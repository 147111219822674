import {Browser} from "@capacitor/browser";
import {Device} from "@capacitor/device";
import {CONFIG} from "../constants";

export default function goToAppStore() {
    Device.getInfo().then((deviceInfo) => {
        if (deviceInfo.operatingSystem === 'ios') {
            Browser.open({url: CONFIG.APP_STORE_IOS_URL});
        } else if (deviceInfo.operatingSystem === 'android') {
            Browser.open({url: CONFIG.APP_STORE_ANDROID_URL});
        }
    });
}