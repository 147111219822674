import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonPage,
    IonRow,
    IonSpinner,
    IonText,
    useIonViewWillEnter
} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {Link, RouteComponentProps} from "react-router-dom";
import {AppContext} from "../App";
import {Img} from "react-image";

interface ILoginProps extends RouteComponentProps<{qrCode: string}> {}

const Login: React.FC<ILoginProps> = ({history, match}) => {
    const {t} = useTranslation();
    const [email, setEmail] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const {dispatch} = React.useContext(AppContext);
    const [errorMessages, setErrorMessages] = useState([]);

    useIonViewWillEnter(() => {
        setErrorMessages([]);
    });

    const submitFormByEnter = function(e: any) {
        if (e.keyCode === 13) {
            if (email !== '' && password !== '') {
                login(e);
            } else {
                setErrorMessages([t('email and password and password are required')]);
            }
        }
    };

    const login = function(e: any) {
        e.preventDefault();
        Axios.post(`${CONFIG.API_ENDPOINT}authentication/userPass`, {"email": email, "pass": password})
            .then((res: any) => {
                if (res.data.success === true) {
                    setEmail(null);
                    setPassword(null);
                    dispatch({
                        type: "LOGIN",
                        payload: {token: res.data.token}
                    });
                    if (match.params.qrCode) {
                        history.push(`/joinGroup/${match.params.qrCode}`);
                    } else {
                        history.push('/');
                    }
                } else {
                    if (res.data.errors) {
                        let errorMessageArray: any = [];
                        res.data.errors.forEach((item: any) => {
                            if(!errorMessageArray.includes(t(`errors.${item.code}`))){
                                errorMessageArray.push(t(`errors.${item.code}`));
                            }
                        });
                        setErrorMessages(errorMessageArray);
                        setPassword(null);
                    }
                }
            });
    };

    return (
        <IonPage>
            <IonContent className="ion-padding authenticateBackground">
                <div className="authenticateWrapper">
                    <Img src="/assets/images/logoYFA.png" className="logoImage" loader={<IonSpinner name="crescent" class="ion-margin-bottom" style={{ marginLeft: "auto", marginRight: "auto", display: "block"}} />} />
                    {errorMessages && errorMessages.length > 0 &&
                        <>

                            {errorMessages.map((errorMessage: string, index) => {
                                return (
                                    <IonText key={errorMessage + index} className="ion-text-center ion-margin-bottom formError" color="danger">
                                        <i className="fas fa-exclamation-circle"/> {errorMessage}
                                    </IonText>
                                )
                            })}

                        </>
                    }
                    <form onSubmit={login} onKeyUp={submitFormByEnter}>
                        <IonInput type="email" class="defaultInput ion-padding-horizontal" name="email" inputmode="email" placeholder={t('email address')} value={email} onIonChange={e => {setEmail(e.detail.value!)}} />
                        <IonInput type="password" class="defaultInput ion-padding-horizontal" name="password" placeholder={t('password')} value={password} onIonChange={e => {setPassword(e.detail.value!)}} />
                        <IonRow>
                            <IonCol class="ion-no-padding ion-text-center">
                                <IonButton type="submit" expand="full" class="defaultButton" disabled={email === '' || password === ''}>{t('login')}</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <span className="line" />
                        </IonRow>
                        {/*<IonRow>*/}
                        {/*    <IonCol class="ion-no-padding ion-text-center">*/}
                        {/*        <IonButton  color="facebook" size="default" class="facebookButton" onClick={(e) => {e.preventDefault(); loginWithFacebook()}}><i className="fab fa-facebook-f" />{t('login with facebook')}</IonButton>*/}
                        {/*    </IonCol>*/}
                        {/*</IonRow>*/}
                        {/*<IonRow>*/}
                        {/*    <IonCol class="ion-no-padding ion-text-center">*/}
                        {/*        <IonButton color="google" size="default" class="googleButton" onClick={(e) => {e.preventDefault(); loginWithGoogle()}}><i className="fab fa-google" />{t('login with google')}</IonButton>*/}
                        {/*    </IonCol>*/}
                        {/*</IonRow>*/}
                        {/*<IonRow>*/}
                        {/*    <span className="line" />*/}
                        {/*</IonRow>*/}
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <Link to="/password-reset" className="defaultLink">{t('reset password')}</Link>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <Link to="/register" className="defaultLink" >{t('register')}</Link>
                            </IonCol>
                        </IonRow>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Login);
