import {
    IonButton,
    IonButtons,
    IonCard, IonCol,
    IonContent, IonGrid,
    IonHeader, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonList,
    IonMenuButton,
    IonPage, IonRefresher, IonRefresherContent, IonRow, IonSelect, IonSelectOption, IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RefresherEventDetail, toastController} from "@ionic/core";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {CONFIG} from "../constants";
import {AppContext} from "../App";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";
import TimeMoment from "../component/TimeMoment";
import Moment from "react-moment";

const Inbox: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [messages, setMessages] = useState<any>([]);
    const {state} = useContext(AppContext);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const slidingOptionsRef = useRef<any>([]);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        } else {
            getMessages();
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getMessages = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}messageBox/list`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setPages(Math.floor(res.data.count/10));
                if (res.data.messages) {
                    setMessages(res.data.messages);
                } else {
                    setMessages([]);
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getMessages();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token, state.me.primaryGroup]);

    function getRowClass(index: any) {
        let rowClass;
        if (isEven(index)) {
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function unreadMessage(id: any){
        Axios.post(
            `${CONFIG.API_ENDPOINT}messageBox/${id}/markUnread`,
            {},
            {
                headers: {
                    'Authorization': `bearer ${state.token}`,
                    'Accept-Language': i18n.language
                }
            }
        ).then((res) => {
            if(res.data.success === true) {
                getMessages();
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    }

    function deleteMessage(id: any){
        Axios.delete(
            `${CONFIG.API_ENDPOINT}messageBox/${id}`,
            {
                headers: {
                    'Authorization': `bearer ${state.token}`,
                    'Accept-Language': i18n.language
                }
            }
        ).then((res) => {
            if(res.data.success === true) {
                getMessages();
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }

        });
    }

    const showMessages = () => {
        if (messages && messages.length > 0) {
            return (
                <IonList class="ion-no-padding ion-no-margin cardLayout">
                    {messages.map((message: any, index: number) =>
                        <IonItemSliding key={index} ref={ref => {slidingOptionsRef.current[index] = ref}} >
                            <IonItem lines="none" routerDirection="forward" detail={false} routerLink={`/inbox/${message.id}`} className={`ion-align-items-center messageItem ${getRowClass(index)} ${message.isRead ? '' : 'activeRankingRow' }`} id={message.id}>
                                <IonCol size="9">
                                    <IonText><i className={message.isRead ? 'fas fa-envelope-open' : 'fas fa-envelope'}/> <span className="ion-margin-start">{message.title}</span></IonText>
                                </IonCol>
                                <IonCol class="ion-text-right" size="3">
                                    <IonText><Moment unix format="LL">{message.publicationDate}</Moment> <TimeMoment amPmTime={state.amPmTime} time={message.publicationDate} timeZone={state.me.timeZone} /></IonText>
                                </IonCol>
                            </IonItem>
                            <IonItemOptions side="end">
                                {message.isRead && <IonItemOption onClick={() => { slidingOptionsRef?.current[index]?.close().then(() => {unreadMessage(message.id)}); }}>{ t('unread') }</IonItemOption>}
                                <IonItemOption color="danger" onClick={() => {deleteMessage(message.id)}}>{t('delete')}</IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                    )}
                </IonList>)
        } else {
            return <IonCard className="cardBackground noBorderRadius ion-padding ion-no-margin">{t('no messages found')}</IonCard>
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getMessages().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    const getPaginationSelectOptions = (pages:number) => {
        let pageRows = [];
        for(let i:number = 1; i <= pages; i++){
            pageRows.push(<IonSelectOption key={"page" + i} value={i}>{t('pagination_text',{page: i, pages: pages})}</IonSelectOption>);
        }

        return pageRows;
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle text-center>{t('inbox')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>

                <IonGrid class="ion-margin-top ion-no-padding">
                    <IonRow class="ion-no-padding ion-justify-content-center">
                        <IonCol class="ion-no-padding" size="12" sizeLg="8" sizeXl="6">
                            {showMessages()}
                            {currentPage && pages > 1 && (pages - 1) > 0 &&
                            <IonRow className="ion-padding-top">
                                <IonCol sizeXs="3">
                                    {currentPage > 1 &&
                                    <IonButton fill="solid" onClick={() => {setCurrentPage(currentPage - 1); }}><i className="far fa-chevron-left"/></IonButton>
                                    }
                                </IonCol>
                                <IonCol sizeXs="6">
                                    <IonSelect className="ion-padding ion-text-center" placeholder={t("select a page")} value={currentPage} onIonChange={e => {setCurrentPage(e.detail.value)}}>
                                        {getPaginationSelectOptions(pages)}
                                    </IonSelect>
                                </IonCol>
                                <IonCol sizeXs="3" className="ion-text-right">
                                    {!(currentPage === pages) &&
                                    <IonButton fill="solid" onClick={() => {setCurrentPage(currentPage + 1); }}><i className="far fa-chevron-right"/></IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Inbox);
