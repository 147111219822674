import {createContext} from "react";

const KnockOutGames = createContext([
    // {
    //     "id":1,
    //     "startTime":1624723200,
    //     "title":"Round of 16",
    //     "teams":[
    //         {
    //             "id":669,
    //             "team_id":79,
    //             "position":1,
    //             "name":"",
    //             "code":"Lokomotiv Moscow",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3713.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":3
    //                 }
    //             }
    //         },
    //         {
    //             "id":670,
    //             "team_id":66,
    //             "position":2,
    //             "name":"Red Bull Salzburg",
    //             "code":"Red Bull Salzburg",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3700.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":2,
    //     "startTime":1624809600,
    //     "title":"Round of 16",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":3,
    //     "startTime":1624896000,
    //     "title":"Round of 16",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":4,
    //     "startTime":1624982400,
    //     "title":"Round of 16",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":5,
    //     "startTime":1625241600,
    //     "title":"Quarter-finals",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":6,
    //     "startTime":1625328000,
    //     "title":"Quarter-finals",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":7,
    //     "startTime":1625587200,
    //     "title":"Semi-finals",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":8,
    //     "startTime":1625673600,
    //     "title":"Semi-finals",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // },
    // {
    //     "id":9,
    //     "startTime":1626019200,
    //     "title":"Final",
    //     "teams":[
    //         {
    //             "id":671,
    //             "team_id":73,
    //             "position":1,
    //             "name":"Shakhtar Donetsk",
    //             "code":"Shakhtar Donetsk",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3707.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         },
    //         {
    //             "id":672,
    //             "team_id":71,
    //             "position":2,
    //             "name":"Real Madrid",
    //             "code":"Real Madrid",
    //             "logo":"https://api.yourfootballapp.eu/appApi/asset/3705.png",
    //             "currentResult":null,
    //             "currentPosition":null,
    //             "predictions":{
    //                 "1000000":{
    //                     "result":0
    //                 }
    //             }
    //         }
    //     ],
    //     "state":1,
    //     "detailedState":"NS",
    //     "round":"Group Stage - 5",
    //     "elapsed":0,
    //     "competition":{
    //         "id":2757,
    //         "name":"UEFA Champions League 2020",
    //         "logo":"https://api.yourfootballapp.eu/appApi/asset/3017.png"
    //     },
    //     "rankingScores":[
    //
    //     ]
    // }
]);

export default KnockOutGames;
