import React, {useContext, useEffect, useState} from "react";
import {
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonThumbnail,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {CONFIG} from "../constants";
import {AppContext} from "../App";
import {RefresherEventDetail, toastController} from "@ionic/core";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";

const Statistics: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [playerRankings, setPlayerRankings] = useState([]);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getStatistics = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}competitions/${CONFIG.COMPETITION}/playerRankings`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setPlayerRankings(res.data.playerRankings);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getStatistics();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    function getRowClass(index: any) {
        let rowClass;
        if (isEven(index)) {
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function checkIfBelowFive(key: any) {
        return parseInt(key) <= 4;
    }

    const showPlayerRankings = (playerRankingKey: any) => {
        if (Object.keys(playerRankings[playerRankingKey]) && Object.keys(playerRankings[playerRankingKey]).length > 0) {
            return(
                <IonList class="ion-padding ion-no-margin" style={{background: "transparent"}} key={playerRankingKey}>
                    <IonListHeader style={{color: "var(--ion-color-secondary)", fontSize: "16px"}} class="ion-no-padding"><strong>{t(`playerRankings.${playerRankingKey}`)}</strong></IonListHeader>
                    <div className="cardLayout">
                        {Object.keys(playerRankings[playerRankingKey]).filter(checkIfBelowFive).map((playerRanking: any, index: number) => {
                            return (
                                showPlayerRanking(playerRanking, playerRankingKey, index)
                            )
                        })}
                    </div>
                </IonList>
            )
        }
    };

    const showPlayerRanking = (playerRanking: any, playerRankingKey: any, index: number) => {
        if (Object.keys(playerRankings[playerRankingKey][playerRanking]) && Object.keys(playerRankings[playerRankingKey][playerRanking]).length > 0) {
            return(
                <IonItem routerDirection="forward" detail={false} routerLink={`teams/${playerRankings[playerRankingKey][playerRanking]["team"]["id"]}/player/${playerRankings[playerRankingKey][playerRanking]["player"]["id"]}`} key={`${playerRankings[playerRankingKey][playerRanking]["player"]["id"]}_${index}`} class={getRowClass(index)} lines="none">
                    <IonGrid>
                        <IonRow class="ion-align-items-center">
                            <IonCol>
                                <IonText color="primary"><strong>{playerRankings[playerRankingKey][playerRanking]["position"]}</strong></IonText>
                            </IonCol>
                            <IonCol size="9" sizeSm="10">
                                <IonRow class="ion-align-items-center">
                                    <IonCol size="auto" class="ion-padding-end">
                                        <IonThumbnail style={{ "--size": "20px", "--border-radius": "15px"}}>
                                            <IonImg src={playerRankings[playerRankingKey][playerRanking]["player"]["nationality"]["flag"]} />
                                        </IonThumbnail>
                                    </IonCol>
                                    <IonCol size="8">
                                        <IonLabel>{playerRankings[playerRankingKey][playerRanking]["player"]["fullName"]}</IonLabel>
                                        <IonLabel style={{fontSize: "14px", "--color": "#505050"}}>{playerRankings[playerRankingKey][playerRanking]["team"]["name"]}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol class="ion-text-center">
                                <IonText color="primary"><strong>{playerRankings[playerRankingKey][playerRanking]["value"]}</strong></IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
            )
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getStatistics().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{ t('statistics') }</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        {playerRankings ? (
                            Object.keys(playerRankings).map((playerRankingKey: any) => {
                                return showPlayerRankings(playerRankingKey)
                            })
                        ) : (
                            <div className="ion-padding ion-margin cardLayout" style={{backgroundColor : "#fff", color : "#737373", fontSize : "14px"}}><IonText>{t("no information available")}</IonText></div>
                        )}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Statistics);
