import React, {useEffect, useState} from 'react';
import {IonText} from "@ionic/react";
import moment from "moment";
import {useTranslation} from "react-i18next";

function MatchTimer({matchStartTime} : {matchStartTime : any}) {
    const calculateTimeLeft = () => {
        const matchStart = moment.unix(matchStartTime);
        const difference = moment(matchStart, "minutes").diff(moment());
        let timeLeft = t("match has started");
        if(difference > 0){
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            let minutes = makeMeTwoDigits(Math.floor((difference / 1000 / 60) % 60));
            let seconds = makeMeTwoDigits(Math.floor((difference / 1000) % 60));
            timeLeft = t('match_timer_text',{hoursLeft: hours, minutesLeft: minutes, secondsLeft: seconds})
        }
        return timeLeft;

    };

    function makeMeTwoDigits(n: any){
        return (n < 10 ? "0" : "") + n;
    }

    const {t} = useTranslation();
    const [matchTimeLeft, setMatchTimeLeft] = useState(calculateTimeLeft());


    useEffect(() => {
        const timer = setTimeout(() => {
            setMatchTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });
    return (
        <div className="ion-margin-bottom ion-padding-bottom">
            <IonText class="ion-text-center">
                <h2 style={{fontSize : "16px", fontWeight : "bold"}}>{matchTimeLeft}</h2>
            </IonText>
        </div>
    );
}

export default MatchTimer;
