import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner, IonText
} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {Img} from "react-image";
import {RouteComponentProps} from "react-router-dom";
import {AppContext} from "../App";

interface IChangePasswordProps extends RouteComponentProps<{recoveryKey: string, code: string}> {}

const ChangePassword: React.FC<IChangePasswordProps> = ({history, match}) => {
    const {t} = useTranslation();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const {dispatch} = React.useContext(AppContext);

    const submitFormByEnter = function(e: any) {
        if (e.keyCode === 13) {
            if (password1 !== '' && password2 !== '') {
                resetPassword(e);
            }
        }
    };

    const resetPassword = function(e: any) {
        e.preventDefault();
        let errorMessageArray: any = [];
        if (password1 === password2) {
            Axios.post(`${CONFIG.API_ENDPOINT}authentication/validateKey`, {'recoveryKey': match.params.recoveryKey, 'code': match.params.code })
                .then((validateResponse: any) => {
                    if (validateResponse.data.success === true) {
                        Axios.post(`${CONFIG.API_ENDPOINT}authentication/setPassword`, {'recoveryKey': match.params.recoveryKey, 'code': match.params.code, 'password': password1})
                            .then((res: any) => {
                                if (res.data.success === true) {
                                    setPassword1('');
                                    setPassword2('');
                                    dispatch({
                                        type: "LOGIN",
                                        payload: {token: res.data.token}
                                    });
                                    if (res.data.joinGroupQrCode) {
                                        history.push(`/joinGroup/${res.data.joinGroupQrCode}`);
                                    } else {
                                        history.push('/');
                                    }
                                } else {
                                    if (res.data.errors) {
                                        res.data.errors.forEach((item: any) => {
                                            if(!errorMessageArray.includes( t(`errors.${item.code}`))){
                                                errorMessageArray.push(t(`errors.${item.code}`));
                                            }
                                        });
                                    }
                                }
                            })
                    } else {
                        if(!errorMessageArray.includes( t('the password reset code is invalid or has expired.'))){
                            errorMessageArray.push( t('the password reset code is invalid or has expired.'));
                        }
                    }
                    setErrorMessages(errorMessageArray);
                })
        } else {
            if(!errorMessageArray.includes( t(`INVALID_PASSWORD_MATCH`))){
                errorMessageArray.push(t(`INVALID_PASSWORD_MATCH`));
            }
            setErrorMessages(errorMessageArray);
        }

    };

    return (
        <IonPage>
            <IonContent className="ion-padding authenticateBackground">
                <div className="authenticateWrapper">
                    <Img src="/assets/images/logoYFA.png" className="logoImage" loader={<IonSpinner name="crescent" class="ion-margin-bottom" style={{ marginLeft: "auto", marginRight: "auto", display: "block"}} />} />
                    {errorMessages && errorMessages.length > 0 &&
                        errorMessages.map((errorMessage: string, index) => {
                                return (
                                    <IonText key={errorMessage + index} className="ion-text-center ion-margin-bottom formError" color="danger"><i className="fas fa-exclamation-circle" /> {errorMessage}</IonText>
                                )
                        })
                    }

                    <form onSubmit={resetPassword} onKeyUp={submitFormByEnter}>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonLabel position="stacked">{t('password')}</IonLabel>
                                <IonInput type="password" name="password" inputmode="text" class="defaultInput" value={password1} onIonChange={e => {setPassword1(e.detail.value!)}} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonLabel position="stacked">{t('repeat password')}</IonLabel>
                                <IonInput type="password" name="password" inputmode="text" class="defaultInput" value={password2} onIonChange={e => {setPassword2(e.detail.value!)}} />
                                <IonText color="medium">
                                    <p className="small"><i className="fas fa-info-circle" /> {t("a password should contain at least 8 characters")}</p>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-no-padding ion-text-center">
                                <IonButton type="submit" expand="full" class="defaultButton" disabled={password1 === '' || password2 === ''}>{t('save password')}</IonButton>
                            </IonCol>
                        </IonRow>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(ChangePassword);
