import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import {
    IonButton,
    IonButtons,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol,
    IonContent,
    IonHeader, IonInput, IonItem, IonLabel, IonList,
    IonMenuButton, IonNote,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow, IonSearchbar,
    IonSegment,
    IonSegmentButton, IonSlide, IonSlides, IonText,
    IonTitle,
    IonToolbar, useIonAlert,
} from "@ionic/react";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import Axios from "axios";
import {CONFIG} from "../constants";
import {RefresherEventDetail, toastController} from "@ionic/core/components";
import {RouteComponentProps} from "react-router-dom";
import {Fetch} from "../Fetch";
import {Share} from "@capacitor/share";
import {Capacitor} from "@capacitor/core";

const Groups: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [groups, setGroups] = useState([]);
    const [searchGroups, setSearchGroups] = useState([]);
    const {state} = useContext(AppContext);
    const [searchText, setSearchText] = useState('');
    const [segmentItem, setSegmentItem] = useState<any>('groups');
    const [groupName, setGroupName] = useState('');
    const [freeGroup, setFreeGroup] = useState<any>({});
    const [screenWidth, setScreenWidth] = useState<any>(0);
    var QRCode = require('qrcode.react');
    const [present] = useIonAlert();

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        checkIfHasFreeGroup();
        // eslint-disable-next-line
    }, [groups]);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        findGroups(searchText);
        // eslint-disable-next-line
    }, [searchText]);

    const findGroups = async (searchText: string) => {
        Axios.get(`${CONFIG.API_ENDPOINT}groups/search?q=${searchText}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                if (res.data.groups) {
                    setSearchGroups(res.data.groups);
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    const getGroups = async () => {
        Axios.get(`${CONFIG.API_ENDPOINT}me`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                if (res.data.groups) {
                    setGroups(res.data.groups);
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    window.addEventListener("resize", function(){
        setScreenWidth(window.innerWidth);
    });

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getGroups();
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    function qrCodeNotNull(group: any){
        return group.qrCode != null;
    }

    function checkIfHasFreeGroup(){
        let userHasFreeGroup = {};
        groups.forEach((group: any) => {
            if (group.isFree && group.role === 1){
                userHasFreeGroup = group;
            }
        });
        setFreeGroup(userHasFreeGroup);
    }

    function shareJoinGroup(group: any){
        Share.share({
            title: t('share join group'),
            text: t('join group',{groupname: group.name }),
            url: `https://yourfootballapp.eu/app/joinGroup/` + group.qrCode,
            dialogTitle: t('share join group')
        }).then((result) => {
            console.log(result);
        });
    }

    function copyJoinGroupLinkToClipboard(link:string){
        navigator.clipboard.writeText(link);
        toastController.create({
            message: t('join group link copied'), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
        }).then((result: HTMLIonToastElement) => {
            return result.present();
        });
    }

    function getRowClass(index: any) {
        let rowClass;
        if(isEven(index)){
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function showQrCodesInSlider(){
        return (
            <IonSlides pager={true} class="voucherSlider" key={groups.map((group: any) => {
                let slideKey = group.id;
                return slideKey
            }).join('_')}>
                {groups.filter(qrCodeNotNull).map((group: any, index) => {
                    return (
                        <IonSlide class="groupSlide" key={`group${group.id}`}>
                            <IonCard class={"voucherCard noBorderRadius ion-margin groupSlide"}>
                                <div className="voucherCardTop hideLandscape">
                                    <QRCode className="qrCode ion-padding" value={`${CONFIG.APP_URL}/joinGroup/${group.qrCode}`} size={275} />
                                </div>
                                {Capacitor.isPluginAvailable('Share') ?
                                    <div className="joinGroupShare" >
                                        <IonButton className="ion-margin-top" onClick={() => {shareJoinGroup(group)}}><i className="fas fa-share-alt shareButtonIcon" />{t('share join group')}</IonButton>
                                    </div>
                                    :
                                    <div className="joinGroupLink">
                                        <IonInput className="defaultInput" value={'https://yourfootballapp.eu/app/joinGroup/' + group.qrCode} readonly />
                                        <IonButton className="ion-no-margin" onClick={() => {copyJoinGroupLinkToClipboard('https://yourfootballapp.eu/app/joinGroup/' + group.qrCode)}} >{t("copy")}</IonButton>
                                    </div>
                                }
                                <IonCardHeader class="ion-text-center ion-no-padding ion-padding-top">
                                    <IonCardTitle className="ion-padding-top">{group.name}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonSlide>
                    )
                })}
            </IonSlides>
        )
    }

    function QrCodesInGrid(){
        return (
            <IonRow style={{margin: "12px"}} key={groups.map((group: any) => {
                let slideKey = group.id;
                return slideKey
            }).join('_')}>
                {groups.filter(qrCodeNotNull).map((group: any, index) => {
                    return (
                        <IonCol size="12" sizeLg="6" sizeXl="6" key={`group${group.id}`}>
                            <IonCard class={"voucherCard noBorderRadius ion-no-margin groupSlide"}>
                                <div className="voucherCardTop hideLandscape">
                                    <QRCode className="qrCode ion-padding" value={`${CONFIG.APP_URL}/joinGroup/${group.qrCode}`} size={275} />
                                </div>
                                <div className="joinGroupLink">
                                    <IonInput className="defaultInput" value={'https://yourfootballapp.eu/app/joinGroup/' + group.qrCode} readonly />
                                    <IonButton className="ion-no-margin" onClick={() => {copyJoinGroupLinkToClipboard('https://yourfootballapp.eu/app/joinGroup/' + group.qrCode)}} >{t("copy")}</IonButton>
                                </div>
                                <IonCardHeader class="ion-text-center ion-no-padding ion-padding-top">
                                    <IonCardTitle className="ion-padding-top">{group.name}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    )
                })}
            </IonRow>
        )
    }

    const showSegement = () => {
        if (segmentItem === 'groups') {
            return (
                <>
                    <IonCard class="cardBackground ion-margin noBorderRadius">
                        <IonCardContent>
                            {t('below you find the qr-codes of all the groups you\'ve joined.')} {t('you can let others scan the code to join the group or you can share the join link of the group to let others join the group.')}
                        </IonCardContent>
                    </IonCard>
                    {screenWidth < 992 ?
                        showQrCodesInSlider()
                        :
                        QrCodesInGrid()
                    }
                </>
            )
        } else  if (segmentItem === 'search') {
            return (
                <>
                    <IonSearchbar class="ion-padding" placeholder={t('search for a new group to join')} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} />
                    <IonCard class="cardBackground ion-margin noBorderRadius">
                        {searchGroups.length > 0 &&
                            <IonList class="ion-no-padding">
                                {searchGroups.map((searchGroup: any, index:number) => {
                                    return (
                                        <IonItem className={getRowClass(index)} key={searchGroup.id} lines="none" onClick={() => enterGroupAlert(searchGroup.id, searchGroup.name, searchGroup.isPasswordProtected)}>
                                            <IonLabel><i className={searchGroup.isPasswordProtected ? "fas fa-lock" : "fas fa-unlock"} slot="start" style={{width: "16px", color: "#737373"}}/> {searchGroup.name}</IonLabel>
                                            <IonNote slot="end">{searchGroup.publicMemberCount ? `${searchGroup.publicMemberCount} ${t('members')}` : ''}</IonNote>
                                        </IonItem>
                                    )
                                })}
                            </IonList>
                        }
                    </IonCard>
                </>
            );
        } else if (segmentItem === 'free-group') {
            return (
                Object.keys(freeGroup).length > 0 ?
                    <IonCard class="ion-padding cardBackground ion-margin noBorderRadius freeGroup">
                        <IonCardHeader class="ion-no-padding ion-padding-bottom ion-no-margin">
                            <IonText>{t('your free group with a limit of 100 users.')}</IonText>
                        </IonCardHeader>
                        <IonCardContent class="ion-no-margin ion-no-padding">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked">{t('group name')}</IonLabel>
                                <IonInput type="text" name="name" inputmode="text" class="defaultInput" value={freeGroup.name}  disabled />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="auto">
                                <IonButton expand="full" onClick={() => {deleteGroupAlert(freeGroup.id, freeGroup.name)}}>{t('delete free group')}</IonButton>
                            </IonCol>
                        </IonCardContent>
                    </IonCard>
                    :
                    <IonCard class="ion-padding cardBackground ion-margin noBorderRadius freeGroup">
                        <IonCardHeader class="ion-no-padding ion-padding-bottom ion-no-margin">
                            <IonText>{t('every user can create one group with a limit of 100 users.')} {t('you only need to enter a name.')}</IonText>
                        </IonCardHeader>
                        <IonCardContent class="ion-no-margin ion-no-padding">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked">{t('group name')}</IonLabel>
                                <IonInput type="text" name="name" inputmode="text" class="defaultInput" onIonChange={e => {setGroupName(e.detail.value!)}} required />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="auto">
                                <IonButton expand="full" onClick={() => {createGroup()}}>{t('create group')}</IonButton>
                            </IonCol>
                        </IonCardContent>
                    </IonCard>
            )
        }
    };

    function createGroup() {
        Fetch.call({
            method: "post",
            url:    "groups/create",
            payload: {
                'name': groupName,
            },
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res) =>  {
            if (res.data.success === true) {
                history.push('/profile');
                getGroups();
                toastController.create({
                    message: t('group created'), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
            setGroupName('');
        });
    }

    function deleteGroup(groupId: any) {
        Fetch.call({
            method: "post",
            url:    "groups/delete",
            payload: {
                'group': groupId,
            },
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res) =>  {
            if (res.data.success === true) {
                setFreeGroup({});
                toastController.create({
                    message: t('group deleted'), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            } else {
                toastController.create({
                    message: t('something went wrong with deleting the group please contact us'),
                    buttons: [{text: t('close'), role: 'cancel'}],
                    cssClass: "toastMessage",
                    duration: 3000,
                    position: 'top',
                    translucent: false,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            }
            setGroupName('');
        });
    }

    function deleteGroupAlert(groupId:any, groupName:string){
        present({
            header: t("delete") + " " + groupName,
            message: t("are you sure you want to delete this group") + "?",
            buttons: [{
                text:t('delete'),
                handler: () => { deleteGroup(groupId) }
            }, t('cancel')]
        });
    }

    function enterGroupAlert(groupId: any, groupName: string, passwordRequired: boolean) {
        if (passwordRequired) {
            present({
                header: `${t("join")} "${groupName}"`,
                message: t("Are you sure you want to join this group") + "? " + t('Please enter below the group password.'),
                inputs: [{
                    'label': t('password'),
                    'name': 'password',
                    'type': 'password',
                    'placeholder': t('password'),
                    'id': 'joinGroupAlertPasswordInput'
                }],
                buttons: [{
                    text:t('join'),
                    handler: (alertData) => { enterGroup(groupId, alertData.password) }
                }, t('cancel')]
            });
        } else {
            present({
                header: `${t("join")} "${groupName}"`,
                message: t("Are you sure you want to join this group") + "?",
                buttons: [{
                    text:t('join'),
                    handler: () => { enterGroup(groupId, false) }
                }, t('cancel')]
            });
        }
    }

    const enterGroup = (groupId: any, groupPassword: any) => {
        Fetch.call({
            method: "post",
            url:    "me/joinGroupById",
            payload: {
                'groupId': groupId,
                'password': groupPassword ? groupPassword : null
            },
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res) =>  {
            if (res.data.success === true) {
                history.push('/profile');
                toastController.create({
                    message: t('group joined'), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getGroups().then(() => {
            event.detail.complete();
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('groups')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-no-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="10" sizeXl="8">
                        <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                            <IonRefresherContent refreshingSpinner="crescent" />
                        </IonRefresher>
                        <IonRow className="ion-padding">
                            <IonSegment onIonChange={e => {setSegmentItem(e.detail.value); }} value={segmentItem}>
                                <IonSegmentButton value="groups" class="segmentButton">
                                    <IonLabel>{t('groups')}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="search" class="segmentButton">
                                    <IonLabel>{t('search')}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="free-group" class="segmentButton">
                                    <IonLabel>{t('free group')}</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonRow>
                        {showSegement()}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Groups);
