import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './helper/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {CONFIG} from "./constants";

declare global {
    interface Window {
        __sportappOverrideApiEndpoint:  string|undefined;
    }
}

axios.interceptors.request.use(request => {
    if(window.__sportappOverrideApiEndpoint !== undefined) {
        request.url = request.url?.replace(CONFIG.API_ENDPOINT, window.__sportappOverrideApiEndpoint);
    }
    return request;
}, error => {
    return Promise.reject(error);
});
ReactDOM.render(<Suspense fallback={<div>Loading app</div>}><App /></Suspense>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
