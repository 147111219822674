import React, {useEffect, useState} from "react";

interface IVoucherTimer {
    voucherId: string,
    voucherTimeout: number,
    voucherBaseTimeout: number,
    userOnPage:boolean,
    onChange: Function
}

const VoucherTimer: React.FC<IVoucherTimer> = (props) => {
    const [timeout, setTimeout] = useState(props.voucherTimeout);
    const timeoutPercentage = (timeout / props.voucherBaseTimeout) * 100;
    let timeOutCalc = timeout;
    const timeoutHours = Math.floor(timeOutCalc / 3600);
    timeOutCalc = timeOutCalc - (timeoutHours * 3600);
    const timeoutMinutes = Math.floor(timeOutCalc / 60);
    const timeoutSeconds = timeOutCalc - (timeoutMinutes * 60);

    function prependZero(prependNumber:number) {
        if (prependNumber <= 9)
            return "0" + prependNumber;
        else
            return prependNumber;
    }
    useEffect(() => {
        let interval = setInterval(() => {
            if(props.userOnPage) {
                if (timeout > 0) {
                    setTimeout(() => timeout - 1);
                } else {
                    props.onChange(props.voucherId);
                    clearInterval(interval);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [timeout, props.userOnPage]);

    return (
        <div className={`countDown countDownTimer${props.voucherId}`}>
            <div className="countDownNumber">{prependZero(timeoutHours)}:{prependZero(timeoutMinutes)}:{prependZero(timeoutSeconds)}</div>
            <div className="countDownBarContainer">
                <div className="countDownBar" style={{ width : timeoutPercentage.toFixed(1) + "%"}} />
            </div>
        </div>
    )
};

export default React.memo(VoucherTimer);
