import React, {useContext, useEffect, useState} from "react";
import {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonRow, IonCol, IonMenuToggle, IonGrid, IonHeader, IonToolbar, IonTitle, IonBadge
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import AppPages, {menuItems} from "../helper/AppPages";
import {IPage} from "../models/IPage";
import {AppContext} from "../App";

const AppMenu: React.FC = () => {
    const {t} = useTranslation();
    const pages = useContext(AppPages);
    const {state} = React.useContext(AppContext);
    let [openMenus, setOpenMenus] = useState<any[]>([]);

    const getSubMenuItems = (page: IPage) => {
        return <IonList lines="none" style={{"--ion-item-background" : "none", "padding":"0"}} key={page.title}>
            {pages.map((subpage: IPage, index) =>
                subpage.parent === page.title && subpage.menu &&
                    printItem(subpage, index)
            )}
        </IonList>
    };

    const handleParentClick = (pageTitle: string) => {
       if(openMenus.includes(pageTitle)){
            setOpenMenus(openMenus.filter(item => item !== pageTitle));
        } else {
            setOpenMenus([...openMenus, pageTitle]);
        }
    };

    const getMenu = () => {
        return pages.filter(menuItems).map((page: IPage, index: any) => {
            if ((page.auth && state.token) && page.component === null && page.path === '') {
                var hasSubMenu = false;
                if(pages.filter(subpage => subpage.title === page.title && subpage.menu).length > 0) {
                    hasSubMenu = true;
                }
                return  (
                    <IonItem lines="none" detail={false} key={page.title} color="primary">
                        <IonGrid className="ion-no-padding">
                            <IonRow>
                                <IonCol size="12" style={{ "--ion-grid-column-padding" : 0 }}>
                                    <IonItem  lines="none" detail={false} style={{ "--padding-start": "0px", cursor : "pointer" }} color="primary" onClick={() => handleParentClick(page.title)}>
                                        {page.icon &&
                                            <i className={page.icon} slot="start" style={{ width: "16px" }} />
                                        }
                                        <IonLabel>{t(page.title)}</IonLabel>
                                        {hasSubMenu &&
                                            <i className="fas fa-caret-down" slot="end" style={{ float: "right" }} />
                                        }
                                    </IonItem>
                                </IonCol>
                                <IonCol  size="12"  style={{ "--ion-grid-column-padding" : 0 }}>
                                    {openMenus.includes(page.title) &&
                                        getSubMenuItems(page)
                                    }
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                )
            } else {
                return printItem(page, index);
            }
        });
    };

    function printItem(page: IPage, index: any) {
        if ((page.auth && state.token && page.title !== 'login') ||
            (state.token && !['register', 'login', 'password reset'].includes(page.title)) ||
            (!state.token && ['support', 'login'].includes(page.title))) {
            return (
                <IonMenuToggle autoHide={false} key={index}>
                    <IonItem routerLink={t(page.path)} routerDirection="none" color={page.parent ? "primary" : "none"} lines="none" detail={false} key={page.title + index} >
                        {page.icon &&
                            <i className={page.icon} slot="start" style={{ width: "16px" }} />
                        }
                        <IonLabel>
                            {t(page.title)}
                            {page.title === "inbox" && typeof state.me.messageBoxUnread && state.me.messageBoxUnread > 0 &&
                                <IonBadge style={{"verticalAlign" : "middle", "marginLeft": "8px"}}  color="light">{state.me.messageBoxUnread}</IonBadge>
                            }
                        </IonLabel>
                    </IonItem>
                </IonMenuToggle>
            );
        }
    }

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getMenu();
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token, state.me.primaryGroup]);

    return (
        <IonMenu contentId="main" className="mainMenuWrapper" style={{"--max-width": "300px"}}>
            <IonHeader>
                <IonToolbar color="primary" >
                    <IonTitle>{t("menu")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="primary">
                <IonList class="mainMenu">
                    {getMenu()}
                </IonList>
            </IonContent>
        </IonMenu>
    )
};

export default React.memo(AppMenu);
