import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonGrid,
    IonPage,
    IonTitle,
    IonRow,
    IonCol,
    IonToolbar,
    IonRefresherContent,
    IonRefresher,
    IonItem,
    IonList,
    IonBackButton,
    IonBadge,
    IonText,
    IonButton, IonSegment, IonSegmentButton, IonLabel,
} from "@ionic/react";
import {AppContext} from "../App";
import Axios from "axios";
import {CONFIG} from "../constants";
import {IUserRanking} from "../models/IUserRanking";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {RouteComponentProps} from "react-router";
import GameRulesModal from "../component/GameRulesModal";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";

interface RankingItem extends RouteComponentProps <{groupId: string, userId: string}> {}

const RankingsItem: React.FC<RankingItem> = ({history, match}) => {
    const {t, i18n} = useTranslation();
    const [ranking, setRanking] = useState<IUserRanking []>([]);
    const [futureRanking, setFutureRanking] = useState<any>([]);
    const {state} = useContext(AppContext);
    const [showGameRulesModal, setShowGameRulesModal] = useState(false);
    const [userName, setUsername] = useState();
    const [rankingState, setRankingState] = useState<any>('futurePredictions');

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    async function getRanking() {
        let getUrl = null;
        if (typeof match.params.groupId === 'undefined' ||  match.params.groupId === 'global') {
            getUrl = `/global/byUser/${match.params.userId}`;
        } else {
            getUrl = `/byGroup/${match.params.groupId}/byUser/${match.params.userId}`;
        }

        Axios.get(`${CONFIG.API_ENDPOINT}rankings/${CONFIG.COMPETITION}${getUrl}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setRanking(res.data.rankingDetails);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        })
    }

    async function getFuturePredictions() {
        Axios.get(`${CONFIG.API_ENDPOINT}predictions/futureMatches/${CONFIG.COMPETITION}/${match.params.userId}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setFutureRanking(res.data);
                setUsername(res.data.user.name);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        })
    }

    useEffect(() => {
        if (!state.initLoading && state.token) {
            if (rankingState === "rankings") {
                getRanking();
            } else {
                getFuturePredictions();
            }
        }
        // eslint-disable-next-line
    }, [rankingState, i18n.language, state.initLoading, state.token]);

    function getRowClass(index: any) {
        let rowClass;
        if (isEven(index)) {
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    const showRanking = () => {
        if (ranking && ranking.length > 0) {
            return (
                <IonList class="ion-no-margin ion-no-padding">
                    {ranking.map((rankingDetail: any, index) => {
                         return <IonItem key={index} class={`${getRowClass(index)}`} lines="none" detail={false} routerLink={"/matches/"+rankingDetail.id}>
                             <div style={{ "padding": "16px 5px"}}>
                                 <strong style={{ color: "var(--ion-color-primary)"}}>{rankingDetail.teams[0].name} {rankingDetail.teams[0].currentResult}  - {rankingDetail.teams[1].currentResult} {rankingDetail.teams[1].name} </strong>
                                 {(rankingDetail.teams[0].predictions[1000000] && rankingDetail.teams[1].predictions[1000000]) ? <p className="ion-no-margin">{t('prediction')}: {rankingDetail.teams[0].predictions[1000000].result} - {rankingDetail.teams[1].predictions[1000000].result} </p> : <p className="ion-no-margin">{t('no prediction')}</p> }
                             </div>
                                 <IonBadge slot="end" style={{ "margin" : "5px", "padding" : "5px"}}>{rankingDetail.rankingScore}</IonBadge>
                         </IonItem>
                    })}
                </IonList>
            )
        } else {
            return <div className="ion-padding"><IonText style={{ color : "#737373", fontSize : "14px"}}>{t("no information available")}</IonText></div>
        }
    };

    const showFuturePredictions = () => {
        if (futureRanking.matches && futureRanking.matches.length) {
            return (
                <IonList class="ion-no-margin ion-no-padding">
                    {futureRanking.matches.map((rankingFutureDetail: any, index: number) => {
                        return <IonItem key={index} class={`${getRowClass(index)}`} lines="none" detail={false}  routerLink={"/matches/"+rankingFutureDetail.id}>
                            <div style={{ "padding": "16px 5px"}}>
                                <strong style={{ color: "var(--ion-color-primary)"}}>{rankingFutureDetail.teams[0].name} {rankingFutureDetail.teams[0].currentResult}  - {rankingFutureDetail.teams[1].currentResult} {rankingFutureDetail.teams[1].name} </strong>
                                {(rankingFutureDetail.teams[0].predictions[1000000] && rankingFutureDetail.teams[1].predictions[1000000]) ? <p className="ion-no-margin">{t('prediction')}: {rankingFutureDetail.teams[0].predictions[1000000].result} - {rankingFutureDetail.teams[1].predictions[1000000].result} </p> : <p className="ion-no-margin">{t('no prediction')}</p> }
                            </div>
                            <IonBadge slot="end" style={{ "margin" : "5px", "padding" : "5px"}}>{rankingFutureDetail.rankingScore}</IonBadge>
                        </IonItem>
                    })}
                </IonList>
            )
        } else {
            return <div className="ion-padding"><IonText style={{ color : "#737373", fontSize : "14px"}}>{t("no information available")}</IonText></div>
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getRanking().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/rankings" text="" />
                    </IonButtons>
                    <IonTitle>{t('rankings')} {userName}</IonTitle>
                    <IonButtons slot="end" >
                        <IonButton onClick={() => setShowGameRulesModal(true)}>{t('rules')}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center fixed`}}>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                            <IonRefresherContent refreshingSpinner="crescent" />
                        </IonRefresher>
                        <IonSegment onIonChange={e => {setRankingState(e.detail.value)}} value={rankingState}>
                            <IonSegmentButton value="rankings" class="segmentButton">
                                <IonLabel>{t('completed')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="futurePredictions" class="segmentButton">
                                <IonLabel>{t('future')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        <IonGrid class="ion-margin-top ion-no-padding">
                            <IonRow class="ion-no-padding">
                                <IonCol size="12" class="ion-no-padding">
                                    <IonRow class="rankingTitles ion-padding-start ion-padding-end">
                                        <IonCol size-xs="9" size-sm="10" size-md="10">
                                            <IonText color="secondary">{t("match")}</IonText>
                                        </IonCol>
                                        {rankingState === 'rankings' &&
                                        <IonCol class="ion-text-right" size-xs="3" size-sm="2" size-md="2">
                                            <IonText color="secondary">{t("points")}</IonText>
                                        </IonCol>
                                        }
                                    </IonRow>
                                </IonCol>
                                <IonCol style={{background: '#fff'}} class="ion-no-padding cardLayout">
                                    {rankingState === 'rankings' ? (
                                        showRanking()
                                    ) : (
                                        showFuturePredictions()
                                    )}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
                <GameRulesModal showGameRulesModal={showGameRulesModal} onChange={setShowGameRulesModal} />
            </IonContent>
        </IonPage>
    )
};

export default React.memo(RankingsItem);
