import {ILanguage} from "../models/ILanguage";

const AvailableLanguages: ILanguage[] = [
    {
        id: 1,
        code: "en"
    },
    {
        id: 2,
        code: "nl"
    },
    {
        id: 3,
        code: "de"
    }
];

export default AvailableLanguages;
