import React, {useContext, useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol,
    IonContent,
    IonHeader,
    IonPage, IonRefresher, IonRefresherContent, IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import {CONFIG} from "../constants";
import Axios from "axios";
import {RefresherEventDetail, toastController} from "@ionic/core";
import parse from 'html-react-parser';
import Moment from "react-moment";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import TimeMoment from "../component/TimeMoment";

interface NewsProps extends RouteComponentProps <{id: string}> {}

const InboxItem: React.FC<NewsProps> = ({history, match}) =>  {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [inboxItem, setInboxItem] = useState<any>();

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getInboxItem = async () => {
        Axios.get(`${CONFIG.API_ENDPOINT}messageBox/${match.params.id}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setInboxItem(res.data.message);
                if (!res.data.message.isRead) {
                    Axios.post(
                        `${CONFIG.API_ENDPOINT}messageBox/${match.params.id}/markRead`,
                        {},
                        {
                            headers: {
                                'Authorization': `bearer ${state.token}`,
                                'Accept-Language': i18n.language
                            }
                        }).then((data) => { console.log('read'); return true; });
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getInboxItem();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    const showInboxItem = () => {
        if (inboxItem) {
            return (
                <IonCard class="ion-no-margin cardBackground noBorderRadius" key={inboxItem.id}>
                    <IonCardHeader>
                        <IonCardTitle><h1 className="ion-no-margin">{inboxItem.title}</h1></IonCardTitle>
                        <IonCardSubtitle className="inboxItemPublishing"><Moment unix format="LL">{inboxItem.publicationDate}</Moment> <TimeMoment amPmTime={state.amPmTime} time={inboxItem.publicationDate} timeZone={state.me.timeZone} /></IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent class="ion-padding" style={{ fontSize: "16px"}}>
                        {parse(inboxItem.text)}
                    </IonCardContent>
                </IonCard>
            )
        } else {
            return <IonCard className="cardBackground ion-no-margin noBorderRadius ion-padding">{t('no message found')}</IonCard>
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getInboxItem().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/inbox" text="" />
                    </IonButtons>
                    <IonTitle>{inboxItem && inboxItem.title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        {showInboxItem()}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(InboxItem);
