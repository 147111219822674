import React, {useState} from "react";
import {IonBadge, IonButton, IonContent, IonItem, IonList, IonModal, IonText} from "@ionic/react";
import {useTranslation, Trans} from "react-i18next";
import ScrollArrow from "./ScrollArrow";

interface IGameRulesModal {
    showGameRulesModal: boolean,
    onChange: Function
}

const GameRulesModal: React.FC<IGameRulesModal> = (props) => {
    const {t} = useTranslation();
    const [hasNotScrolled, setHasNotScrolled] = useState(true);

    function handleScroll(e:any) {
        if(e.detail.scrollTop > 0) {
            setHasNotScrolled(false);
        } else {
            setHasNotScrolled(true);
        }
    }

    return (
        <IonModal isOpen={props.showGameRulesModal} onDidDismiss={() => props.onChange(false)}>
            <IonContent scrollEvents={true} onIonScroll={(e) => {handleScroll(e)}} >
                <div className="gameRulesModalContainer" style={{ overflowY: "auto"}}>
                    <h4 className="ion-no-margin ion-padding">{t('playing rules')}</h4>
                    <div className="ion-padding-horizontal ion-padding-bottom">
                        <IonText><Trans i18nKey="game_rules_text"></Trans></IonText>
                    </div>
                    <IonList class="ion-no-padding">
                        <IonItem class="itemEvenRow" lines="none">
                            <IonBadge slot="end" color="primary">250</IonBadge>
                            <IonText>{t('prediction same as end result')}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                            <IonBadge slot="end" color="primary">150</IonBadge>
                            <IonText>{t('predicted draw')}</IonText>
                        </IonItem>
                        <IonItem class="itemEvenRow" lines="none">
                            <IonBadge slot="end" color="primary">100</IonBadge>
                            <IonText>{t('predicted winner')}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                            <IonBadge slot="end" color="primary">40</IonBadge>
                            <IonText>{t('predicted number of goals team')}</IonText>
                        </IonItem>
                    </IonList>
                </div>
                <IonButton className="gameRulesModalCloseButton ion-margin" onClick={() => {props.onChange(false);  setHasNotScrolled(true);}}>{t('close')}</IonButton>
            </IonContent>
            <ScrollArrow hasNotScrolled={hasNotScrolled}/>
        </IonModal>
    )
};

export default React.memo(GameRulesModal);
