import {
    IonButtons,
    IonCard, IonCol,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage, IonRefresher, IonRefresherContent, IonRow, IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RefresherEventDetail, toastController} from "@ionic/core";
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {CONFIG} from "../constants";
import parse from 'html-react-parser';
import {AppContext} from "../App";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";

const About: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [about, setAbout] = useState<any>();
    const {state} = useContext(AppContext);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getAbout = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}me`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                if (res.data.primaryGroup &&
                    res.data.primaryGroup.texts &&
                    res.data.primaryGroup.texts.aboutUs) {
                    setAbout(parse(res.data.primaryGroup.texts.aboutUs));
                } else {
                    setAbout('');
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getAbout();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token, state.me.primaryGroup]);

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getAbout().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle text-center>{t('about')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        {about ? (
                            <IonCard class="ion-padding cardBackground ion-no-margin noBorderRadius aboutContent">
                                {about}
                            </IonCard>
                        ) : (
                            <IonCard class="ion-padding cardBackground ion-no-margin noBorderRadius aboutContent">
                                <IonText>{t("default about text")}</IonText>
                            </IonCard>
                        )

                        }
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(About);
