import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonLoading, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.css';
import './theme/app.css';

import AppPages from "./helper/AppPages";
import Datastore from "./helper/Datastore";
import {IPage} from "./models/IPage";
import AppMenu from "./component/AppMenu";
import {IMe} from "./models/IMe";
import {IAuth} from "./models/IAuth";
import {ITime} from "./models/ITime";
import {useTranslation} from "react-i18next";
import ChangeThemeColors from "./helper/ChangeThemeColors";
import {Fetch} from "./Fetch"
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import Moment from "react-moment";
import WelcomeModal from "./component/WelcomeModal";
import RedirectListener from "./component/RedirectListener";
import {Capacitor} from "@capacitor/core";
import {Network} from "@capacitor/network";
import {PushNotifications} from "@capacitor/push-notifications";
import {SplashScreen} from "@capacitor/splash-screen";
import {FCM} from "@capacitor-community/fcm";
import {toastController} from "@ionic/core";
import compareVersions from "compare-versions";
import momentTZ from 'moment-timezone';
import TermsAndConditionsModal from "./component/TermsAndConditionsModal";
import {Device, DeviceInfo} from "@capacitor/device";
import {App as AppDevice} from '@capacitor/app';
import {StatusBar, Style} from "@capacitor/status-bar";
import {CONFIG} from "./constants";

export const AppContext = React.createContext<any>({});

let initialMe: IMe = {
    id: null,
    name: "",
    email: "",
    groups: [],
    primaryGroup: null,
    timeZone: momentTZ.tz.guess(),
    useGroupLayout: true,
    tosAccepted: null,
    hideFromGlobalRanking: false,
    messageBoxUnread: 0,
};

let initialAuth: IAuth = {
    token: null
};

let initialTime: ITime = {
    amPmTime: 'false'
}

let initialObject = Object.assign({},
    {me: initialMe},
    initialAuth,
    initialTime,
    {outdated: false},
    {showTermsModal: false},
    {agreeOrRead: "agree"},
    {initLoading: true},
    {env: 'production'}
)

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case "INIT":
            Moment.globalTimezone = action.payload.me.timeZone ? action.payload.me.timeZone : momentTZ.tz.guess();

            return {
                ...state,
                me: action.payload.me,
                token: action.payload.token,
                amPmTime: action.payload.amPmTime || 'false',
                initLoading: action.payload.initLoading,
                showTermsModal: action.payload.showTermsModal,
                agreeOrRead: action.payload.agreeOrRead,
                env: action.payload.env || 'production',
            };
        case "LOGIN":
            Datastore.setItem('token', action.payload.token);
            return {
                ...state,
                token: action.payload.token,
            };
        case "LOGOUT":
            Datastore.removeItem('token');
            return {
                ...state,
                me: initialMe,
                token: null,
            };
        case "ME":
            return {
                ...state,
                me: action.payload
            };
        case "REDIRECT":
            return {
                ...state,
                redirect: action.payload.redirect
            };
        case "TIME":
            Datastore.setItem('amPmTime', action.payload);
            return {
                ...state,
                amPmTime: action.payload
            }
        case "OUTDATED":
            return {
                ...state,
                outdated: action.payload
            }
        case "showTermsModal":
            return {
                ...state,
                showTermsModal: action.payload
            }
        case "agreeOrRead":
            return {
                ...state,
                agreeOrRead: action.payload
            }
        case "ENV":
            Datastore.setItem('env', action.payload);
            return {
                ...state,
                env: action.payload
            }
        default:
            return state;
    }
};

const App: React.FC = () => {
    const {t, i18n} = useTranslation();
    const pages = useContext(AppPages);
    const [state, dispatch] = useReducer(reducer, initialObject);
    const [showNotConnected, setShowNotConnected] = useState(false);

    setupIonicReact();

    useEffect(() => {
        let source = (state.env === 'test') ? CONFIG.API_TEST_ENDPOINT : CONFIG.API_PRODUCTION_ENDPOINT;
        window.__sportappOverrideApiEndpoint = source;
    }, [state.env]);

    useEffect(() => {
        Moment.globalLocale = i18n.language;
        if (Capacitor.isPluginAvailable('SplashScreen')) {
            SplashScreen.hide();
        }

        Datastore.getItem('token').then((result) => {
            if (result.value) {
                Fetch.call({
                    method: "get",
                    url:    "me",
                    headers: {'Authorization': `bearer ${result.value}`, 'Accept-Language': i18n.language}
                }).then(res => {
                    if (res.data.success === true) {
                        Datastore.getItem('amPmTime').then((amPmResult) => {
                            Datastore.getItem('env').then((envResult) => {
                                dispatch({
                                    type: "INIT",
                                    payload: {
                                        me: {
                                            id: res.data.id,
                                            name: res.data.name,
                                            groups: res.data.groups,
                                            primaryGroup: res.data.primaryGroup
                                        },
                                        token: result.value,
                                        initLoading: false,
                                        amPmTime: amPmResult.value,
                                        showTermsModal: false,
                                        agreeOrRead: "agree",
                                        env: envResult.value || 'production'
                                    }
                                });
                            });
                        });
                    } else {
                        // remove token and redirect to login when error code is: INVALID_CREDENTIALS
                        if (res.data.errors.length > 0) {
                            let found = false;
                            for (let i = 0; i < res.data.errors.length; i++) {
                                if (res.data.errors[i].code === 'INVALID_CREDENTIALS') {
                                    found = true;
                                    break;
                                }
                            }
                            if (found) {
                                dispatch({
                                    type: "LOGOUT"
                                });
                                dispatch({
                                    type: "REDIRECT",
                                    payload: {redirect: "/login"}
                                })
                            }
                        }
                    }
                });
            } else {
                var initObjectLoaded = initialObject;
                initObjectLoaded.initLoading = false;

                dispatch({
                    type: "INIT",
                    payload: initObjectLoaded
                });

                Datastore.getItem('env').then((envResult) => {
                    dispatch({
                        type: "ENV",
                        payload: envResult.value || 'production'
                    });
                });
            }

            Device.getInfo().then((deviceInfo: DeviceInfo) => {
                Fetch.call({
                    method: "get",
                    url:    "requiredVersions",
                    headers: {'Authorization': `bearer ${result.value}`, 'Accept-Language': i18n.language}
                }).then(res => {
                    if (['ios', 'android'].indexOf(deviceInfo.platform) >= 0) {
                        AppDevice.getInfo().then((appInfo) => {
                            console.log("APPINFO", appInfo.version, res.data[deviceInfo.platform], compareVersions(appInfo.version, res.data[deviceInfo.platform]));
                            if (compareVersions(appInfo.version, res.data[deviceInfo.platform]) === -1) {
                                dispatch({
                                    type: "OUTDATED",
                                    payload: true
                                });
                            }
                        });
                    }
                });
            });
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!showNotConnected && state.token) {
            Fetch.call({
                method: "get",
                url:    "me",
                headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
            }).then(res => {
                if (res.data.success === true) {
                    const me: IMe = {
                        id: res.data.id,
                        name: res.data.name,
                        email: res.data.email,
                        groups: res.data.groups,
                        primaryGroup: res.data.primaryGroup,
                        timeZone: res.data.timeZone,
                        useGroupLayout: res.data.useGroupLayout,
                        tosAccepted: res.data.tosAccepted,
                        hideFromGlobalRanking: res.data.hideFromGlobalRanking,
                        messageBoxUnread: res.data.messageBoxUnread,
                    };
                    dispatch({
                        type: "ME",
                        payload: me
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, [showNotConnected, state.token]);

    useEffect(() => {
        Device.getInfo().then((deviceInfo: DeviceInfo) => {
            if (state.me.primaryGroup && state.me.primaryGroup.colors && state.me.primaryGroup.colors.primary && state.me.useGroupLayout) {
                ChangeThemeColors(false, state.me.primaryGroup.colors);
                if (Capacitor.isPluginAvailable('StatusBar')) {
                    // TODO: temp fix IOS (setBackgroundColor not available in current StatusBar plugin)
                    if (deviceInfo.platform !== 'ios') {
                        StatusBar.setBackgroundColor({color: state.me.primaryGroup.colors.primary}).then(() => {
                            StatusBar.setStyle({style: Style.Dark});
                        });
                    }
                }
            } else {
                ChangeThemeColors(true);
                if (Capacitor.isPluginAvailable('StatusBar')) {
                    // TODO: temp fix IOS (setBackgroundColor not available in current StatusBar plugin)
                    if (deviceInfo.platform !== 'ios') {
                        StatusBar.setBackgroundColor({color: '#1d3d51'}).then(() => {
                            StatusBar.setStyle({style: Style.Dark});
                        });
                    }
                }
            }
        });
        // eslint-disable-next-line
    }, [state.me.primaryGroup]);


    function pushRedirectAction(dataLink: string) {
        dispatch({
            type: "REDIRECT",
            payload: {redirect: `/${dataLink}`}
        })
    }

    useEffect(() => {
        if (state.token && Capacitor.isPluginAvailable('PushNotifications')) {
            PushNotifications.requestPermissions().then( result => {
                if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register().then(() => {
                        console.log("REGISTRED -> FCM");
                        FCM.getToken().then((r: any) => {
                            Fetch.call({
                                method: "post",
                                url:    "me/setMessagingToken",
                                payload:{
                                    messagingToken: r.token
                                },
                                headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                            }).then(res => {
                                if (res.data.success !== true) {
                                    res.data.errors.forEach((item: any) => {
                                        toastController.create({
                                            message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                                        }).then((result: HTMLIonToastElement) => {
                                            return result.present();
                                        });
                                    });
                                }
                            });
                        }).catch((err: any) => console.log(err));
                    });
                } else {
                    // Show some error
                }
            });

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token) => {
                    console.log('Push registration success, token: ' + token.value);
                }
            );

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error));
                }
            );

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification) => {
                    console.log('Notification received: ' + JSON.stringify(notification));

                    if(notification.data.type === "update" && notification.data.data === "user") {
                        Fetch.call({
                            method: "get",
                            url:    "me",
                            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                        }).then(res => {
                            if (res.data.success === true) {
                                const me: IMe = {
                                    id: res.data.id,
                                    name: res.data.name,
                                    email: res.data.email,
                                    groups: res.data.groups,
                                    primaryGroup: res.data.primaryGroup,
                                    timeZone: res.data.timeZone,
                                    useGroupLayout: res.data.useGroupLayout,
                                    tosAccepted: res.data.tosAccepted,
                                    hideFromGlobalRanking: res.data.hideFromGlobalRanking,
                                    messageBoxUnread: res.data.messageBoxUnread
                                };
                                dispatch({
                                    type: "ME",
                                    payload: me
                                });
                            }
                        });
                    }

                    /*let buttons: ToastButton[] = [];
                    if (notification.data.link) {
                        buttons = [{
                            text: t('open'),
                            handler: () => pushRedirectAction(notification.data.link)
                        }, {
                            text: t('close'),
                            role: 'cancel'
                        }]
                    }
                    toastController.create({
                        message: notification.title, cssClass: "toastMessage", buttons: buttons, duration: 10000, position: 'top', translucent: false
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });*/
                }
            );

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification) => {
                    console.log('Push action performed: ' + JSON.stringify(notification));
                    if (notification.notification.data.link) {
                        pushRedirectAction(notification.notification.data.link);
                    }
                }
            );
        } else {
            console.log('push not available');
        }
        // eslint-disable-next-line
    }, [state.token]);

    Network.addListener('networkStatusChange', (status) => {
        (status.connected) ? setShowNotConnected(false) : setShowNotConnected(true);
    });

    Network.getStatus().then((status) => {
        (status.connected) ? setShowNotConnected(false) : setShowNotConnected(true);
    });

    useEffect( () => {
        if (state.token && "tosAccepted" in state.me && state.me.tosAccepted !== null  && !state.me.tosAccepted) {
            dispatch({
                type: "showTermsModal",
                payload: true
            });
            dispatch({
                type: "agreeOrRead",
                payload: "agree"
            });
        }
    }, [state.me.tosAccepted, state.token, state.me]);

    function noMenuShown(){
        return !state.token;
    }

    return (
        <IonApp>
            <AppContext.Provider value={{state, dispatch}}>
                <WelcomeModal />
                <TermsAndConditionsModal showTermsModal={state.showTermsModal} agreeOrRead={state.agreeOrRead} />
                <IonSplitPane contentId="main" when="md" style={{ "--side-max-width" : "300px"}} disabled={noMenuShown()}>
                    <IonReactRouter>
                        <RedirectListener />
                        <AppMenu />
                        <IonLoading isOpen={showNotConnected} onDidDismiss={() => setShowNotConnected(false)} message={t('waiting for internet connection')} />
                        <IonRouterOutlet id="main">
                            {pages.map((page: IPage, key: number) => {
                                return (<Route path={page.path} component={page.component} exact={true} key={key} />)
                            })}
                            <Redirect to="/" />
                        </IonRouterOutlet>
                    </IonReactRouter>
                </IonSplitPane>
            </AppContext.Provider>
        </IonApp>
    );
};

export default App;
