function hexToRGB(hex: string) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return "rgb(" + r + ", " + g + ", " + b + ")";
}

function lightenDarkenColor(col: string, amt: number) {
    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
}

export default function changeThemeColors(setToDefault: boolean = false, colors?: any) {
    const colorProperties = ['primary', 'secondary', 'tertiary'];

    if (setToDefault || colors.length === 0) {
        colorProperties.forEach((colorProperty) => {
            document.documentElement.style.removeProperty(`--ion-color-${colorProperty}`);
            document.documentElement.style.removeProperty(`--ion-color-${colorProperty}-rgb`);
            document.documentElement.style.removeProperty(`--ion-color-${colorProperty}-contrast`);
            document.documentElement.style.removeProperty(`--ion-color-${colorProperty}-contrast-rgb`);
            document.documentElement.style.removeProperty(`--ion-color-${colorProperty}-shade`);
            document.documentElement.style.removeProperty(`--ion-color-${colorProperty}-tint`);
        });
    } else if (colors && colors.primary && colors.secondary && colors.tertiary) {
        colorProperties.forEach((colorProperty) => {
            document.documentElement.style.setProperty(`--ion-color-${colorProperty}`, colors[colorProperty]);
            document.documentElement.style.setProperty(`--ion-color-${colorProperty}-rgb`, hexToRGB(colors[colorProperty]));
            if (colorProperty === 'primary') {
                document.documentElement.style.setProperty(`--ion-color-${colorProperty}-contrast`, colors.tertiary);
                document.documentElement.style.setProperty(`--ion-color-${colorProperty}-contrast-rgb`, hexToRGB(colors.tertiary));
            } else {
                document.documentElement.style.setProperty(`--ion-color-${colorProperty}-contrast`, '#ffffff');
                document.documentElement.style.setProperty(`--ion-color-${colorProperty}-contrast-rgb`, hexToRGB('#ffffff'));
            }
            document.documentElement.style.setProperty(`--ion-color-${colorProperty}-shade`, lightenDarkenColor(colors[colorProperty], 20));
            document.documentElement.style.setProperty(`--ion-color-${colorProperty}-tint`, lightenDarkenColor(colors[colorProperty], -20));
        });
    }
}
