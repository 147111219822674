import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonGrid,
    IonMenuButton,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonRow,
    IonCol,
    IonToolbar,
    IonRefresherContent,
    IonRefresher,
    IonList,
    IonButton, IonCard, IonFab,
} from "@ionic/react";
import {AppContext} from "../App";
import Axios from "axios";
import {CONFIG} from "../constants";
import {IUserRanking} from "../models/IUserRanking";
import {IUserGroup} from "../models/IUserGroup";
import {toastController, RefresherEventDetail} from "@ionic/core";
import GameRulesModal from "../component/GameRulesModal";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";

const Rankings: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [rankings, setRankings] = useState<IUserRanking []>([]);
    const {state} = useContext(AppContext);
    const [showGameRulesModal, setShowGameRulesModal] = useState(false);
    const [pages, setPages] = useState(1);
    const [userRank, setUserRank] = useState<any>();
    const [rankingGroup, setRankingGroup] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [groupPageChange, setGroupPageChange ] = useState<any>();
    const [uniquePosition, setUniquePosition] = useState<number>(0);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        if (state.me.primaryGroup) {
            setRankingGroup(state.me.primaryGroup.id);
            setGroupPageChange({ group: state.me.primaryGroup.id, page: 1});
        } else {
            setRankingGroup("global");
            setGroupPageChange({ group: "global", page: 1});
        }
        // eslint-disable-next-line
    }, [state.me.primaryGroup]);

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getRankings(true);
            getUserRank();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token, groupPageChange]);

    async function getRankings(reset:boolean) {
        let getUrl = null;
        const items: IUserRanking[] = reset ? [] : rankings;
        const rankingOffset = pages && pages > 1 && currentPage > 1 ? (currentPage * 10) - 10 : '0';

        if (typeof rankingGroup === 'undefined' || rankingGroup === 'global') {
            getUrl = `rankings/${CONFIG.COMPETITION}/global`;
        } else {
            getUrl = `rankings/${CONFIG.COMPETITION}/byGroup/${rankingGroup}`;
        }
        getUrl += `?limit=11&offset=${rankingOffset}`;
        Axios.get(`${CONFIG.API_ENDPOINT}${getUrl}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setPages(Math.floor(res.data.count/10));
                setRankings([...items, ...res.data.ranking]);
                if(uniquePosition > 0){
                    const scrollToElement: HTMLElement | null = document.getElementById(uniquePosition.toString());
                    if(scrollToElement){
                        scrollToElement.scrollIntoView();
                    }
                    setUniquePosition(0)
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        })
    }

    function getRowClass(index: any) {
        let rowClass;
        if (isEven(index)) {
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function getUserRank() {
        let getUrl = null;
        if (typeof rankingGroup === 'undefined' || rankingGroup === 'global') {
            getUrl = `rankings/${CONFIG.COMPETITION}/global/`;
        } else {
            getUrl = `rankings/${CONFIG.COMPETITION}/byGroup/${rankingGroup}/`;
        }
        Axios.get(`${CONFIG.API_ENDPOINT}${getUrl}myPosition`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                let uniquePositionLimit = Math.floor((res.data.uniquePosition-1)/10) + 1;
                getUserScore((uniquePositionLimit * 10) - 10);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }

        })
    }

    function getUserScore(positionLimit:number){
        let getUrl = null;
        if (typeof rankingGroup === 'undefined' || rankingGroup === 'global') {
            getUrl = `rankings/${CONFIG.COMPETITION}/global`;
        } else {
            getUrl = `rankings/${CONFIG.COMPETITION}/byGroup/${rankingGroup}`;
        }
        getUrl += `?limit=11&offset=${positionLimit}`;
        Axios.get(`${CONFIG.API_ENDPOINT}${getUrl}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                const userRankInfo = res.data.ranking.filter((rank:any) => parseInt(rank.user.id) === parseInt(state.me.id));
                setUserRank(userRankInfo[0]);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        })
    }

    function handleUserRankClick(uniquePosition:any){
        let uniquePositionLimit = Math.floor((uniquePosition-1)/10) + 1;
        const oldCurrentPage = currentPage;
        setCurrentPage(uniquePositionLimit);
        setUniquePosition(uniquePosition);
        if(uniquePosition > 0 && oldCurrentPage === uniquePositionLimit){
            const scrollToElement: HTMLElement | null = document.getElementById(uniquePosition.toString());
            if(scrollToElement){
                scrollToElement.scrollIntoView();
            }
            setUniquePosition(0);
        }
    }

    const showRankings = () => {
        if (rankings && rankings.length > 0) {
            return (
                <IonList class="ion-no-padding ion-no-margin">
                    {rankings.map((ranking: IUserRanking, index: number) =>
                        <IonItem lines="none" routerDirection="forward" detail={false} routerLink={`/rankings/${rankingGroup}/${ranking.user.id}`} className={`ion-align-items-center ${getRowClass(index)} ${parseInt(ranking.user.id) === parseInt(state.me.id) ? 'activeRankingRow' : '' }`} key={index} id={ranking.uniquePosition}>
                            <IonCol size-xs="3" size-sm="2" size-md="1">
                                <IonBadge style={{verticalAlign : "middle"}}>{ranking.position}</IonBadge>
                            </IonCol>
                            <IonCol size-xs="6" size-sm="8" size-md="9">
                                <IonText> {ranking.user.name} </IonText>
                            </IonCol>
                            <IonCol size-xs="3" size-sm="2" size-md="2">
                                <IonText>{ranking.score}</IonText>
                            </IonCol>
                        </IonItem>
                    )}
                </IonList>)
        } else {
            return <IonCard className="cardBackground noBorderRadius ion-padding ion-no-margin">{t('no rankings found')}</IonCard>
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getRankings(true).then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    const getPaginationSelectOptions = (pages:number) => {
        let pageRows = [];
        for(let i:number = 1; i <= pages; i++){
            pageRows.push(<IonSelectOption key={"page" + i} value={i}>{t('pagination_text',{page: i, pages: pages})}</IonSelectOption>);
        }

        return pageRows;
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('rankings')}</IonTitle>
                    <IonButtons slot="end" >
                        <IonButton onClick={() => setShowGameRulesModal(true)}>{t('rules')}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`, "--padding-bottom" : "80px"}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        {rankingGroup &&
                        <IonItem style={{ "--background" : "transparent", "--inner-padding-end" : "none"}} className="ion-no-padding">
                            <IonLabel color="secondary"><strong>{t('group')}</strong></IonLabel>
                            <IonSelect interfaceOptions={{"id":"rankingGroupSelectAlert"}} style={{ color : "var(--ion-color-secondary)", fontWeight : "bold"}} className="ion-align-items-center" cancelText={t('cancel')} okText={t('ok')} placeholder={t('select group')} onIonChange={e => {setCurrentPage(1);setRankingGroup(e.detail.value);setGroupPageChange({ group: e.detail.value, page: 1});}} value={rankingGroup}>
                                <IonSelectOption value="global">{t('global')}</IonSelectOption>
                                {state.me.groups.map((group: IUserGroup, index: number) =>
                                    <IonSelectOption key={index} value={group.id}>{group.name}</IonSelectOption>
                                )}
                            </IonSelect>
                        </IonItem>
                        }
                        <IonGrid class="ion-margin-top ion-no-padding">
                            <IonRow class="ion-no-padding">
                                <IonCol size="12" class="ion-no-padding">
                                    <IonRow class="rankingTitles ion-padding-start ion-padding-end">
                                        <IonCol size-xs="3" size-sm="2" size-md="1">
                                            <IonText color="secondary"><i className="fas fa-trophy"/></IonText>
                                        </IonCol>
                                        <IonCol size-xs="6" size-sm="8" size-md="9">
                                            <IonText color="secondary">{t("user")}</IonText>
                                        </IonCol>
                                        <IonCol class="rankingPoints" size-xs="3" size-sm="2" size-md="2">
                                            <IonText color="secondary">{t("points")}</IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol class="ion-no-padding">
                                    {showRankings()}
                                    {currentPage && pages > 1 && (pages - 1) > 0 &&
                                    <IonRow className="ion-padding-top">
                                        <IonCol sizeXs="3">
                                            {currentPage > 1 &&
                                            <IonButton fill="solid" onClick={() => {setCurrentPage(currentPage - 1);setGroupPageChange({ group: rankingGroup, page: currentPage - 1}); }}><i className="far fa-chevron-left"/></IonButton>
                                            }
                                        </IonCol>
                                        <IonCol sizeXs="6">
                                            <IonSelect className="ion-padding ion-text-center" placeholder={t("select a page")} value={currentPage} onIonChange={e => {setCurrentPage(e.detail.value); setGroupPageChange({ group: rankingGroup, page: e.detail.value})}}>
                                                {getPaginationSelectOptions(pages)}
                                            </IonSelect>
                                        </IonCol>
                                        <IonCol sizeXs="3" className="ion-text-right">
                                            {!(currentPage === pages) &&
                                            <IonButton fill="solid" onClick={() => {setCurrentPage(currentPage + 1);setGroupPageChange({ group: rankingGroup, page: currentPage + 1}); }}><i className="far fa-chevron-right"/></IonButton>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    }
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                </IonRow>
                {userRank &&
                    <IonFab vertical="bottom" slot="fixed" className="ion-padding-horizontal rankingFab">
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" sizeLg="8" sizeXl="6">
                                <IonButton className="rankingFabButton" onClick={() => {handleUserRankClick(userRank.uniquePosition)}}>
                                    <IonCol size-xs="3" size-sm="2" size-md="1" className="ion-no-padding">
                                        <IonBadge className="rankingFabBadge" style={{verticalAlign : "middle"}}>{userRank.position}</IonBadge>
                                    </IonCol>
                                    <IonCol size-xs="6" size-sm="8" size-md="9" className="ion-no-padding">
                                        <IonText> {userRank.user.name} </IonText>
                                    </IonCol>
                                    <IonCol size-xs="3" size-sm="2" size-md="2" className="ion-no-padding">
                                        <IonText>{userRank.score}</IonText>
                                    </IonCol>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonFab>
                }
                <GameRulesModal showGameRulesModal={showGameRulesModal} onChange={setShowGameRulesModal} />
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Rankings);
