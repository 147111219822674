import React, {useContext, useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol,
    IonContent,
    IonHeader,
    IonPage, IonRefresher, IonRefresherContent, IonRow, IonSpinner,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import {CONFIG} from "../constants";
import {Img} from "react-image";
import Axios from "axios";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {INews} from "../models/INews";
import parse from 'html-react-parser';
import Moment from "react-moment";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import TimeMoment from "../component/TimeMoment";

interface NewsProps extends RouteComponentProps <{id: string}> {}

const NewsItem: React.FC<NewsProps> = ({history, match}) =>  {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [newsItem, setNewsItem] = useState<INews>();

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getNewsItem = async () => {
        Axios.get(`${CONFIG.API_ENDPOINT}news/${match.params.id}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setNewsItem(res.data.news);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getNewsItem();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    const showNewsItem = () => {
        if (newsItem) {
            return (<IonCard class="ion-no-margin cardBackground noBorderRadius" key={newsItem.code}>
                {newsItem.image ?
                    <Img src={newsItem.image} loader={<IonSpinner name="crescent" class="ion-margin-top" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} />} />
                    :
                    <div className="placeholderImage">
                        <i className="fas fa-image"/>
                    </div>
                }

                <IonCardHeader>
                    <IonCardTitle><h1 className="ion-no-margin">{newsItem.title}</h1></IonCardTitle>
                    <IonCardSubtitle className="newsItemPublishing"><Moment unix format="LL">{newsItem.pubDate}</Moment> <TimeMoment amPmTime={state.amPmTime} time={newsItem.pubDate} timeZone={state.me.timeZone} /></IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent class="ion-padding" style={{ fontSize: "16px"}}>
                    {parse(newsItem.content)}
                </IonCardContent>
            </IonCard>)
        } else {
            return <IonCard className="cardBackground ion-no-margin noBorderRadius ion-padding">{t('no news found')}</IonCard>
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getNewsItem().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/news" text="" />
                    </IonButtons>
                    <IonTitle>{newsItem && newsItem.title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeLg="8" sizeXl="6">
                        {showNewsItem()}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(NewsItem);
