import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {IonButton, IonCol, IonContent, IonInput, IonPage, IonRow, IonSpinner, IonText} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {Link} from "react-router-dom";
import {Img} from "react-image";
import {RouteComponentProps} from "react-router";

const PasswordReset: React.FC<RouteComponentProps> = ({history, match}) => {
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);

    const submitFormByEnter = function(e: any) {
        if (e.keyCode === 13 && email !== '') {
            resetPassword(e);
        }
    };

    const resetPassword = function(e: any) {
        e.preventDefault();
        if (email) {
            Axios.post(`${CONFIG.API_ENDPOINT}authentication/requestPasswordReset`, {email: email })
                .then((res: any) => {
                    if (res.data.success === true) {
                        setEmail('');
                        history.push(`/waitForEmail/${res.data.recoveryKey}`);
                    } else {
                        if (res.data.errors) {
                            let errorMessageArray: any = [];
                            res.data.errors.forEach((item: any) => {
                                if(!errorMessageArray.includes(t(`errors.${item.code}`))){
                                    errorMessageArray.push(t(`errors.${item.code}`));
                                }
                            });
                            setErrorMessages(errorMessageArray);
                        }
                    }
                })
        }
    };

    return (
        <IonPage>
            <IonContent className="ion-padding authenticateBackground">
                <div className="authenticateWrapper">
                    <Img src="/assets/images/logoYFA.png" className="logoImage" loader={<IonSpinner name="crescent" class="ion-margin-bottom" style={{ marginLeft: "auto", marginRight: "auto", display: "block"}} />} />
                    {errorMessages && errorMessages.length > 0 &&
                        <>

                            {errorMessages.map((errorMessage: string, index) => {
                                return (
                                    <IonText key={errorMessage + index} className="ion-text-center ion-margin-bottom formError" color="danger">
                                        <i className="fas fa-exclamation-circle"/> {errorMessage}
                                    </IonText>
                                )
                            })}

                        </>
                    }
                    <form onSubmit={resetPassword} onKeyUp={submitFormByEnter}>
                        <IonInput type="email" class="defaultInput ion-padding-horizontal" name="email" placeholder={t('email address')} inputmode="email" value={email} autofocus={true} onIonChange={e => {setEmail(e.detail.value!)}} required />
                        <IonRow>
                            <IonCol class="ion-no-padding ion-text-center">
                                <IonButton type="submit" expand="full" class="defaultButton" disabled={email === ''}>{t('send reset link')}</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <span className="line"/>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-text-center"><Link to="/login" className="defaultLink">{t('login')}</Link></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <Link to="/register" className="defaultLink">{t('register')}</Link>
                            </IonCol>
                        </IonRow>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(PasswordReset);
