import {
    IonButtons, IonCard, IonCardContent, IonCol,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage, IonRow, IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {AppContext} from "../App";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import moment from "moment";
import KnockOutGames from "../helper/KnockOutGames";
import {RouteComponentProps} from "react-router-dom";

const KnockOut: React.FC<RouteComponentProps> = ({history}) => {
    const {i18n, t} = useTranslation();
    const {state} = useContext(AppContext);
    const matches = useContext(KnockOutGames);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    function getDateHeader(matchDateKey: any, matchesArray: any) {
        let dateString = moment(matchDateKey, "DD-MM-YYYY").locale(i18n.language).format('LL');
        if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment(), 'day')) {
            dateString = t('today');
        } else if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment().add(1, 'days'), 'day')) {
            dateString = t('tomorrow');
        } else if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment().subtract(1, 'days'), 'day')) {
            dateString = t('yesterday');
        }
        return (
            <IonText color="secondary" class="matchesOverviewDateHeader" style={{'margin-bottom':'0px'}}><strong>{dateString} - {matchesArray[matchDateKey][0].title}</strong></IonText>
        )
    }

    const showMatches = () => {
        if (matches && matches.length > 0) {
            let matchesArray = matches.reduce((groups :any, game: any) => {
                const date = moment.unix(game.startTime).format('DD-MM-YYYY');
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, []);
            let matchesOrder = Object.keys((matchesArray));
            return (
                matchesOrder.map((matchDateKey: any) =>
                <div key={matchDateKey} className="ion-margin-top">
                    {matchesArray[matchDateKey].map((match: any) =>
                        <div key={match.id}>
                            <IonCard class="cardBackground ion-no-margin ion-margin-bottom noBorderRadius">
                                <IonCardContent class="ion-no-padding">
                                    <IonRow>
                                        <IonCol size="12" class="ion-padding">
                                            <IonText class="ion-text-center"><h2 style={{fontSize : "16px", fontWeight : "bold"}}>{getDateHeader(matchDateKey, matchesArray)}</h2></IonText>
                                        </IonCol>
                                        {/*<IonCol size="12" class="ion-no-padding">*/}
                                        {/*    <IonRow class="ion-text-center">*/}
                                        {/*        <IonCol class="ion-no-padding ion-padding-start" size="6" style={{ paddingRight : "10px" }}>*/}
                                        {/*            <IonThumbnail class="teamLogo" style={{ "--size" : "60px"}}><Img src={match.teams[0].logo} loader={<IonSpinner name="crescent" />}/></IonThumbnail>*/}
                                        {/*            <div style={{ marginTop : "5px" }}><IonText style={{ fontSize: "14px"}}>{match.teams[0].name}</IonText></div>*/}
                                        {/*        </IonCol>*/}
                                        {/*        <IonCol size="6" class="ion-no-padding ion-padding-end" style={{ paddingLeft : "10px" }}>*/}
                                        {/*            <IonThumbnail class="teamLogo" style={{ "--size" : "60px"}}><Img src={match.teams[1].logo} loader={<IonSpinner name="crescent" />}/></IonThumbnail>*/}
                                        {/*            <div style={{ marginTop : "5px" }}><IonText style={{ fontSize : "14px"}}>{match.teams[1].name}</IonText></div>*/}
                                        {/*        </IonCol>*/}
                                        {/*    </IonRow>*/}
                                        {/*</IonCol>*/}
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </div>
                    )}
                </div>
            ));
        } else {
            return <IonCard className="cardBackground noBorderRadius ion-padding-horizontal ion-no-margin ion-margin-top"><IonText><p>{t('no matches found')}</p></IonText></IonCard>;
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle text-center>{t('knock out phase')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="10" sizeXl="8">
                        {showMatches()}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(KnockOut);
