import React from 'react';
import {IonText} from "@ionic/react";
import {useTranslation} from "react-i18next";

function ScrollArrow(props:any) {
    const {t} = useTranslation();

   return (
        <div className="scrollArrow" style={{ opacity : props.hasNotScrolled ? "1": "0", visibility : props.hasNotScrolled ? "visible": "hidden"}}>
            <IonText style={{display:"block"}}>{t("scroll")}</IonText>
            <i className="fal fa-chevron-double-down"/>
        </div>
    );
}

export default React.memo(ScrollArrow);
