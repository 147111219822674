import {Storage} from "@capacitor/storage";

class Datastore {
    getShowWelcomeModal(primaryGroupId: number, userId: number, force: boolean = false) {
        let show = this.getItem(`wms_${primaryGroupId}_${userId}`).then((result) => {
            if (result.value === null || force) {
                return true;
            } else {
                return false;
            }
        });
        return show;
    }

    async setItem(key: string, value: string) {
        await Storage.set({key: key, value: value});
    }

    async getItem(key: string) {
        return await Storage.get({key: key});
    }

    async removeItem(key: string) {
        await Storage.remove({key: key});
    }

    async clear() {
        await Storage.clear();
    }
}

export default new Datastore();
