import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IonButton, IonCheckbox, IonContent, IonItem, IonLabel, IonModal, IonText} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {AppContext} from "../App";
import {toastController} from "@ionic/core";
import {IMe} from "../models/IMe";

interface termsAndConditionsModalPropsInterface {
    showTermsModal: boolean,
    agreeOrRead: string
}

const TermsAndConditionsModal: React.FC<termsAndConditionsModalPropsInterface> = (props) => {
    const {t, i18n} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const [agreementText, setAgreementText] = useState<any>({});
    const [tosAgreed, setTosAgreed] = useState(false);

    useEffect(() => {
        function getTermsAndConditions() {
            Axios.get(`${CONFIG.API_ENDPOINT}iam/${i18n.language}/termsandconditions`).then(res => {
                setAgreementText(res.data);
            });
        }
        if (i18n.language){
            getTermsAndConditions();
        }
    },[i18n.language]);

    const getMe = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}me`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            const me: IMe = {
                id: res.data.id,
                name: res.data.name,
                email: res.data.email,
                groups: res.data.groups,
                primaryGroup: res.data.primaryGroup,
                timeZone: res.data.timeZone,
                useGroupLayout: res.data.useGroupLayout,
                tosAccepted: res.data.tosAccepted,
                hideFromGlobalRanking: res.data.hideFromGlobalRanking,
                messageBoxUnread: res.data.messageBoxUnread,
            };
            dispatch({
                type: "ME",
                payload: me
            });
        });
    };

    function handleTosSubmit() {
        Axios.post(`${CONFIG.API_ENDPOINT}me`, {
          tosAccepted: true
        },{
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                getMe().then(() => {
                    toastController.create({
                        message: t('terms and conditions accepted'), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
                dispatch({
                    type: "showTermsModal",
                    payload: false
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    }

    return (
        <IonModal isOpen={props.showTermsModal} id='termsAndConditionsModal' backdropDismiss={false}>
            <IonContent scrollEvents={true}>
                <div className="ion-padding" style={{ overflowY: "auto"}}>
                    <IonText className="ion-margin-bottom" color="primary"><h2>{agreementText.subject}</h2></IonText>
                    <div dangerouslySetInnerHTML={{__html: agreementText.body}}/>
                </div>
                <div>
                    {props.agreeOrRead === "read" &&
                        <IonButton expand="full" class="ion-margin" onClick={() => {
                            dispatch({type: "agreeOrRead", payload: "agree"});
                            dispatch({type: "showTermsModal", payload: false})
                        }}>{t('close')}</IonButton>
                    }
                    {props.agreeOrRead === "agree" &&
                        <>
                            <IonItem lines="none">
                                <IonCheckbox color="primary " slot="start" onClick={() => setTosAgreed(!tosAgreed)} />
                                <IonLabel style={{ whiteSpace: "pre-wrap"}}>{t("I have read and agree to the")} {t("terms and conditions")}</IonLabel>
                            </IonItem>
                            <IonButton expand="full" class="ion-margin" disabled={!tosAgreed} onClick={() => { handleTosSubmit();

                            }}>{t('submit')}</IonButton>
                        </>
                    }
                </div>
            </IonContent>
        </IonModal>
    )
}

export default React.memo(TermsAndConditionsModal);
