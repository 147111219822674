import React from 'react';
import Moment from "react-moment";

function TimeMoment(props: any) {
    return (
        <Moment unix format={props.amPmTime === 'true' ? 'hh:mm A' : 'HH:mm'} tz={props.timeZone}>{props.time}</Moment>
    )
}

export default React.memo(TimeMoment);
