import React, {useContext, useState} from "react";
import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonContent,
    IonInput, IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner, IonText, useIonViewWillEnter
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Img} from "react-image";
import {AppContext} from "../App";
import {RouteComponentProps} from "react-router";
import {Fetch} from "../Fetch";

interface IRegisterProps extends RouteComponentProps<{joinGroupQrCode: string}> {}

const Register: React.FC<IRegisterProps> = ({history, match}) => {
    const {t, i18n} = useTranslation();
    const [name, setName] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [tosAccepted, setTosAccepted] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const {dispatch} = useContext(AppContext);

    useIonViewWillEnter(() => {
        setErrorMessages([]);
    });

    const submitFormByEnter = function(e: any) {
        if (e.keyCode === 13) {
            if (name !== '' && email !== '' && tosAccepted) {
                register(e);
            } else {
                setErrorMessages([t('please fill in all required fields')]);
            }
        }
    };

    const register = function(e: any) {
        e.preventDefault();
        if (name && email) {
            Fetch.call({
                method: "post",
                url: "authentication/register",
                payload: {
                    "name": name,
                    "email": email,
                    "tosAccepted": tosAccepted,
                    "joinGroupQrCode": match.params.joinGroupQrCode
                },
                headers: {'Accept-Language': i18n.language}
            }).then((res: any) => {
                if (res.data.success === true) {
                    setName(null);
                    setEmail(null);
                    setTosAccepted(false);
                    history.push(`/waitForEmail/${res.data.recoveryKey}`);
                    // dispatch({type: "REDIRECT", payload: {redirect: "waitForEmail"}})
                } else {
                    if (res.data.errors) {
                        let errorMessageArray: any = [];
                        res.data.errors.forEach((item: any) => {
                            if(!errorMessageArray.includes(t(`errors.${item.code}`))){
                                errorMessageArray.push(t(`errors.${item.code}`));
                            }
                        });
                        setErrorMessages(errorMessageArray);
                    }
                }
            });
        }
    };

    return (
        <IonPage>
            <IonContent className="ion-padding authenticateBackground">
                <div className="authenticateWrapper">
                    <Img src="/assets/images/logoYFA.png" className="logoImage" loader={<IonSpinner name="crescent" class="ion-margin-bottom" style={{ marginLeft: "auto", marginRight: "auto", display: "block"}} />} />
                    {errorMessages && errorMessages.length > 0 &&
                        <>

                            {errorMessages.map((errorMessage: string, index) => {
                                return (
                                    <IonText key={errorMessage + index} className="ion-text-center ion-margin-bottom formError" color="danger">
                                        <i className="fas fa-exclamation-circle"/> {errorMessage}
                                    </IonText>
                                )
                            })}

                        </>
                    }
                    <form onSubmit={register} onKeyUp={submitFormByEnter}>
                        <IonInput type="text" class="defaultInput ion-padding-horizontal" name="name" inputmode="text" placeholder={t('name')} autofocus={true} value={name} onIonChange={e => {setName(e.detail.value!)}} required />
                        <IonInput type="email" class="defaultInput ion-padding-horizontal" name="email" placeholder={t('email address')} inputmode="email" value={email} onIonChange={e => {setEmail(e.detail.value!)}} required />
                        <IonItem lines="none" className="ion-no-padding">
                            <IonCheckbox color="primary " slot="start" onClick={() => setTosAccepted(!tosAccepted)} checked={tosAccepted} />
                            <IonLabel style={{ whiteSpace: "pre-wrap"}}>{t("I have read and agree to the")} <IonButton fill="clear" className="linkButton ion-no-padding" onClick={() => {dispatch({type: "agreeOrRead", payload: "read"}); dispatch({type: "showTermsModal", payload: true})}}>{t("terms and conditions")}</IonButton></IonLabel>
                        </IonItem>
                        <IonRow>
                            <IonCol class="ion-no-padding ion-text-center">
                                <IonButton type="submit" expand="full" class="defaultButton" disabled={name === '' || email === '' || !tosAccepted}>{t('register')}</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <span className="line"/>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-text-center"><Link to="/login" className="defaultLink">{t('login')}</Link></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <Link to="/password-reset" className="defaultLink">{t('reset password')}</Link>
                            </IonCol>
                        </IonRow>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Register);
