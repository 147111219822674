import React, {useContext, Suspense, useEffect} from 'react';
import {
    IonButtons, IonCol,
    IonContent, IonGrid,
    IonHeader,
    IonMenuButton,
    IonButton,
    IonPage, IonRow, IonSpinner, IonText,
    IonTitle,
    IonToolbar, IonFooter
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {AppContext} from "../App";
import {Link, RouteComponentProps} from "react-router-dom";
import {useImage} from "react-image";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {IPrimaryGroup} from "../models/IPrimaryGroup";
import goToAppStore from "../helper/goToAppStore";

interface LogoImageInterface {
    group: IPrimaryGroup,
    useGroupLayout: boolean
}

const LogoImage: React.FC<LogoImageInterface> = ({group, useGroupLayout}) => {
    const {src} = useImage({
        srcList:
            getPrimaryGroupAsset(group, 'logo', useGroupLayout)
    });

    return <img src={src} className="companyLogoHome" alt="logo"/>;
};

const Home: React.FC<RouteComponentProps> = ({history}) => {
    const {t}  = useTranslation();
    const {state} = useContext(AppContext);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('home')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" scrollY={true} style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <Suspense fallback={<IonSpinner class="ion-margin-top" name="crescent" />}>
                    <LogoImage group={state.me.primaryGroup} useGroupLayout={state.me.useGroupLayout}/>
                </Suspense>
                <IonGrid className="ion-padding ion-text-center homeIconsWrapper">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" sizeLg="8" sizeXl="6">
                            <IonRow class="homeIconRow">
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/news"><i className="fas fa-newspaper" /><p>{t('news')}</p></Link></IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/matches"><i className="fas fa-futbol" /><p>{t('matches')}</p></Link></IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/predictions"><i className="fas fa-plus-circle"/><p>{t('predictions')}</p></Link></IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow class="homeIconRow">
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/rankings"><i className="fas fa-dice"/><p>{t('rankings')}</p></Link></IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/vouchers"><i className="far fa-percentage" /><p>{t('vouchers')}</p></Link></IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/about"><i className="fas fa-info-circle"/><p>{t('about')}</p></Link></IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow class="homeIconRow ion-hide-md-down">
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/statistics"><i className="fas fa-chart-pie-alt"/><p>{t('statistics')}</p></Link></IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/groups"><i className="fas fa-user-friends" /><p>{t('groups')}</p></Link></IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText class="secondaryLinkColor"><Link className="homeIcon" to="/profile"><i className="fas fa-cogs"/><p>{t('profile')}</p></Link></IonText>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            {state.outdated &&
                <IonFooter translucent={true} className="ion-no-border">
                    <IonToolbar className="ion-no-padding">
                        <IonButton className="ion-no-margin" expand="full" onClick={(e) => {e.preventDefault(); goToAppStore()}}><i className="fas fa-store ion-margin-end"/> {t('New version available in store')}</IonButton>
                    </IonToolbar>
                </IonFooter>
            }
        </IonPage>
    )
};

export default React.memo(Home);
