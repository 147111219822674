import Axios, {Method} from "axios";
import {CONFIG} from "./constants";
import {HTTP} from '@ionic-native/http';

export interface FetchRequest {
    url?: string;
    method?: string;
    payload?: any;
    headers?: object
}

export interface FetchResponse {
    data:   any;
    status: number
    cached: boolean
}

export interface FetchInstance {
    cache : any;
    call<R = FetchResponse>(request : FetchRequest): Promise<R>;
}

export const Fetch: FetchInstance = {
    cache: {},

    call: function <R = FetchResponse>(request: any): Promise<R> {
        let fetchThis = this;
        let url = CONFIG.API_ENDPOINT + request.url;

        let _resolve: any;
        let promise = new Promise<R>(function (resolve, reject) {
            _resolve = resolve;
        });

        if (window.hasOwnProperty("cordova")) {
            let cacheable = request.method?.toLowerCase() === "get";

            let httpRequest : any = {
                method:     request.method?.toLowerCase() as "get"|"post",
                headers:    request.headers as {[index: string]: string;},
            };

            if(request.payload) {
                httpRequest.data = request.payload;
            }

            if(cacheable) {
                if(this.cache.hasOwnProperty(url)) {
                    let cacheItem = this.cache[url] as any;
                    httpRequest.headers['if-modified-since'] = cacheItem.lastModified;
                }
            }

            HTTP.setFollowRedirect(false);
            HTTP.setDataSerializer('json');
            HTTP.sendRequest(url, httpRequest).then(function(response: any) {
                if(response.status === 200) {
                    let parsedData = JSON.parse(response.data);

                    if (cacheable) {
                        if (response.headers['last-modified']) {
                            fetchThis.cache[url] = {
                                lastModified: response.headers['last-modified'],
                                data: parsedData
                            }
                        }
                    }

                    let fResponse: FetchResponse = {
                        status: response.status,
                        data:   parsedData,
                        cached: false,
                    }
                    _resolve(fResponse);
                }
            }).catch(function(response) {
                if(response.status === 304) {
                    let fResponse: FetchResponse = {
                        status: 200,
                        data:   fetchThis.cache[url].data,
                        cached: true,
                    }
                    _resolve(fResponse);
                }
            });
        } else {
            Axios.request({
                url: url,
                method: request.method as Method,
                data: request.payload,
                headers: request.headers,
            }).then(function (response) {
                let fResponse: FetchResponse = {
                    status: response.status,
                    data:   response.data,
                    cached: false,
                }
                _resolve(fResponse);
            });
        }

        return promise;
    }
};
