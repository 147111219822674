import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner, IonText
} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {Img} from "react-image";
import {RouteComponentProps} from "react-router-dom";

interface IWaitForEmailProps extends RouteComponentProps<{recoveryKey: string, qrCode: string}> {}

const WaitForEmail: React.FC<IWaitForEmailProps> = ({history, match}) => {
    const {t} = useTranslation();
    const [code, setCode] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);

    const submitFormByEnter = function(e: any) {
        if (e.keyCode === 13 && code !== '') {
            validateCode(e);
        }
    };

    const validateCode = function(e: any) {
        e.preventDefault();

        if (code) {
            Axios.post(`${CONFIG.API_ENDPOINT}authentication/validateKey`, {'recoveryKey': match.params.recoveryKey, 'code': code })
                .then((validateResponse: any) => {
                    if (validateResponse.data.success === true) {
                        history.push(`/changePassword/${match.params.recoveryKey}/${code}`);
                        setCode('');
                    } else {
                        setErrorMessages([t('the password reset code is invalid or has expired')])
                    }
                });
        }
    };

    return (
        <IonPage>
            <IonContent className="ion-padding authenticateBackground">
                <div className="authenticateWrapper">
                    <Img src="/assets/images/logoYFA.png" className="logoImage" loader={<IonSpinner name="crescent" class="ion-margin-bottom" style={{ marginLeft: "auto", marginRight: "auto", display: "block"}} />} />
                    {errorMessages && errorMessages.length > 0 &&
                        <>

                            {errorMessages.map((errorMessage: string, index) => {
                                return (
                                    <IonText key={errorMessage + index} className="ion-text-center ion-margin-bottom formError" color="danger">
                                        <i className="fas fa-exclamation-circle"/> {errorMessage}
                                    </IonText>
                                )
                            })}

                        </>
                    }
                    <form onSubmit={validateCode} onKeyUp={submitFormByEnter}>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="12">
                                <p>{t('we have send you an email with a validation code')}<br /><br />{t('please enter the code below')}</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonLabel position="stacked">{t('code')}</IonLabel>
                                <IonInput type="number" name="code" minlength={6} maxlength={6} inputmode="text" class="defaultInput" value={code} onIonChange={e => {setCode(e.detail.value!)}} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-no-padding ion-text-center">
                                <IonButton type="submit" expand="full" class="defaultButton" disabled={code === ''}>{t('validate code')}</IonButton>
                            </IonCol>
                        </IonRow>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(WaitForEmail);
