import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonCol,
    IonContent,
    IonHeader,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSlide,
    IonSlides,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar, useIonAlert,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from "@ionic/react";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {CONFIG} from "../constants";
import {AppContext} from "../App";
import Moment from "react-moment";
import {Img} from "react-image";
import Axios from "axios";
import {IVoucher} from "../models/IVoucher";
import parse from 'html-react-parser';
import VoucherTimer from "../component/VoucherTimer";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";
import TimeMoment from "../component/TimeMoment";

interface IVoucherEdit extends IVoucher {
    timeActivated: number,
    timeout: number,
    baseTimeout: number,
}

const Vouchers: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [vouchers, setVouchers] = useState<IVoucherEdit []>([]);
    const [userOnPage, setUserOnPage] = useState<boolean>(false);
    const [viewVouchers, setViewVouchers] = useState<any>("available");
    const [vouchersLoading, setVouchersLoading] = useState<any>(true);
    const [screenWidth, setScreenWidth] = useState<any>(0);
    const {state} = useContext(AppContext);
    const [present] = useIonAlert();

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, []);

    const getVouchers = async () => {
        setVouchersLoading(true);
        Axios.get(`${CONFIG.API_ENDPOINT}vouchers/list`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            setVouchersLoading(false);
            if (res.data.success === true) {
                if (res.data.vouchers) {
                    let vouchersNew = [];
                    let filteredVouchers;
                    if (viewVouchers === "available"){
                        filteredVouchers = res.data.vouchers.filter((voucher:any) => { return voucher.activatable || (voucher.timeout && voucher.timeout > 0) || (!voucher.alreadyUsed && !voucher.timeout) });
                    } else {
                        filteredVouchers = res.data.vouchers.filter((voucher:any) => { return !voucher.activatable && !voucher.timeout && voucher.alreadyUsed });
                    }
                    vouchersNew = filteredVouchers.map((voucher: IVoucher) => {
                        return {
                            ...voucher,
                            timeActivated: 0,
                        }
                    });
                    setVouchers(vouchersNew);
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useIonViewWillEnter(() => {
        if (!state.initLoading && state.token) {
            getVouchers();
            setUserOnPage(true);
        }
    }, [state.initLoading, state.token]);

    useIonViewWillLeave(() => {
        if (!state.initLoading && state.token) {
            setUserOnPage(false);
        }
    }, [state.initLoading, state.token]);


    useEffect(() => {
        if (!state.initLoading && state.token) {
            getVouchers();
            setUserOnPage(true);
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, viewVouchers]);

    function voucherAlert(voucher: any, index: number){
        present({
            header: t("activate voucher") + "?",
            message: t("after activating the voucher you have limited time to use it"),
            buttons: [{
                text: t('activate'),
                handler: e => {activateVoucher(e, voucher, index)}
            }, t('cancel')]
        });
    }

    function activateVoucher(e: any, voucher: IVoucherEdit, index: number) {
        Axios.post(`${CONFIG.API_ENDPOINT}vouchers/activate`, {
            voucherId: voucher.id
        }, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                let vouchersNew = [...vouchers];
                voucher.activationText = res.data.activationText;
                voucher.timeout = res.data.timeout;
                vouchersNew[index] = voucher;
                setVouchers(vouchersNew);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    }

    const showVouchersinSlider = () => {
        if (vouchers && vouchers.length > 0) {
            return (
                <IonSlides pager={true} class="voucherSlider"  key={vouchers.map((voucher) => {
                    let slideKey = voucher.id;
                    return slideKey
                }).join('_')}>
                    {vouchers.map((voucher: IVoucherEdit, index) => {
                        return (
                            <IonSlide key={`voucher${voucher.id}`}>
                                <IonCard class={`voucherCard noBorderRadius ion-margin ${!voucher.activatable && !voucher.timeout ? "voucherDisabled" : ""} ${voucher.alreadyUsed && !voucher.activatable && !voucher.timeout ? "voucherUsed" : ""}`}>
                                    <div className="voucherCardTop hideLandscape">
                                        {voucher.alreadyUsed && !voucher.activatable && !voucher.timeout &&
                                        <span className="voucherUsedText">{t("voucher already used")}</span>
                                        }
                                        {voucher.image ?
                                            <Img src={voucher.image}
                                                 loader={<IonSpinner class="ion-margin-top" name="crescent"/>}/>
                                            :
                                            <div className="placeholderImage">
                                                <i className="fas fa-image"/>
                                            </div>
                                        }
                                    </div>
                                    <IonCardHeader class="ion-text-center">
                                        <IonCardTitle>{voucher.title}</IonCardTitle>
                                        <IonCardSubtitle style={{'display': voucher.expDate ? 'block' : 'none'}}
                                                         class="ion-no-margin">{t("available until")} <Moment unix
                                                                                                              format="LL">{voucher.expDate}</Moment> <TimeMoment amPmTime={state.amPmTime} time={voucher.expDate} timeZone={state.me.timeZone} /></IonCardSubtitle>
                                    </IonCardHeader>
                                    {voucher.timeout > 0 ? (
                                        <IonCardContent class="ion-text-center cardContent">
                                            <div className="ion-padding-bottom">
                                                <IonText><p>{voucher.activationText}</p></IonText>
                                            </div>
                                            <VoucherTimer voucherId={voucher.id} voucherTimeout={voucher.timeout}
                                                          voucherBaseTimeout={voucher.baseTimeout} onChange={getVouchers} userOnPage={userOnPage}/>
                                        </IonCardContent>
                                    ) : (
                                        <IonCardContent class="ion-text-center cardContent">
                                            {parse(voucher.content)}
                                            <div>
                                                <IonButton class="ion-margin-top voucherActivate" fill="outline" expand="full"
                                                           onClick={() => voucherAlert(voucher, index)}
                                                           disabled={!voucher.activatable}>{t("activate")}</IonButton>
                                            </div>
                                        </IonCardContent>
                                    )}
                                </IonCard>
                            </IonSlide>
                        )
                    })}
                </IonSlides>
            )
        } else {
            return (<IonCard class="ion-padding cardBackground ion-margin noBorderRadius"><IonText>{vouchersLoading ? t("vouchers are being loaded") : t("no vouchers available")}</IonText></IonCard>);
        }
    };

    function showVouchersInGrid() {
        if (vouchers && vouchers.length > 0) {
            return (
                <IonRow className="ion-padding-horizontal">
                    {vouchers.map((voucher: IVoucherEdit, index) => {
                        return (
                            <IonCol sizeMd="6" sizeLg="6" sizeXl="6" key={`voucher${voucher.id}`}>
                                <IonCard  class={`voucherCard noBorderRadius ion-no-margin ${!voucher.activatable && !voucher.timeout ? "voucherDisabled" : ""} ${voucher.alreadyUsed && !voucher.activatable && !voucher.timeout ? "voucherUsed" : ""}`}>
                                    <div className="voucherCardTop hideLandscape">
                                        {voucher.alreadyUsed && !voucher.activatable && !voucher.timeout &&
                                        <span className="voucherUsedText">{t("voucher already used")}</span>
                                        }
                                        {voucher.image ?
                                            <Img src={voucher.image}
                                                 loader={<IonSpinner class="ion-margin-top" name="crescent"/>}/>
                                            :
                                            <div className="placeholderImage">
                                                <i className="fas fa-image"/>
                                            </div>
                                        }
                                    </div>
                                    <IonCardHeader class="ion-text-center">
                                        <IonCardTitle>{voucher.title}</IonCardTitle>
                                        <IonCardSubtitle style={{'display': voucher.expDate ? 'block' : 'none'}}
                                                         class="ion-no-margin">{t("available until")} <Moment unix
                                                                                                              format="LL">{voucher.expDate}</Moment> <TimeMoment amPmTime={state.amPmTime} time={voucher.expDate} timeZone={state.me.timeZone} /></IonCardSubtitle>
                                    </IonCardHeader>
                                    {voucher.timeout > 0 ? (
                                        <IonCardContent class="ion-text-center cardContent">
                                            <div className="ion-padding-bottom">
                                                <IonText><p>{voucher.activationText}</p></IonText>
                                            </div>
                                            <VoucherTimer voucherId={voucher.id} voucherTimeout={voucher.timeout}
                                                          voucherBaseTimeout={voucher.baseTimeout} onChange={getVouchers} userOnPage={userOnPage}/>
                                        </IonCardContent>
                                    ) : (
                                        <IonCardContent class="ion-text-center cardContent">
                                            {parse(voucher.content)}
                                            <div>
                                                <IonButton class="ion-margin-top voucherActivate" fill="outline" expand="full"
                                                           onClick={() => voucherAlert(voucher, index)}
                                                           disabled={!voucher.activatable}>{t("activate")}</IonButton>
                                            </div>
                                        </IonCardContent>
                                    )}
                                </IonCard>
                            </IonCol>
                        )
                    })}
                </IonRow>
            )
        } else {
            return (<IonCard class="ion-padding cardBackground ion-margin noBorderRadius"><IonText>{vouchersLoading ? t("vouchers are being loaded") : t("no vouchers available")}</IonText></IonCard>);
        }
    }
    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getVouchers().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    window.addEventListener("resize", function(){
        setScreenWidth(window.innerWidth);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('vouchers')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-no-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-padding">
                    <IonSegment onIonChange={e => {setVouchers([]); setViewVouchers(e.detail.value); }} value={viewVouchers}>
                        <IonSegmentButton value="available" class="segmentButton">
                            <IonLabel>{t('available')}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="used" class="segmentButton">
                            <IonLabel>{t('used')}</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonRow>
                {screenWidth < 768 ?
                    showVouchersinSlider()
                    :
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" sizeLg="8" sizeXl="6">
                            {showVouchersInGrid()}
                        </IonCol>
                    </IonRow>
                }
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Vouchers);
