import React, {useEffect, useState} from "react";
import {IonButton, IonContent, IonModal, useIonAlert} from "@ionic/react";
import Datastore from "../helper/Datastore";
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";
import {AppContext} from "../App";
import goToAppStore from "../helper/goToAppStore";

const WelcomeModal: React.FC = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [intro, setIntro] = useState<any>();
    const {state} = React.useContext(AppContext);
    const [present] = useIonAlert();

    useEffect(() => {
        if (state.outdated === true) {
            present({
                header: t("New version available in store"),
                message: t("Would you like go to the App Store and download the latest version of this app to benefit from his new features") + "?",
                buttons: [{
                    text: t('update'),
                    handler: () => {
                        goToAppStore()
                    }
                }, t('cancel')]
            });
        }
        // eslint-disable-next-line
    }, [state.outdated]);

    useEffect(() => {
        if (state.me.tosAccepted && state.outdated === false && state.token && state.me.primaryGroup) {
            Datastore.getShowWelcomeModal(state.me.primaryGroup.id, state.me.id).then((result) => {
                if (state.token && result) {
                    if (state.me.primaryGroup &&
                        state.me.primaryGroup.texts &&
                        state.me.primaryGroup.texts.intro) {
                        setIntro(parse(state.me.primaryGroup.texts.intro));
                        setShowModal(true);
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [state.token, state.me.primaryGroup, state.me.tosAccepted]);

    return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonContent className="ion-padding">
                {intro}
                <IonButton expand="block" onClick={() => {Datastore.setItem(`wms_${state.me.primaryGroup.id}_${state.me.id}`, '1').then(() => { setShowModal(false); })}}>{t('close intro')}</IonButton>
            </IonContent>
        </IonModal>
    );
};

export default React.memo(WelcomeModal);
