import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {
    IonButton,
    IonButtons, IonCard,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {toastController} from "@ionic/core";
import {IMe} from "../models/IMe";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";

interface IJoinGroupProps extends RouteComponentProps<{qrCode: string}> {}

const JoinGroup: React.FC<IJoinGroupProps> = ({history, match}) => {
    const {t, i18n} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const [groupName, setGroupName] = useState('');

    function joinGroup(qrCode: string) {
        Axios.post(`${CONFIG.API_ENDPOINT}me/joinGroup`, {
            'qrCode': qrCode
        }, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) =>  {
            if (res.data.success === true) {
                Axios.get(`${CONFIG.API_ENDPOINT}me`, {
                    headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                }).then((res: any) => {
                    const me: IMe = {
                        id: res.data.id,
                        name: res.data.name,
                        email: res.data.email,
                        groups: res.data.groups,
                        primaryGroup: res.data.primaryGroup,
                        timeZone: res.data.timeZone,
                        useGroupLayout: res.data.useGroupLayout,
                        tosAccepted: res.data.tosAccepted,
                        hideFromGlobalRanking: res.data.hideFromGlobalRanking,
                        messageBoxUnread: res.data.messageBoxUnread,
                    };
                    dispatch({
                        type: "ME",
                        payload: me
                    });
                    history.push('/profile');
                });
                toastController.create({
                    message: t('group joined'), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    }

    useEffect(() => {
        if (!state.initLoading && state.token) {
            Axios.get(`${CONFIG.API_ENDPOINT}me/groupInfo/${match.params.qrCode}`, {
                headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
            }).then((res: any) => {
                setGroupName(res.data.name);
            });
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle text-center>{t('join group')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center fixed`}}>
                <IonCard class="ion-padding cardBackground ion-no-margin noBorderRadius aboutContent">
                    {state.token ?
                        <>
                            {match.params.qrCode ?
                                <>
                                    <h1>{t('join group')} {groupName}</h1>
                                    <p>{t("do you want to join this group") + "?"}</p>
                                    <IonButton expand="full" class="ion-margin-vertical" id="joinGroupButton" onClick={() => joinGroup(match.params.qrCode)}>{t('join')}</IonButton>
                                </>
                                :
                                <>
                                    <h1>{t('no qrCode')} {groupName}</h1>
                                </>
                            }
                        </>
                        :
                        <>
                            <h1>{t('not logged in')}</h1>
                            <p>{t('please login so we can add you to the group:')} {groupName}</p>
                            <IonButton expand="full" class="ion-margin-vertical" onClick={() => history.push('/login')}>{t('login')}</IonButton>
                            <IonButton expand="full" class="ion-margin-vertical" onClick={() => history.push(`/register/${match.params.qrCode}`)}>{t('register')}</IonButton>
                        </>
                    }
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(JoinGroup);
