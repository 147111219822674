import React, {useContext, useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonModal,
    IonPage,
    IonList,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption, IonText,
    IonTitle,
    IonToolbar, useIonAlert,
} from '@ionic/react';
import {useTranslation} from "react-i18next";
import AvaiableLanguages from "../helper/AvailableLanguages";
import {CONFIG} from "../constants";
import {IUserGroup} from "../models/IUserGroup";
import {ILanguage} from "../models/ILanguage";
import {AppContext} from "../App";
import {IMe} from "../models/IMe";
import {Device, DeviceInfo} from "@capacitor/device";
import {App} from '@capacitor/app';
import {RefresherEventDetail, toastController} from "@ionic/core/components";
import {BarcodeScanner} from "@ionic-native/barcode-scanner";
import Datastore from "../helper/Datastore";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";
import momentTZ from 'moment-timezone';
import Moment from "react-moment";
import {Fetch} from "../Fetch"
import '../theme/profile.css';

const Profile: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [name, setName] = useState<any>();
    const [password, setPassword] = useState<any>();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [selectedTimezone, setSelectedTimezone] = useState();
    const [amPmTime, setAmPmTime] = useState(false);
    const [primaryGroup, setPrimaryGroup] = useState();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [info, setInfo] = useState<any>();
    const [useGroupLayout, setUseGroupLayout] = useState(true);
    const [hideFromGlobalRanking, setHideFromGlobalRanking] = useState(false);
    const {state, dispatch} = useContext(AppContext);
    const [timeZonesList] = useState(momentTZ.tz.names());
    const [present] = useIonAlert();

    function showChangeDateSourceAlert(value: string) {
        present({
            header: t("changing data source"),
            message: t("do you want to change the data source") + "?",
            buttons: [{
                text: t('change'),
                handler: (e) => {
                    setShowInfoModal(false);

                    dispatch({
                        type: "ENV",
                        payload: value
                    });

                    history.push('/logout');
                }
            }, t('cancel')]
        });
    }

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        if (i18n.language) {
            setSelectedLanguage(i18n.language);
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    const getMe = async () => {
        await Fetch.call({
            method: "get",
            url:    "me",
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then(res => {
            const me: IMe = {
                id: res.data.id,
                name: res.data.name,
                email: res.data.email,
                groups: res.data.groups,
                primaryGroup: res.data.primaryGroup,
                timeZone: res.data.timeZone,
                useGroupLayout: res.data.useGroupLayout,
                tosAccepted: res.data.tosAccepted,
                hideFromGlobalRanking: res.data.hideFromGlobalRanking,
                messageBoxUnread: res.data.messageBoxUnread,
            };
            dispatch({
                type: "ME",
                payload: me
            });
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getMe();
            setAmPmTime(state.amPmTime);
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    useEffect(() => {
        setName(state.me.name);
        setUseGroupLayout(state.me.useGroupLayout);
        setPrimaryGroup(state.me.primaryGroup ? state.me.primaryGroup.id : null);
        setSelectedTimezone(state.me.timeZone ? state.me.timeZone : Moment.globalTimezone);
        setHideFromGlobalRanking(state.me.hideFromGlobalRanking);
        // eslint-disable-next-line
    }, [state.me.name, state.me.primaryGroup, state.me.timeZone]);

    useEffect(() => {
        Device.getInfo().then((deviceResult: DeviceInfo) => {
            if (deviceResult.platform !== 'web') {
                App.getInfo().then((appInfoResult) => setInfo({...deviceResult, ...appInfoResult}));
            } else {
                setInfo(deviceResult);
            }
        });
        // eslint-disable-next-line
    }, []);

    function updateLanguage(e: any) {
        setSelectedLanguage(e.target.value);
        i18n.changeLanguage(e.target.value);
    }

    function updateTimezone(e: any) {
        setSelectedTimezone(e.target.value);
    }

    function updateTime(e: any) {
        setAmPmTime(e.target.value);
        dispatch({
            type: "TIME",
            payload: e.target.value
        });
    }

    const leaveGroup = (e: any, groupId: any) => {
        Fetch.call({
            method: "post",
            url:    "me/leaveGroup",
            payload: {
                'group': groupId
            },
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res) =>  {
            if (res.data.success === true) {
                getMe();
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    const profileSubmit = async () => {
        await Fetch.call({
            method:     "post",
            url:        "me",
            payload:    {
                name: name,
                password: password,
                primaryGroup: primaryGroup,
                timeZone: selectedTimezone,
                useGroupLayout: useGroupLayout,
                hideFromGlobalRanking: hideFromGlobalRanking,
            },
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res) => {
            if (res.data.success === true) {
                setPassword(null);
                getMe().then(() => {
                    toastController.create({
                        message: t('profile saved'), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    function leaveGroupAlert(groupId:any, groupName:string, event:any){
        present({
            header: t("leave") + " " + groupName,
            message: t("are you sure you want to leave this group? when leaving a group you will lose all points gained in that group."),
            buttons: [{
                text:t('leave'),
                handler: () => { leaveGroup(event, groupId) }
            }, t('cancel')]
        });
    }

    function joinGroup(qrCode: string) {
        Fetch.call({
            method: "post",
            url:    "me/joinGroup",
            payload:{
                'qrCode': qrCode
            },
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res) => {
            if (res.data.success === true) {
                Fetch.call({
                    method: "get",
                    url:    "me",
                    headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                }).then(res => {
                    const me: IMe = {
                        id: res.data.id,
                        name: res.data.name,
                        email: res.data.email,
                        groups: res.data.groups,
                        primaryGroup: res.data.primaryGroup,
                        timeZone: res.data.timeZone,
                        useGroupLayout: res.data.useGroupLayout,
                        tosAccepted: res.data.tosAccepted,
                        hideFromGlobalRanking: res.data.hideFromGlobalRanking,
                        messageBoxUnread: res.data.messageBoxUnread,
                    };
                    dispatch({
                        type: "ME",
                        payload: me
                    });
                });
                toastController.create({
                    message: t('group joined'), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: true,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    }

    function changeGroupAlert(groupId: any, e: any){
        present({
            header: t("change primary group") + " ",
            message: t("are you sure you want to change the primary group") + "?",
            buttons: [{
                text:t('change'),
                handler: () => {
                    Fetch.call({
                        method: "post",
                        url:    "me",
                        payload: {
                            primaryGroup: groupId
                        },
                        headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                    }).then((res) => {
                        if (res.data.success === true) {
                            getMe().then(() => {
                                toastController.create({
                                    message: t('profile saved'), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                                }).then((result: HTMLIonToastElement) => {
                                    return result.present();
                                });
                            });
                        } else {
                            res.data.errors.forEach((item: any) => {
                                toastController.create({
                                    message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                                }).then((result: HTMLIonToastElement) => {
                                    return result.present();
                                });
                            });
                        }
                    });
                }
            },
            {
                text:t('cancel'),
            }]
        });
    }

    function scanBarCode() {
        BarcodeScanner.scan().then((barcodeData) => {
            if (barcodeData.format === 'QR_CODE' && barcodeData.text !== '') {
                const groupQrCode = barcodeData.text.replace(`${CONFIG.APP_URL}/joinGroup/`, '');

                Fetch.call({
                    method: "get",
                    url:    encodeURI(`me/groupInfo/${groupQrCode}`),
                    headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                }).then((res) => {
                    present({
                        header: t("joining group") + " " + res.data.name + "?",
                        message: t("do you want to join this group") + "?",
                        buttons: [{
                            text: t('join'),
                            handler: () => { joinGroup(groupQrCode) }
                        }, t('cancel')]
                    });
                });
            }
        }).catch(errorMessage => {
            alert(errorMessage);
        });
    }

    function clearWelcomeModalSeen() {
        if (state.me.primaryGroup) {
            Datastore.removeItem(`wms_${state.me.primaryGroup.id}_${state.me.id}`).then(() => {
                toastController.create({
                    message: t('storage cleared'), buttons: [{text: t('close'), role: 'cancel'}], cssClass: "toastMessage", duration: 3000, position: 'top', translucent: false,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            });
        }
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getMe().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage className="settingsPage">
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('profile')}</IonTitle>
                    <IonButtons slot="end" >
                        <IonButton onClick={() => setShowInfoModal(true)}><i className="fas fa-info" /></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                            <IonRefresherContent refreshingSpinner="crescent" />
                        </IonRefresher>
                        <form onSubmit={(e) => {e.preventDefault(); profileSubmit()}}>
                            <div className="settingsWrapper cardLayout ion-padding">
                                <IonRow>
                                    <IonCol sizeXs="12">
                                        <IonLabel position="stacked">{t('language')}</IonLabel>
                                        <IonSelect okText={t('ok')} cancelText={t('cancel')} onIonChange={e => updateLanguage(e)} value={selectedLanguage} class="defaultInput">
                                            {AvaiableLanguages.map((language: ILanguage) => {
                                                return (
                                                    <IonSelectOption key={language.id} value={language.code}>
                                                        {t(`languages.${language.code}`)}
                                                    </IonSelectOption>
                                                );
                                            })}
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel position="stacked">{t('timezone')}</IonLabel>
                                        <IonSelect okText={t('ok')} cancelText={t('cancel')} onIonChange={e => updateTimezone(e)} value={selectedTimezone} class="defaultInput">
                                            {timeZonesList.map((timezone: any) => {
                                                return (
                                                    <IonSelectOption key={timezone} value={timezone}>
                                                        {timezone}
                                                    </IonSelectOption>
                                                );
                                            })}
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel position="stacked">{t('Time format')}</IonLabel>
                                        <IonSelect okText={t('ok')} cancelText={t('cancel')} onIonChange={e => updateTime(e)} value={amPmTime} class="defaultInput">
                                            <IonSelectOption value={'false'}>
                                                {t('24 hours')}
                                            </IonSelectOption>
                                            <IonSelectOption value={'true'}>
                                                {t('12 hours')}
                                            </IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel position="stacked">{t('name')}</IonLabel>
                                        <IonInput type="text" name="name" inputmode="text" class="defaultInput" value={name} onIonChange={e => {setName(e.detail.value!)}} required />
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel position="stacked">{t('email address')}</IonLabel>
                                        <IonInput type="text" name="email" inputmode="email" class="defaultInput" value={state.me.email} disabled />
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="12">
                                        <IonLabel position="stacked">{t('password')}</IonLabel>
                                        <IonRow><IonText class="help-text">{t("leave blank if you dont want to change")}</IonText ></IonRow>
                                        <IonInput type="password" name="password" inputmode="text" class="defaultInput" value={password} onIonChange={e => {setPassword(e.detail.value!)}} />
                                    </IonCol>
                                    {state.me.primaryGroup &&
                                    <IonCol sizeXs="12" class="ion-margin-bottom">
                                        <IonLabel position="stacked">{t('primary group')}</IonLabel>
                                        <IonList class="defaultRadio">
                                            {state.me.groups.map((group: IUserGroup) => {
                                                return (
                                                    <IonRow key={group.id} class="ion-align-items-center">
                                                        <IonCol class="ion-no-padding">
                                                            <IonItem lines="full" onClick={(e) => {changeGroupAlert(group.id, e)}}>
                                                                <IonLabel class="removeNoWrap">{group.name}</IonLabel>
                                                                {primaryGroup === group.id && <IonText color="primary"><i className="fas fa-check"/></IonText>}
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size="auto" class="ion-no-padding" style={{ paddingLeft : "10px"}}>
                                                            <IonButton class="ion-no-padding" expand="full"
                                                                       style={{fontSize: "20px", "--ripple-color": "#fff"}}
                                                                       fill="clear" onClick={(e) => {
                                                                leaveGroupAlert(group.id, group.name, e)
                                                            }}><i className="far fa-sign-out"/></IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                )
                                            })}
                                        </IonList>
                                    </IonCol>
                                    }
                                    <IonCol sizeXs="12" class="ion-margin-bottom layoutSettings">
                                        <IonLabel position="stacked">{t('layout settings')}</IonLabel>
                                        <IonList className="defaultRadio">
                                            <IonRow class="ion-align-items-center">
                                                <IonCol class="ion-no-padding">
                                                    <IonItem lines="full" onClick={() => {setUseGroupLayout(true)}} >
                                                        <IonLabel class="removeNoWrap">{t("use group layout")}</IonLabel>
                                                        {useGroupLayout && <IonText color="primary"><i className="fas fa-check"/></IonText>}
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow class="ion-align-items-center">
                                                <IonCol class="ion-no-padding">
                                                    <IonItem lines="full" onClick={() => {setUseGroupLayout(false)}} >
                                                        <IonLabel class="removeNoWrap">{t("use default layout")}</IonLabel>
                                                        {!useGroupLayout && <IonText color="primary"><i className="fas fa-check"/></IonText>}
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonList>
                                    </IonCol>
                                    <IonCol sizeXs="12" class="ion-margin-bottom">
                                        <IonLabel position="stacked">{t('position in global ranking')}</IonLabel>
                                        <IonList className="defaultRadio">
                                            <IonRow class="ion-align-items-center">
                                                <IonCol class="ion-no-padding">
                                                    <IonItem lines="full" onClick={() => {setHideFromGlobalRanking(true)}} >
                                                        <IonLabel class="removeNoWrap">{t("hide")}</IonLabel>
                                                        {hideFromGlobalRanking && <IonText color="primary"><i className="fas fa-check"/></IonText>}
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow class="ion-align-items-center">
                                                <IonCol class="ion-no-padding">
                                                    <IonItem lines="full" onClick={() => {setHideFromGlobalRanking(false)}} >
                                                        <IonLabel class="removeNoWrap">{t("show")}</IonLabel>
                                                        {!hideFromGlobalRanking && <IonText color="primary"><i className="fas fa-check"/></IonText>}
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonList>
                                    </IonCol>
                                    <IonCol className="ion-text-center ion-margin-bottom" size="12">
                                        <IonButton fill="clear" className="linkButton ion-no-padding" onClick={() => {dispatch({type: "agreeOrRead", payload: "read"}); dispatch({type: "showTermsModal", payload: true})}}>{t("read terms and conditions")}</IonButton>
                                    </IonCol>
                                    {info && info.platform !== 'web' &&
                                    <IonCol sizeXs="12" sizeMd="auto">
                                        <IonButton fill="outline" class="profileButton" expand="full" onClick={() => scanBarCode()}>{t('scan code to join group')}</IonButton>
                                    </IonCol>
                                    }
                                    <IonCol size="12" class="settingsSubmit">
                                        <IonButton type="submit" expand="full">{t('save')}</IonButton>
                                    </IonCol>
                                </IonRow>
                            </div>
                        </form>
                        <IonModal isOpen={showInfoModal} onDidDismiss={() => setShowInfoModal(false)}>
                            <IonContent scrollEvents={true}>
                                <div className="ion-padding">
                                    <IonText><h4 className="ion-no-margin">{t("technical information")}</h4></IonText>
                                    <pre>{JSON.stringify(info, null, 2)}</pre>
                                </div>
                                {state.me.email && state.me.email.endsWith('@cmdc.eu') &&
                                <div className="ion-padding">
                                    <IonCol>
                                        <IonLabel position="stacked">{t('choose data source')}</IonLabel>
                                        <IonSelect okText={t('ok')} cancelText={t('cancel')} onIonChange={(e) => showChangeDateSourceAlert(e.detail.value)} value={state.env} class="defaultInput">
                                            <IonSelectOption key={1} value='production'>{t('production')}</IonSelectOption>
                                            <IonSelectOption key={2} value='test'>{t('test')}</IonSelectOption>
                                        </IonSelect>
                                    </IonCol>
                                </div>
                                }
                                <div>
                                    <IonButton expand="full" fill="outline" class="ion-margin profileButton" onClick={() => clearWelcomeModalSeen()}>{t('clear welcome modal seen')}</IonButton>
                                    <IonButton expand="full" class="ion-margin" onClick={() => setShowInfoModal(false)}>{t('close')}</IonButton>
                                </div>
                            </IonContent>
                        </IonModal>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Profile);
