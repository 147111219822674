import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButtons, IonCol,
    IonContent,
    IonHeader, IonItem, IonLabel, IonList, IonListHeader,
    IonMenuButton,
    IonPage, IonRefresher, IonRefresherContent, IonRow, IonSpinner, IonText, IonThumbnail,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {AppContext} from "../App";
import Axios from "axios";
import {CONFIG} from "../constants";
import {ICompetition} from "../models/ICompetition";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {Img} from "react-image";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";

const TeamsOverview: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [competition, setCompetition] = useState<ICompetition>({id:1,name:'',state:1,startDate:"",endDate:"",logo:"",teams:[]});

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getTeamOverview = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}competitions/${CONFIG.COMPETITION}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setCompetition(res.data.competition);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getTeamOverview();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    function getRowClass(index: any) {
        let rowClass;
        if(isEven(index)){
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getTeamOverview().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('team_plural')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        <IonList class="ion-no-padding ion-padding-horizontal ion-padding-bottom" style={{background: "transparent"}}>
                            {competition.name &&
                            <IonListHeader style={{color: "var(--ion-color-secondary)", fontSize: "16px"}} class="ion-no-padding">
                                <strong>{competition.name}</strong>
                            </IonListHeader>
                            }
                            <div className="cardLayout">
                                {competition.teams && competition.teams.length > 0 ? (
                                    competition.teams.sort(function (a: any, b: any) {
                                        if (a.name < b.name) {
                                            return -1;
                                        }
                                        if (a.name > b.name) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((team: any, index: any) => {
                                        return (
                                            <IonItem button={true} routerDirection="forward" routerLink={`/teams/${team.id}`}
                                                     lines="none" class={getRowClass(index)} key={team.id}>
                                                <IonRow class="ion-align-items-center">
                                                    <IonCol size='auto' class="ion-padding-end">
                                                        <IonThumbnail style={{"--size": "32px"}}>
                                                            <Img src={`${team.logo}`}
                                                                 loader={<IonSpinner name="crescent"/>}/>
                                                        </IonThumbnail>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonLabel>{team.name}</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonItem>
                                        )
                                    })
                                ) : (
                                    <div className="ion-padding ion-margin-top" style={{backgroundColor : "#fff", color : "#737373", fontSize : "14px"}}><IonText>{t("no information available")}</IonText></div>
                                )}
                            </div>
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(TeamsOverview);
