import {IPrimaryGroup} from "../models/IPrimaryGroup";

export default function getPrimaryGroupAsset(primaryGroup: IPrimaryGroup, type: 'background' | 'logo', useGroupLayout: boolean) {
    if (useGroupLayout && primaryGroup && primaryGroup.files &&
        ((type === 'background' && primaryGroup.files.background) ||
        (type === 'logo' && primaryGroup.files.logo))) {
        return `${primaryGroup.files[type]}`;
    } else {
        if (type === 'background') {
            return process.env.PUBLIC_URL +'/assets/images/backgroundSettingslight.jpg';
        } else {
            return process.env.PUBLIC_URL +'/assets/images/logoYFA.png'
        }
    }
}
