import React, {useContext, useEffect, useState} from "react";
import {
    IonPage,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonContent,
    IonCard,
    IonCardContent,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonText,
    IonRefresherContent,
    IonRefresher,
    IonBadge, useIonViewWillLeave, IonCol,
} from "@ionic/react";
import {useTranslation} from 'react-i18next';
import {Fetch} from "../Fetch"
import {AppContext} from "../App";
import {IMatch} from "../models/IMatch";
import 'moment-timezone';
import moment from "moment";
import {Link, RouteComponentProps} from "react-router-dom";
import {RefresherEventDetail, toastController} from "@ionic/core";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import TimeMoment from "../component/TimeMoment";

const MatchesOverview: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [matches, setMatches] = useState<IMatch []>([]);
    const [matchState, setmatchState] = useState<any>('1');
    const {state} = useContext(AppContext);
    const [liveMatchesCount, setLiveMatchesCount] = useState(0);
    const [matchesLoading, setMatchesLoading] = useState(false);
    let matchesInterval: any = false;

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    // useIonViewWillEnter(() => {
    //     console.log('matches interval is: ', matchesInterval);
    //     // prevent on first run -> useEffect() when coming back this page below should pass
    //     if (!matchesInterval && !state.isLoading && state.token) {
    //         setMatchesLoading(true);
    //         getMatches().then(() => {
    //             matchesInterval = setInterval(() => {
    //                 getMatches();
    //             }, 15000);
    //         });
    //     }
    // }, [matchState, matchesInterval]);

    useIonViewWillLeave(() => {
        console.log('does leave', matchesInterval);
        clearInterval(matchesInterval);
        matchesInterval = false;
    }, [matchState]);

    const getMatches = async () => {
        Fetch.call({
            method:     "get",
            url:        `matches/list?state=${matchState}`,
            headers:    {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then(res => {
            setMatchesLoading(false);
            if (res.data.success === true) {
                setMatches(res.data.matches);
                setLiveMatchesCount(res.data.countByState[2]);
                return res.data.matches;
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token && !matchesInterval) {
            setMatchesLoading(true);
            getMatches().then(() => {
                // eslint-disable-next-line
                matchesInterval = setInterval(() => {
                    getMatches();
                }, 15000);
            });
        }
        return () => {clearInterval(matchesInterval); matchesInterval = false};
        // eslint-disable-next-line
    }, [matchState, i18n.language, state.initLoading, state.token]);

    function getDateHeader(matchDateKey: any) {
        let dateString = moment(matchDateKey, "DD-MM-YYYY").locale(i18n.language).format('LL');
        if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment(), 'day')) {
            dateString = t('today');
        } else if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment().add(1, 'days'), 'day')) {
            dateString = t('tomorrow');
        } else if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment().subtract(1, 'days'), 'day')) {
            dateString = t('yesterday');
        }
        return (
            <IonCol size="12">
                <IonText color="secondary" class="matchesOverviewDateHeader"><strong>{dateString}</strong></IonText>
            </IonCol>
        )
    }
    function getMatchState (detailedState: any) {
        let matchState;
        if (detailedState === "HT") {
            matchState = t("halftime");
        } else if (detailedState === "ET") {
            matchState = t("extra time");
        } else if (detailedState === "BT") {
            matchState = t("break time");
        } else if (detailedState === "P") {
            matchState = t("penalty in progress");
        } else {
            matchState = "";
        }
        return matchState;
    }

    const showMatches = () => {
        if (matches && matches.length > 0) {
            let matchesArray = matches.reduce((groups :any, game: any) => {
                const date = moment.unix(game.startTime).format('DD-MM-YYYY');
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, []);
            let matchesOrder;
            if (matchState === '3') {
                matchesOrder = Object.keys((matchesArray)).reverse();
            } else {
                matchesOrder = Object.keys((matchesArray));
            }
            return (matchesOrder.map((matchDateKey: any) =>
                <IonRow key={matchDateKey} className="ion-margin-top">
                    {matchState !== "2" &&
                        getDateHeader(matchDateKey)
                    }
                    {matchState === '3' ?
                        matchesArray[matchDateKey].reverse().map((match: any) => getMatch(match))
                        : matchesArray[matchDateKey].map((match: any) => getMatch(match))
                    }
                </IonRow>
            ));
        } else {
            return <IonCard className="cardBackground noBorderRadius ion-padding-horizontal ion-no-margin ion-margin-top"><IonText><p>{matchesLoading ? t('matches are being loaded') : t('no matches found')}</p></IonText></IonCard>;
        }
    };

    function getMatch(match : any) {
        return (
            <IonCol size="12" sizeLg="6" key={match.id}>
                <IonCard class="cardBackground ion-no-margin noBorderRadius" button={true} >
                    <IonCardContent class="ion-no-padding">
                        <Link to={"/matches/"+match.id} className="matchCardLink">
                            {match.state === 1 ? (
                                <div>
                                    <div style={{textAlign:"center", fontSize : "16px", fontWeight : "bold", margin: "15px", color: "var(--ion-color-primary, #1d3d51)"}}>
                                        <TimeMoment amPmTime={state.amPmTime} time={match.startTime} timeZone={state.me.timeZone} />
                                    </div>
                                    <div style={{ display : "flex", width:"100%", paddingLeft:"15px",paddingRight: "15px" }}>
                                        {match.teams.map((team :any, i : number) =>
                                            <div key={team.id} style={{flexGrow:1, textAlign:"center", margin:"15px", marginTop:"0px", flexBasis:0}}>
                                                <img className="upcomingMatchTeamImage" alt={team.name} src={team.logo} loading={"lazy"} />
                                                <p>{team.name}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div style={{ paddingRight : "5px", width: "100%" }}>
                                    {match.state === 2 &&
                                    <div style={{ display : "flex", width: "100%", padding: "15px", paddingBottom: "0px", color: "var(--ion-color-primary, #1d3d51)" }}>
                                        <div style={{fontWeight:"bold", flexGrow: 1}}>
                                            <TimeMoment amPmTime={state.amPmTime} time={match.startTime} timeZone={state.me.timeZone} />
                                        </div>
                                        <div style={{textAlign:"right", fontWeight:"bold"}}>
                                            <p style={{ display: "inline", marginRight : "5px"}}>
                                                {getMatchState(match.detailedState)}
                                            </p>
                                            <strong>{match.elapsed / 60}'</strong>
                                        </div>
                                    </div>}
                                    {match.teams.map((team :any, i : number) =>
                                        <div key={team.id} style={{ display : "flex", flexDirection: "row", width:"100%", marginTop: (i>0?"-20px":0) }}>
                                            <div style={{width: "18%", maxWidth:"180px", padding: "15px"}}>
                                                <img alt={team.name} src={team.logo} loading={"lazy"} style={{width:"100%"}} />
                                            </div>
                                            <div style={{fontSize: "14px", alignSelf:"center", flexGrow: 1, paddingLeft:"5px"}}>
                                                {team.name}
                                            </div>
                                            <div style={{fontSize: "14px", alignSelf:"center", textAlign:"right", paddingRight: "15px"}}>
                                                {team.currentResult}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Link>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        )
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getMatches().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('matches')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`, "--padding-bottom" : "0"}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow>
                    <IonSegment onIonChange={e => {setMatches([]); setmatchState(e.detail.value)}} value={matchState}>
                        <IonSegmentButton value="2" class="segmentButton">
                            <IonLabel class="ion-align-items-center" style={{ display : "flex" }}>{t('live')}{liveMatchesCount > 0 && <IonBadge class="liveBadge" color="primary">{liveMatchesCount}</IonBadge>}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="1" class="segmentButton">
                            <IonLabel>{t('future')}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="3" class="segmentButton">
                            <IonLabel>{t('completed')}</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="12" sizeXl="8">
                        {showMatches()}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(MatchesOverview);

