import {createContext} from "react";
import Home from "../pages/Home";
import NewsOverview from "../pages/NewsOverview";
import NewsItem from "../pages/NewsItem";
import MatchesOverview from "../pages/MatchesOverview";
import About from "../pages/About";
import Profile from "../pages/Profile";
import Register from "../pages/Register";
import Login from "../pages/Login";
import PasswordReset from "../pages/PasswordReset";
import Logout from "../pages/Logout";
import MatchesItem from "../pages/MatchesItem";
import {IPage} from "../models/IPage";
import Rankings from "../pages/Rankings";
import Vouchers from "../pages/Vouchers";
import TeamsOverview from "../pages/TeamsOverview";
import TeamsItem from "../pages/TeamsItem";
import Statistics from "../pages/Statistics";
import Player from "../pages/Player";
import Coach from "../pages/Coach";
import GroupStage from "../pages/GroupStage";
import JoinGroup from "../pages/JoinGroup";
import RankingsItem from "../pages/RankingsItem";
import Support from "../pages/Support";
import Predictions from "../pages/Predictions";
import KnockOut from "../pages/KnockOut";
import ChangePassword from "../pages/ChangePassword";
import WaitForEmail from "../pages/WaitForEmail";
import Groups from "../pages/Groups";
import Inbox from "../pages/Inbox";
import InboxItem from "../pages/InboxItem";

const AppPages = createContext<IPage[]>([
    {
        title: 'home',
        path: '/',
        icon: 'fas fa-home',
        component: Home,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'inbox',
        path: '/inbox',
        icon: 'fas fa-inbox',
        component: Inbox,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'inbox',
        path: '/inbox/:id',
        icon: '',
        component: InboxItem,
        menu: false,
        auth: true,
        parent: null,
    },
    {
        title: 'news',
        path: '/news',
        icon: 'fas fa-newspaper',
        component: NewsOverview,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'news item',
        path: '/news/:id',
        icon: '',
        component: NewsItem,
        menu: false,
        auth: true,
        parent: null,
    },
    {
        title: 'tournament',
        path: '',
        icon: 'fas fa-trophy',
        component: null,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'matches',
        path: '/matches',
        icon: 'fas fa-futbol',
        component: MatchesOverview,
        menu: true,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'matches item',
        path: '/matches/:id',
        icon: '',
        component: MatchesItem,
        menu: false,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'teams',
        path: '/teams',
        icon: 'fas fa-users',
        component: TeamsOverview,
        menu: true,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'teams item',
        path: '/teams/:id',
        icon: '',
        component: TeamsItem,
        menu: false,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'group stage',
        path: '/group-stage',
        icon: 'fas fa-users-class',
        component: GroupStage,
        menu: true,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'knock out phase',
        path: '/knock-out',
        icon: 'fas fa-boxing-glove',
        component: KnockOut,
        menu: true,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'player',
        path: '/teams/:teamId/player/:playerId',
        icon: '',
        component: Player,
        menu: false,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'coach',
        path: '/teams/:teamId/coach/:coachId',
        icon: '',
        component: Coach,
        menu: false,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'statistics',
        path: '/statistics',
        icon: 'fas fa-chart-pie-alt',
        component: Statistics,
        menu: true,
        auth: true,
        parent: 'tournament',
    },
    {
        title: 'game',
        path: '',
        icon: 'fas fa-gamepad-alt',
        component: null,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'predictions',
        path: '/predictions',
        icon: 'fas fa-plus-circle',
        component: Predictions,
        menu: true,
        auth: true,
        parent: 'game',
    },
    {
        title: 'rankings',
        path: '/rankings',
        icon: 'fas fa-dice',
        component: Rankings,
        menu: true,
        auth: true,
        parent: 'game',
    },
    {
        title: 'rankings',
        path: '/rankings/:groupId/:userId',
        icon: '',
        component: RankingsItem,
        menu: false,
        auth: true,
        parent: 'game',
    },
    {
        title: 'groups',
        path: '/groups',
        icon: 'fas fa-user-friends',
        component: Groups,
        menu: true,
        auth: false,
        parent: 'game',
    },
    {
        title: 'about',
        path: '/about',
        icon: 'fas fa-info-circle',
        component: About,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'vouchers',
        path: '/vouchers',
        icon: 'far fa-percentage',
        component: Vouchers,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'profile',
        path: '/profile',
        icon: 'fas fa-cogs',
        component: Profile,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'register',
        path: '/register/:qrCode?',
        icon: '',
        component: Register,
        menu: true,
        auth: false,
        parent: null,
    },
    {
        title: 'login',
        path: '/login',
        icon: 'fas fa-sign-in',
        component: Login,
        menu: true,
        auth: false,
        parent: null,
    },
    {
        title: 'password reset',
        path: '/password-reset',
        icon: '',
        component: PasswordReset,
        menu: false,
        auth: false,
        parent: null,
    },
    {
        title: 'logout',
        path: '/logout',
        icon: 'fas fa-sign-out',
        component: Logout,
        menu: true,
        auth: true,
        parent: null,
    },
    {
        title: 'join group',
        path: '/joinGroup/:qrCode',
        icon: '',
        component: JoinGroup,
        menu: false,
        auth: false,
        parent: null,
    },
    {
        title: 'support',
        path: '/support',
        icon: 'fas fa-question-circle',
        component: Support,
        menu: true,
        auth: false,
        parent: null,
    },
    {
        title: 'change password',
        path: '/changePassword/:recoveryKey/:code',
        icon: '',
        component: ChangePassword,
        menu: false,
        auth: false,
        parent: null,
    },
    {
        title: 'wait for email',
        path: '/waitForEmail/:recoveryKey',
        icon: '',
        component: WaitForEmail,
        menu: false,
        auth: false,
        parent: null,
    }
]);

export const menuItems = (page: IPage) => {
    return page.menu && page.parent === null;
};

export default AppPages;
