import React, {useContext, useEffect, useState} from "react";
import {
    IonButton,
    IonButtons, IonCard, IonCol,
    IonContent,
    IonHeader, IonLabel,
    IonMenuButton,
    IonPage, IonRow, IonText, IonTextarea,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {useTranslation} from "react-i18next";
import {AppContext} from "../App";
import {Device, DeviceInfo} from "@capacitor/device";
import Axios from "axios";
import {CONFIG} from "../constants";
import {toastController} from "@ionic/core";

const Support: React.FC = () => {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [message, setMessage] = useState<any>();
    const [info, setInfo] = useState<DeviceInfo>();

    useEffect(() => {
        Device.getInfo().then((result: DeviceInfo) => setInfo(result));
        // eslint-disable-next-line
    }, []);

    function sendForm(e: any) {
        e.preventDefault();

        Axios.post(`${CONFIG.API_ENDPOINT}support/sendMessage`, {body: `${message} <br><br><pre>${JSON.stringify(info, null, 2)}</pre>`}, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success) {
                setMessage(null);
                toastController.create({
                    message: t('message sent'), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                }).then((result: HTMLIonToastElement) => {
                    return result.present();
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`),
                        cssClass: "toastMessage",
                        buttons: [{
                            text: t('close'),
                            role: 'cancel'
                        }],
                        duration: 3000,
                        position: 'top',
                        translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle text-center>{t('support')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        <IonCard class="ion-padding cardBackground ion-no-margin noBorderRadius aboutContent">
                            <div className="ion-padding-bottom" >
                                <h4>{t("support_app_explanation_title")}</h4>
                                <IonText>{t("support_app_explanation")}</IonText>
                            </div>
                            <form onSubmit={e => sendForm(e)}>
                                <IonLabel>{t('message')}</IonLabel>
                                <IonTextarea value={message} placeholder={t('enter message here')} onIonChange={e => {setMessage(e.detail.value)}} />
                                <IonButton color="primary" type="submit">{t('send')}</IonButton>
                            </form>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Support);
