import React, {useEffect} from 'react';
import {AppContext} from "../App";
import {RouteComponentProps} from "react-router-dom";

const Logout: React.FC<RouteComponentProps> = ({history}) => {
    const {state, dispatch} = React.useContext(AppContext);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        if (state.token && window.location.pathname === '/logout') {
            setTimeout(() => {
                dispatch({
                    type: "LOGOUT"
                });
            }, 100);

            return history.push('/login');
        }
    });

    return null;
};

export default React.memo(Logout);
