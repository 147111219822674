import React, {useContext, useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonHeader, IonImg,
    IonItem, IonList,
    IonPage, IonRefresher, IonRefresherContent,
    IonRow, IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {CONFIG} from "../constants";
import {IPlayer} from "../models/IPlayer";
import {ITeam} from "../models/ITeam";
import {RefresherEventDetail, toastController} from "@ionic/core";
import Moment from "react-moment";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";

interface PlayerProp extends RouteComponentProps <{teamId: string, playerId: string}> {}

const Player: React.FC<PlayerProp> = ({history, match}) =>  {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [player, setPlayer] = useState<IPlayer>({
        birthCountry: [],
        birthDate: "",
        birthPlace: "",
        fullName: "",
        heightInCm: 0,
        id: 0,
        injured: false,
        nationality: "",
        number: "",
        position: "",
        statistics: [],
        weightInKg: 0
    });
    const [team, setTeam] = useState<ITeam>({
        id: 0,
        name: "",
        code: "",
        logo: "",
    });

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getPlayer = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}competitions/${CONFIG.COMPETITION}/teams/${match.params.teamId}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setPlayer(getPlayerById(parseInt(match.params.playerId), res.data.team.players));
                setTeam(res.data.team)
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getPlayer();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, match.params.teamId, match.params.playerId]);

    useEffect(() => {
       showPlayer();
       // eslint-disable-next-line
    }, [player, team]);

    const showPlayer = () => {
        return (
            <>
                <IonCard class="cardBackground ion-no-margin noBorderRadius">
                    <IonCardContent class="ion-no-padding playerCard">
                        <div className="ion-padding">
                            {player.injured &&
                            <IonRow>
                                <IonCol><IonText color="danger"><i className="fas fa-briefcase-medical"/> {t("currently injured")}</IonText></IonCol>
                            </IonRow>
                            }
                            {player.position &&
                            <IonRow>
                                <IonCol><IonText>{t("position")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{getPlayerPosition(player.position)}</strong></IonText></IonCol>
                            </IonRow>
                            }
                            {team.name &&
                            <IonRow>
                                <IonCol><IonText>{t("team")}</IonText></IonCol>
                                <IonCol>
                                    <IonText color="primary"><strong>{team.name}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            }
                            {player.number &&
                            <IonRow>
                                <IonCol><IonText>{t("number")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{player.number}</strong></IonText></IonCol>
                            </IonRow>
                            }
                            {player.nationality &&
                            <IonRow>
                                <IonCol><IonText>{t("nationality")}</IonText></IonCol>
                                <IonCol>
                                    <IonImg className="flagImage"  src={player.nationality.flag} />
                                    <IonText color="primary"><strong>{t(`countries.${player.nationality.code.toUpperCase()}`)}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            }
                            {player.birthDate &&
                            <IonRow>
                                <IonCol><IonText>{t("birthdate")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong><Moment format="LL">{player.birthDate}</Moment></strong></IonText></IonCol>
                            </IonRow>
                            }
                            {player.birthPlace &&
                            <IonRow>
                                <IonCol><IonText>{t("birthplace")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{player.birthPlace}</strong></IonText></IonCol>
                            </IonRow>
                            }
                            {player.birthCountry && player.birthCountry.code &&
                            <IonRow>
                                <IonCol>{t("birthcountry")}</IonCol>
                                <IonCol>
                                    <IonImg className="flagImage"
                                            src={`${player.birthCountry.flag}`}/>
                                    <IonText color="primary"><strong>{t(`countries.${player.birthCountry.code.toUpperCase()}`)}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            }
                            {player.heightInCm && player.heightInCm > 0 &&
                            <IonRow>
                                <IonCol><IonText>{t("height")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{player.heightInCm} {t("cm")}</strong></IonText></IonCol>
                            </IonRow>
                            }
                            {player.weightInKg && player.weightInKg > 0 &&
                            <IonRow>
                                <IonCol><IonText>{t("weight")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{player.weightInKg} {t("kg")}</strong></IonText></IonCol>
                            </IonRow>
                            }
                        </div>
                        <IonList class="ion-no-padding">
                            {Object.keys(player.statistics).map((statisticKey: any, index: number) => {
                                return (
                                    <IonItem key={statisticKey} class={`playerStatistic ${getRowClass(index)}`} lines="none" style={{ "--min-height": "auto", "--inner-padding-end": "16px"}}>
                                        <IonCol size="10"><IonText>{t(`statisticsPlayer.${statisticKey}`)}</IonText></IonCol>
                                        <IonCol className="ion-text-end">{player.statistics[statisticKey]}</IonCol>
                                    </IonItem>
                                )
                            })}
                        </IonList>
                    </IonCardContent>
                </IonCard>
            </>
        )
    };

    function getPlayerById(playerId: number, players: any[]) {
        return players.find(player => player.id === playerId);
    }

    function getPlayerPosition(position: string) {
        let positionName;
        switch(position) {
            case "G":
                positionName = t("goalkeeper");
                break;
            case "D":
                positionName = t("defender");
                break;
            case "M":
                positionName = t("midfielder");
                break;
            case "F":
                positionName = t("attacker");
                break;
        }

        return positionName;
    }

    function getRowClass(index: any) {
        let rowClass;
        if(isEven(index)){
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getPlayer().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={`/teams/${match.params.teamId}`} text="" />
                    </IonButtons>
                    <IonTitle>{player.fullName}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        {player && player.fullName !== "" ? (
                            showPlayer()
                        ) : (
                            <div className="ion-padding cardLayout" style={{backgroundColor : "#fff", color : "#737373", fontSize : "14px"}}><IonText>{t("no information available")}</IonText></div>
                        )}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(Player);
