import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from "moment";
import Moment from "react-moment";
import {CONFIG} from "../constants";
import {Fetch} from "../Fetch";

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: `${CONFIG.API_ENDPOINT}translations/{{lng}}`,
        },
        fallbackLng: "en",
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        saveMissing: true,
        missingKeyHandler: function(lngs, ns, key, fallbackValue) {
            if (!key.startsWith('/')) {
                // console.log(lngs, ns, key, fallbackValue);
                Fetch.call({
                    method: "post",
                    url:    "logger/missingTranslation",
                    payload:{
                        lngs: lngs,
                        ns: ns,
                        key: key,
                        fallbackValue: fallbackValue
                    }
                });
            }
        }
    });

i18n.on('languageChanged', function(lng) {
    if (lng.length > 2) {
        i18n.changeLanguage(lng.substr(0, 2));
    }

    moment.locale(lng);
    Moment.globalLocale = lng;
});

export default i18n;
