import React, {useContext, useEffect, useState} from "react";
import {
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    IonSlides,
    IonSlide,
    IonGrid,
    IonList,
    IonItem,
    IonLabel,
    IonListHeader,
    IonBackButton,
    IonSpinner,
    useIonViewWillLeave,
    IonRefresher,
    IonRefresherContent, IonButton, IonBadge, IonImg,
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {CONFIG} from "../constants";
import {IFixture} from "../models/IFixture";
import {AppContext} from "../App";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {Img} from "react-image";
import {Capacitor} from '@capacitor/core';
import TimeMoment from "../component/TimeMoment";
import {Share} from "@capacitor/share";

interface MatchProps extends RouteComponentProps <{id: string}> {}

const MatchesItem: React.FC<MatchProps> = ({history, match}) => {
    const {t, i18n} = useTranslation();
    const [fixture, setMatch] = useState<IFixture | null>(null);
    const {state} = useContext(AppContext);
    let matchInterval: any;
    const sortOrder = ["G", "D", "M", "F"];

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useIonViewWillLeave(() => {
        clearInterval(matchInterval);
    });

    const getMatch = async () => {
        return await Axios.get(`${CONFIG.API_ENDPOINT}matches/${match.params.id}?predictions`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setMatch(res.data.match);
                return res.data.match;
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        })
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getMatch().then((matchFixture: any) => {
                if (matchFixture && matchFixture.state === 2) {
                    // eslint-disable-next-line
                    matchInterval = setInterval(() => {
                        getMatch();
                    }, 15000);
                }
            });
        }
        return () => clearInterval(matchInterval);
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    const matchState = () => {
        if (fixture === null) {
            return "";
        }
        let matchState;
        switch(fixture.state) {
            case 1:
                matchState = t("upcoming match");
                break;
            case 2:
                if (fixture.detailedState === "HT") {
                    matchState = t("halftime");
                } else if (fixture.detailedState === "ET") {
                    matchState = t("extra time");
                } else if (fixture.detailedState === "BT") {
                    matchState = t("break time");
                } else if (fixture.detailedState === "P") {
                    matchState = t("penalty in progress");
                } else {
                    matchState = t("live match");
                }
                break;
            case 3:
                if (fixture.detailedState === "CANC") {
                    matchState = t("canceled match");
                } else if (fixture.detailedState === "PST") {
                    matchState = t("match postponed");
                } else if (fixture.detailedState === "INT") {
                    matchState = t("match interrupted");
                } else {
                    matchState = t("finished match");
                }
                break;
        }
        return (<IonText color="primary"><strong>{matchState}</strong></IonText>);
    };

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function getEventIcon(eventName: string) {
        let eventIcon;

        if (eventName === 'PENALTY MISSED') {
            return (<span className="fa-stack fa-1x">
                <i className="fas fa-futbol fa-stack-1x" style={{'width':'auto'}}></i>
                <i className="fas fa-ban fa-stack-1x" style={{'width':'auto','color':'red'}}></i>
            </span>);
        } else {
            switch(eventName) {
                case "CARD YELLOW":
                    eventIcon = "fas fa-rectangle-portrait cardYellow";
                    break;
                case "CARD RED":
                    eventIcon = "fas fa-rectangle-portrait cardRed";
                    break;
                case "CARD YELLOWRED":
                    eventIcon = "fas fa-rectangle-portrait cardYellowRed";
                    break;
                case "SUBSTITUTION":
                    eventIcon = "fas fa-exchange";
                    break;
                case "PENALTY GOAL":
                    eventIcon = "fas fa-futbol";
                    break;
                case "GOAL":
                    eventIcon = "fas fa-futbol";
                    break;
            }
        }

        return <i className={eventIcon} />;
    }

    function getEventColumns(fixture: any, event: any) {
        let eventActor2;
        if(event.actor2){
            eventActor2 = event.actor2;
        } else {
            eventActor2 = "";
        }
        let eventActor1Class;
        let eventActor2Class;
        if(event.event === "GOAL"){
            eventActor2Class = "regEvent";
        } else if(event.event === "PENALTY GOAL") {
            eventActor2Class = "regEvent";
            eventActor2 = t('penalty scored');
        } else if (event.event === "PENALTY MISSED") {
            eventActor2Class = "regEvent";
            eventActor2 = t('missed penalty');
        } else if (event.event === 'SUBSTITUTION'){
            eventActor1Class = "substitutionEventIn";
            eventActor2Class = "substitutionEventOut";
        }

        if (fixture.teams[0].id === event.team) {
            return (
                <>
                    <IonCol class="ion-text-right ion-no-padding" size="5">
                        <IonRow class="ion-align-items-center">
                            <IonCol size="10"><IonText class={eventActor1Class}>{event.actor1}</IonText><br/><IonText class={eventActor2Class}>{eventActor2}</IonText></IonCol>
                            <IonCol size="2">{getEventIcon(event.event)}</IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol size="2" class="ion-text-center">{event.elapsed / 60}{event.elapsedPlus > 0 ? ' + ' + event.elapsedPlus/60 : ''}</IonCol>
                    <IonCol size="5" class="ion-text-left"> </IonCol>
                </>
            )
        } else {
            return (
                <>
                    <IonCol class="ion-text-right" size="5"> </IonCol>
                    <IonCol size="2" class="ion-text-center"><IonText>{event.elapsed / 60}</IonText> </IonCol>
                    <IonCol size="5" class="ion-text-left ion-no-padding">
                        <IonRow class="ion-align-items-center">
                            <IonCol sizeXs="3" sizeSm="2">{getEventIcon(event.event)}</IonCol>
                            <IonCol  sizeXs="9" sizeSm="10"><IonText class={eventActor1Class}>{event.actor1}</IonText><br/><IonText class={eventActor2Class}>{eventActor2}</IonText></IonCol>
                        </IonRow>
                    </IonCol>
                </>
            )
        }
    }

    function getPlayerPosition(position: any) {
        let positionName;
        switch(position) {
            case "G":
                positionName = t("goalkeeper");
                break;
            case "D":
                positionName = t("defender");
                break;
            case "M":
                positionName = t("midfielder");
                break;
            case "F":
                positionName = t("attacker");
                break;
        }

        return positionName;
    }

    function getStatistics(fixture: any) {
        if(Object.keys(fixture.teams[0].statistics).length > 0 && Object.keys(fixture.teams[1].statistics).length > 0){
            return(
                <IonSlide>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" class="ion-margin-bottom">
                                <IonText color="primary"><strong>{t('statistics')}</strong></IonText>
                            </IonCol>
                            <IonCol size="12">
                                {Object.keys(fixture.teams[0].statistics).map((statisticKey: any, index: any) => {
                                    if(isEven(index)){
                                        return (
                                            <IonRow class="ion-align-items-center matchStatisticsRow evenRow" key={statisticKey}>
                                                <IonCol className="ion-text-start"><IonText  style={{fontSize: "14px", fontWeight: "bold"}} color="primary">{fixture.teams[0].statistics[statisticKey]}</IonText></IonCol>
                                                <IonCol size="8" style={{fontSize: "14px"}}><IonText>{t(`statisticsMatch.${statisticKey}`)}</IonText></IonCol>
                                                <IonCol className="ion-text-end"><IonText  style={{fontSize: "14px", fontWeight: "bold"}} color="primary">{fixture.teams[1].statistics[statisticKey]}</IonText></IonCol>
                                            </IonRow>
                                        )
                                    } else {
                                        return (
                                            <IonRow class="ion-align-items-center matchStatisticsRow" key={statisticKey}>
                                                <IonCol className="ion-text-start"><IonText  style={{fontSize: "14px", fontWeight: "bold"}} color="primary">{fixture.teams[0].statistics[statisticKey]}</IonText></IonCol>
                                                <IonCol size="8" style={{fontSize: "14px"}}><IonText>{t(`statisticsMatch.${statisticKey}`)}</IonText></IonCol>
                                                <IonCol className="ion-text-end"><IonText  style={{fontSize: "14px", fontWeight: "bold"}} color="primary">{fixture.teams[1].statistics[statisticKey]}</IonText></IonCol>
                                            </IonRow>
                                        )
                                    }
                                })}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonSlide>
            )
        }
    }

    function getFirstTeam(team: any){
        if(team.players.length > 0){
            return(
                <IonSlide class="teamSlide">
                    <IonGrid>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <IonText color="primary"><strong>{team.name}</strong></IonText>
                                <Img className="teamLogo" alt={team.name} title={team.name} src={team.logo} loader={<div><IonSpinner class="ion-margin-top" name="crescent" /></div>}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <IonList>
                                    {team.players.sort((a: any, b: any) => {
                                        if (a.position === b.position) {
                                            if (a.number < b.number) { return -1}
                                            if (a.number > b.number) { return 1}
                                        }
                                        return sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position);
                                    }).map((player:any, key:any) => {
                                        if(!player.isSubstitute) {
                                            return (
                                                <IonItem key={key} class="ion-no-padding" routerDirection="forward" detail={false} routerLink={`/teams/${team.team_id}/player/${player.id}`}>
                                                    <IonText class="playerNumber" color="primary"><p>{player.number}</p></IonText>
                                                    <IonLabel class="ion-padding-horizontal IonLabelSmallerFontSize">
                                                        <div className="ion-text-center removeNoWrap">{player.fullName}</div>
                                                        <p className="ion-text-center">
                                                            {getPlayerPosition(player.position)}
                                                        </p>
                                                        {player.isCaptain &&
                                                         <IonText color="primary"><p className="ion-text-center">{t("captain")}</p></IonText>
                                                        }
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </IonList>
                                <IonList>
                                    <IonListHeader class="ion-no-padding" lines="full">
                                        <IonLabel color="primary" class="ion-text-left IonLabelSmallerFontSize">{t("substitutes")}</IonLabel>
                                    </IonListHeader>
                                    {team.players.map((player:any, key:any) => {
                                        if(player.isSubstitute) {
                                            return (
                                                <IonItem key={key} class="ion-no-padding" routerDirection="forward" detail={false} routerLink={`/teams/${team.team_id}/player/${player.id}`}>
                                                    <IonText class="playerNumber" color="primary"><p>{player.number}</p></IonText>
                                                    <IonLabel class="ion-padding-horizontal IonLabelSmallerFontSize">
                                                        <div className="ion-text-center removeNoWrap">{player.fullName}</div>
                                                        <p className="ion-text-center">
                                                            {getPlayerPosition(player.position)}
                                                        </p>
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </IonList>
                                {team.coach &&
                                    <IonList>
                                        <IonListHeader class="ion-no-padding" lines="full">
                                            <IonLabel color="primary" class="ion-text-left IonLabelSmallerFontSize">{t("coach")}</IonLabel>
                                        </IonListHeader>
                                        <IonItem class="ion-no-padding" button={true} routerLink={`/teams/${team.team_id}/coach/${team.coach.id}`} routerDirection="forward" detail={false}>
                                            <IonLabel class="ion-padding-horizontal IonLabelSmallerFontSize">
                                                <div className="ion-text-center removeNoWrap">{team.coach.fullName}</div>
                                                <p className="ion-text-center">
                                                    {t("coach")}
                                                </p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonSlide>
            )
        }
    }

    function getSecondTeam(team: any) {
        if(team.players.length > 0){
            return(
                <IonSlide class="teamSlide">
                    <IonGrid>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <IonText color="primary"><b>{team.name}</b></IonText>
                                <Img className="teamLogo" alt={team.name} title={team.name} src={team.logo} loader={<div><IonSpinner class="ion-margin-top" name="crescent" /></div>}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <IonList>
                                    {team.players.sort((a: any, b: any) => {
                                        if (a.position === b.position) {
                                            if (a.number < b.number) { return -1}
                                            if (a.number > b.number) { return 1}
                                        }
                                        return sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position);
                                    }).map((player:any, key:any) => {
                                        if(!player.isSubstitute) {
                                            return (
                                                <IonItem key={key} class="ion-no-padding" routerDirection="forward" detail={false} routerLink={`/teams/${team.team_id}/player/${player.id}`}>
                                                    <IonText class="playerNumber" color="primary"><p>{player.number}</p></IonText>
                                                    <IonLabel class="ion-padding-horizontal IonLabelSmallerFontSize">
                                                        <div className="ion-text-center removeNoWrap">{player.fullName}</div>
                                                        <p className="ion-text-center">
                                                            {getPlayerPosition(player.position)}
                                                        </p>
                                                        {player.isCaptain &&
                                                            <IonText color="primary"><p className="ion-text-center">{t("captain")}</p></IonText>
                                                        }
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </IonList>
                                <IonList>
                                    <IonListHeader class="ion-no-padding" lines="full">
                                        <IonLabel color="primary" class="ion-text-left IonLabelSmallerFontSize">{t("substitutes")}</IonLabel>
                                    </IonListHeader>
                                    {team.players.map((player:any, key:any) => {
                                        if(player.isSubstitute) {
                                            return (
                                                <IonItem key={key} class="ion-no-padding" routerDirection="forward" detail={false} routerLink={`/teams/${team.team_id}/player/${player.id}`}>
                                                    <IonText class="playerNumber" color="primary"><p>{player.number}</p></IonText>
                                                    <IonLabel class="ion-padding-horizontal IonLabelSmallerFontSize">
                                                        <div className="ion-text-center removeNoWrap">{player.fullName}</div>
                                                        <p className="ion-text-center">
                                                            {getPlayerPosition(player.position)}
                                                        </p>
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </IonList>
                                {team.coach &&
                                    <IonList>
                                        <IonListHeader class="ion-no-padding" lines="full">
                                            <IonLabel color="primary" class="ion-text-left IonLabelSmallerFontSize">{t("coach")}</IonLabel>
                                        </IonListHeader>
                                        <IonItem class="ion-no-padding" button={true} routerLink={`/teams/${team.team_id}/coach/${team.coach.id}`} routerDirection="forward" detail={false}>
                                            <IonLabel class="ion-padding-horizontal IonLabelSmallerFontSize">
                                                <div className="ion-text-center removeNoWrap">{team.coach.fullName}</div>
                                                <p className="ion-text-center">
                                                    {t("coach")}
                                                </p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonSlide>
            )
        }
    }

    function sharePrediction() {
        if(fixture) {
            Share.share({
                title: t('share prediction'),
                text: t('shareText',{team1result: fixture.teams[0].predictions[1000000].result, team2result: fixture.teams[1].predictions[1000000].result,team1name: fixture.teams[0].name, team2name: fixture.teams[1].name }),
                url: `https://yourfootballapp.eu/`,
                dialogTitle: t('share prediction')
            }).then((result) => {
                console.log(result);
            });
        }
    }

    // TODO: get eliminated team when knock out match (results: 1000/1500/2000) -> needs work @API to determine if KO game
    // function teamEliminated(teamId:number) {
    //     if(fixture && fixture.state === 3 && fixture.teams[0].results[2000] && fixture.teams[1].results[2000]){
    //         if(fixture.teams[0].results[2000].result > fixture.teams[1].results[2000].result) {
    //             if(fixture.teams[1].id === teamId){
    //                 return "teamEliminated";
    //             }
    //         } else {
    //             if(fixture.teams[0].id === teamId){
    //                 return "teamEliminated";
    //             }
    //         }
    //     }
    // }

    const showItem = () => {
        if (fixture === null) {
            return <div/>
        }
        const slideOpts = {
            autoHeight:true
        };
        return (
            <>
                <IonRow class="ion-align-items-center">
                    <IonCol size="4">
                        <Link to={`/teams/${fixture.teams[0].team_id}`}>
                            <Img className="teamLogoMatch" alt={fixture.teams[0].name} title={fixture.teams[0].name} src={fixture.teams[0].logo} loader={<IonSpinner name="crescent" style={{ margin: "auto", display: "block"}} />} />
                        </Link>
                    </IonCol>
                    <IonCol size="4">
                        <IonRow>
                            <IonText class="matchScore">{fixture.teams[0].currentResult} - {fixture.teams[1].currentResult}</IonText>
                        </IonRow>
                        {fixture.state === 2 &&
                        <IonRow>
                            <IonText class="matchTimeElapsed">{fixture.elapsed / 60}'</IonText>
                        </IonRow>
                        }
                    </IonCol>
                    <IonCol size="4">
                        <Link to={`/teams/${fixture.teams[1].team_id}`}>
                            <Img className="teamLogoMatch" alt={fixture.teams[1].name} title={fixture.teams[1].name} src={fixture.teams[1].logo} loader={<IonSpinner name="crescent" style={{ margin: "auto", display: "block"}} />} />
                        </Link>
                    </IonCol>
                    <IonCol size="12">
                        {(fixture.teams[0].results[2000] && fixture.teams[1].results[2000]) &&
                            <div className="ion-text-center">
                                <div>
                                    <IonText color="primary">{t("penalty shootout")}</IonText>
                                </div>
                                <IonText color="primary">{fixture.teams[0].results[2000].result} - {fixture.teams[1].results[2000].result}</IonText>
                            </div>
                        }
                    </IonCol>
                    <span className="line"/>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs="6" sizeSm="4" class={'teamName'}><IonText><Link to={`/teams/${fixture.teams[0].team_id}`}>{fixture.teams[0].name}</Link></IonText></IonCol>
                    <IonCol sizeXs="6" pushXs="0" pushSm="4" sizeSm="4" class={'teamName'}><IonText><Link to={`/teams/${fixture.teams[1].team_id}`}>{fixture.teams[1].name}</Link></IonText></IonCol>
                </IonRow>
                {(fixture.teams[0].form && fixture.teams[1].form) &&
                    <IonRow>
                        <IonCol sizeXs="6" sizeSm="4" class="previousOutcome">
                            {fixture.teams[0].form.split("").map((letter: string, index:number) => {
                                return(
                                    <IonBadge key={index + letter} class={`previousOutcomeBadge ${letter === 'L' ? "loseColor" : letter === 'D' ? "drawColor" : letter === 'W' ? "winColor" : "" }`}>{t(`teamForm.${letter}`)}</IonBadge>
                                )
                            })}
                        </IonCol>
                        <IonCol sizeXs="6" pushXs="0" pushSm="4" sizeSm="4" class="previousOutcome">
                            {fixture.teams[1].form.split("").map((letter: string, index:number) => {
                                return(
                                    <IonBadge key={index + letter} class={`previousOutcomeBadge ${letter === 'L' ? "loseColor" : letter === 'D' ? "drawColor" : letter === 'W' ? "winColor" : "" }`}>{t(`teamForm.${letter}`)}</IonBadge>
                                )
                            })}
                        </IonCol>
                    </IonRow>
                }
                <span className="line"/>
                {((fixture.teams[0].predictions && fixture.teams[0].predictions[1000000]) &&
                    (fixture.teams[1].predictions && fixture.teams[1].predictions[1000000])) &&
                    !fixture.wasActive &&
                    <>
                        <IonRow class="ion-text-center">
                            <IonCol size="12" style={{ fontSize : "18px"}}><IonText color="primary"><strong>{t("your prediction")}</strong></IonText></IonCol>
                            <IonCol size="12">
                                <IonText style={{ fontSize : "22px"}}>{fixture.teams[0].predictions[1000000].result} - {fixture.teams[1].predictions[1000000].result}</IonText>
                                {(state.me && fixture.rankingScores && fixture.rankingScores.length > 0 && fixture.rankingScores.find(function (rankingScore: any) { return rankingScore.group && rankingScore.group.id === state.me.primaryGroup.id})) &&
                                    <IonText color="primary" style={{ fontSize : "14px", display : "block" }}>({fixture.rankingScores.find(function (rankingScore: any) { return rankingScore.group && rankingScore.group.id === state.me.primaryGroup.id}).score + " "} {t("points")})</IonText>
                                }
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-text-center">
                            {Capacitor.isPluginAvailable('Share') && fixture.state === 1 &&
                                <IonCol>
                                    <IonButton onClick={sharePrediction}><i className="fas fa-share-alt shareButtonIcon" />{t('share prediction')}</IonButton>
                                </IonCol>
                            }
                            {fixture.state === 1 &&
                                <IonCol>
                                    <IonButton href="/predictions" fill="outline"><i className="fas fa-plus-circle shareButtonIcon" />{t('edit prediction')}</IonButton>
                                </IonCol>
                            }
                        </IonRow>
                        <span className="line"/>
                    </>
                }
                <IonRow class="ion-margin-bottom">
                    <IonCol class="ion-text-center">
                        {matchState()}
                    </IonCol>
                </IonRow>
                {fixture.events && fixture.events.length > 0 &&
                <IonGrid>
                    {fixture.events.slice(0).reverse().map((event, key) => {
                        return (
                            <IonRow key={event.id} class="ion-align-items-center eventRow">
                                {getEventColumns(fixture, event)}
                            </IonRow>
                        )
                    })}
                </IonGrid>}
                <span className="line"/>
                <IonRow>
                    <IonCol size="12" class="ion-text-center"><IonText color="primary"><strong>{t("match info")}</strong></IonText></IonCol>
                    <IonCol size="12" style={{ fontSize : "14px" }}>
                        {fixture.competition.name &&
                            <IonRow>
                                <IonCol size="auto"><IonText color="primary"><strong>{t("competition")}</strong></IonText></IonCol>
                                <IonCol class="ion-text-right"><IonText>{fixture.competition.name}</IonText></IonCol>
                            </IonRow>
                        }
                        {fixture.round &&
                            <IonRow>
                                <IonCol size="auto"><IonText color="primary"><strong>{t("round")}</strong></IonText></IonCol>
                                <IonCol class="ion-text-right"><IonText>{fixture.round}</IonText></IonCol>
                            </IonRow>
                        }
                        {fixture.venue &&
                            <IonRow>
                                <IonCol size="auto"><IonText color="primary"><strong>{t("venue")}</strong></IonText></IonCol>
                                <IonCol class="ion-text-right"><IonText>{fixture.venue}</IonText></IonCol>
                            </IonRow>
                        }
                        {fixture.venueInfo && fixture.venueInfo.address &&
                        <IonRow>
                            <IonCol size="auto"><IonText color="primary"><strong>{t("venue address")}</strong></IonText></IonCol>
                            <IonCol class="ion-text-right"><IonText>{fixture.venueInfo.address}</IonText></IonCol>
                        </IonRow>
                        }
                        {fixture.venueInfo && fixture.venueInfo.city &&
                        <IonRow>
                            <IonCol size="auto"><IonText color="primary"><strong>{t("venue city")}</strong></IonText></IonCol>
                            <IonCol class="ion-text-right"><IonText>{fixture.venueInfo.city}</IonText></IonCol>
                        </IonRow>
                        }
                        {fixture.venueInfo && fixture.venueInfo.country &&
                        <IonRow>
                            <IonCol size="auto"><IonText color="primary"><strong>{t("venue country")}</strong></IonText></IonCol>
                            <IonCol class="ion-text-right">
                                <IonText>
                                <IonImg className="flagImage" src={`${fixture.venueInfo.country.flag}`}/>
                                {t(`countries.${fixture.venueInfo.country.code.toUpperCase()}`)}
                                </IonText>
                            </IonCol>
                        </IonRow>
                        }
                        {fixture.venueInfo && fixture.venueInfo.capacity &&
                        <IonRow>
                            <IonCol size="auto"><IonText color="primary"><strong>{t("venue capacity")}</strong></IonText></IonCol>
                            <IonCol class="ion-text-right"><IonText>{fixture.venueInfo.capacity}</IonText></IonCol>
                        </IonRow>
                        }
                        {fixture.venueInfo && fixture.venueInfo.surface &&
                        <IonRow>
                            <IonCol size="auto"><IonText color="primary"><strong>{t("venue surface")}</strong></IonText></IonCol>
                            <IonCol class="ion-text-right"><IonText>{t(`${fixture.venueInfo.surface}`)}</IonText></IonCol>
                        </IonRow>
                        }
                        {fixture.referee &&
                            <IonRow>
                                <IonCol size="auto"><IonText color="primary"><strong>{t("referee")}</strong></IonText></IonCol>
                                <IonCol class="ion-text-right"><IonText>{fixture.referee}</IonText></IonCol>
                            </IonRow>
                        }
                    </IonCol>
                    <span className="line"/>
                </IonRow>
                {((fixture.teams[0].statistics && fixture.teams[0].statistics.length > 0 ) || (fixture.teams[1].statistics && fixture.teams[1].statistics.length > 0 ) || (fixture.teams[0].players && fixture.teams[0].players.length > 0 ) || (fixture.teams[1].players && fixture.teams[1].players.length > 0 )) &&
                    <IonSlides pager={true} class="matchInfoSlider" options={slideOpts}>
                        {getStatistics(fixture)}
                        {getFirstTeam(fixture.teams[0])}
                        {getSecondTeam(fixture.teams[1])}
                    </IonSlides>
                }
                <IonRow>
                </IonRow>
            </>
        )
    };

    const showMatchTitle  = () => {
        if (fixture === null) {
            return "";
        }
        return (<IonTitle class="ion-no-padding matchTitle"><Moment unix format="LL">{fixture.startTime}</Moment> <TimeMoment amPmTime={state.amPmTime} time={fixture.startTime} timeZone={state.me.timeZone} /></IonTitle>);
    };

    if (!match.params.id) {
        return <p>{t('no match id found')}</p>
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getMatch().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/matches" text="" />
                    </IonButtons>
                    {showMatchTitle()}
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6" className="ion-text-center">
                        {fixture ? (
                            showItem()
                        ) : (
                            <IonText style={{ color : "#737373", fontSize : "14px"}}>{t("no information available")}</IonText>
                        )}
                    </IonCol>
                </IonRow>

            </IonContent>
        </IonPage>
    )
};

export default React.memo(MatchesItem);
