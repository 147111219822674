import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButton,
    IonButtons, IonCol, IonContent, IonGrid,
    IonHeader, IonItem, IonList, IonListHeader,
    IonMenuButton, IonModal,
    IonPage, IonRefresher, IonRefresherContent, IonRow, IonSpinner, IonText, IonThumbnail,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import Axios from "axios";
import {CONFIG} from "../constants";
import {AppContext} from "../App";
import {ITeamGroup} from "../models/ITeamGroup";
import {ITeamGroupRanking} from "../models/ITeamGroupRanking";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {Img} from "react-image";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";

const GroupStage: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [groups, setGroups] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const {state} = useContext(AppContext);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                // eslint-disable-next-line
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getGroups = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}competitions/${CONFIG.COMPETITION}/groups`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setGroups(res.data.groups);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getGroups();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    const showGroupRankings = (rankings: any) => {
        return (
            rankings.map((ranking: ITeamGroupRanking, index: any) => {
                return (
                    <IonItem button={true} detail={false} routerDirection="forward" routerLink={`/teams/${ranking.team.id}`} key={ranking.team.id} className={`${getRowClass(index)} ion-no-padding`} lines="none" style={{ "--inner-padding-end": 0}}>
                        <IonCol className="ion-text-center groupStagePositionColumn">{ranking.position}</IonCol>
                        <IonCol className="groupStageTitleColumn">
                            <IonRow class="ion-align-items-center">
                                <IonCol size="auto" class="ion-padding-end">
                                    <IonThumbnail style={{"--size": "32px"}}><Img src={ranking.team.logo} loader={<IonSpinner name="crescent" />} /></IonThumbnail>
                                </IonCol>
                                <IonCol>
                                    <IonText>{ranking.team.name}</IonText>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol className="ion-text-center groupStagePointsColumn"><IonText color="primary"><strong>{ranking.points}</strong></IonText></IonCol>
                        <IonCol size="1" class="ion-text-center extraInfoSmallScreen"><IonText color="primary"><strong>{ranking.matchesPlayed}</strong></IonText></IonCol>
                        <IonCol size="1" class="ion-text-center extraInfoSmallScreen"><IonText color="primary"><strong>{ranking.matchesWon}</strong></IonText></IonCol>
                        <IonCol size="1" class="ion-text-center extraInfoSmallScreen"><IonText color="primary"><strong>{ranking.matchesDrawn}</strong></IonText></IonCol>
                        <IonCol size="1" class="ion-text-center extraInfoSmallScreen"><IonText color="primary"><strong>{ranking.matchesLost}</strong></IonText></IonCol>
                        <IonCol size="1" class="ion-text-center extraInfoSmallScreen"><IonText color="primary"><strong>{ranking.goalsFor}</strong></IonText></IonCol>
                        <IonCol size="1" class="ion-text-center extraInfoSmallScreen"><IonText color="primary"><strong>{ranking.goalsAgainst}</strong></IonText></IonCol>
                    </IonItem>
                )
            })
        );
    };

    const showGroups = () => {
        if(groups && groups.length > 0) {
            return (
                groups.map((group: ITeamGroup) =>
                    <IonList class="ion-margin-bottom" style={{background: "transparent"}} key={group.group}>
                        <IonListHeader style={{color: "var(--ion-color-secondary)", fontSize: "16px"}} class="ion-no-padding">
                            <IonGrid style={{ paddingLeft:0, paddingRight:0}}>
                                <IonRow className="ion-no-padding">
                                    <IonCol className="groupStageHeaderTitle" style={{ paddingLeft:0, paddingRight:0}}><strong>{t(group.group)}</strong></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><IonText><strong>{t("group_stage_abbreviation_p")}</strong></IonText></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><strong>{t("group_stage_abbreviation_mp")}</strong></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><strong>{t("group_stage_abbreviation_w")}</strong></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><strong>{t("group_stage_abbreviation_d")}</strong></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><strong>{t("group_stage_abbreviation_l")}</strong></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><strong>{t("group_stage_abbreviation_gf")}</strong></IonCol>
                                    <IonCol className="ion-text-center extraInfoSmallScreen"><strong>{t("group_stage_abbreviation_ga")}</strong></IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonListHeader>
                        <div className="cardLayout">
                            {showGroupRankings(group.rankings)}
                        </div>
                    </IonList>
                )
            );
        } else {
            return <div className="ion-padding cardLayout" style={{backgroundColor : "#fff", color : "#737373", fontSize : "14px"}}><IonText>{t("no information available")}</IonText></div>
        }
    };

    function getRowClass(index: any) {
        let rowClass;
        if (isEven(index)) {
            rowClass = "itemEvenRow";
        } else {
            rowClass = "";
        }
        return rowClass;
    }

    function isEven(value: any) {
        return value % 2 === 0;
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getGroups().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('group stage')}</IonTitle>
                    <IonButtons slot="end" >
                        <IonButton className="infoButtonLandscape" onClick={() => setShowInfoModal(true)}>{t("info")}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="10" sizeXl="8">
                        {showGroups()}
                    </IonCol>
                </IonRow>
            </IonContent>
            <IonModal isOpen={showInfoModal} onDidDismiss={() => setShowInfoModal(false)}>
                <IonContent scrollEvents={true}>
                    <div className="ion-padding" style={{ overflowY: "auto"}}>
                        <IonListHeader className="ion-no-padding">
                            <IonText color="primary">{t("abbreviation explanation")}</IonText>
                        </IonListHeader>
                        <IonList class="ion-no-padding">
                            <IonItem class="itemEvenRow" lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_p")}</IonText>
                                <IonText>{t('points')}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_mp")}</IonText>
                                <IonText>{t('matches played')}</IonText>
                            </IonItem>
                            <IonItem class="itemEvenRow" lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_w")}</IonText>
                                <IonText>{t('matches won')}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_d")}</IonText>
                                <IonText>{t('draws')}</IonText>
                            </IonItem>
                            <IonItem class="itemEvenRow" lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_l")}</IonText>
                                <IonText>{t('matches lost')}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_gf")}</IonText>
                                <IonText>{t('goals for')}</IonText>
                            </IonItem>
                            <IonItem class="itemEvenRow" lines="none">
                                <IonText className="groupStageInfoModalAbbreviation" slot="start" color="primary">{t("group_stage_abbreviation_ga")}</IonText>
                                <IonText>{t('goals against')}</IonText>
                            </IonItem>
                        </IonList>
                    </div>
                    <div>
                        <IonButton expand="full" class="ion-margin" onClick={() => setShowInfoModal(false)}>{t('close')}</IonButton>
                    </div>
                </IonContent>
            </IonModal>
        </IonPage>
    );
};

export default React.memo(GroupStage);
