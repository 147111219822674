import React, {useContext, useEffect, useState} from 'react';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCard,
    IonSpinner,
    IonPopover,
    IonButton,
    IonListHeader,
    IonList,
    IonItem,
    IonRow,
    IonCol,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonRefresherContent,
    IonRefresher,
    IonInfiniteScroll, IonInfiniteScrollContent,
} from '@ionic/react';
import {useTranslation} from "react-i18next";
import {AppContext} from "../App";
import {INews} from "../models/INews";
import {CONFIG} from "../constants";
import {Img} from "react-image";
import {RefresherEventDetail, toastController} from "@ionic/core";
import Axios from "axios";
import {INewsCategory} from "../models/INewsCategory";
import {Plugins} from '@capacitor/core';
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";

const NewsOverview: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [newsItems, setNewsItems] = useState<INews []>([]);
    const [newsCategories, setNewsCategories] = useState<INewsCategory []>([]);
    const {state} = useContext(AppContext);
    const [showPopover, setShowPopover] = useState(false);
    const [filter, setFilter] = useState<any>();
    const {Browser} = Plugins;
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [screenWidth, setScreenWidth] = useState<any>(0);
    const [screenHeight, setScreenHeight] = useState<any>(0);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    }, []);

    window.addEventListener("resize", function(){
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    });

    const getNews = async (reset?: boolean) => {
        const items: INews[] = reset ? [] : newsItems;
        const categoryFilter = filter ? filter : '';
        const lastNewsItemCode = newsItems.length > 0 && !reset ? newsItems[newsItems.length -1].code : '';
        const limit = screenWidth > 992 ? Math.round((screenHeight - 300) / 80 * 3) : 10;
        Axios.get(`${CONFIG.API_ENDPOINT}news/list?limit=${limit}&category=${categoryFilter}&from=${lastNewsItemCode}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setNewsItems([...items, ...res.data.newsList]);
                setDisableInfiniteScroll(res.data.newsList.length < 10);
            } else {
                setDisableInfiniteScroll(true);
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: true,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    const getNewsCategories = async () => {
        Axios.get(`${CONFIG.API_ENDPOINT}news/categories`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setNewsCategories(res.data.categories);
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        setFilter(null);
        // eslint-disable-next-line
    }, [state.token]);

    useEffect(() => {
        if (!state.isLoading && state.token) {
            getNews(true).then(() => {
                getNewsCategories();
            });
        }
        // eslint-disable-next-line
    }, [filter, i18n.language, state.isLoading, state.token, state.me.primaryGroup]);

    useEffect(() => {
        if (!state.isLoading && state.token) {
            showNewsItems();
        }
        // eslint-disable-next-line
    }, [newsItems]);

    const showNewsItems = () => {
        if (newsItems && newsItems.length > 0) {
            return (newsItems.map((newsItem: INews, index: number) =>
                <IonCol size="12" key={`newsitem_overview_item_${index}`} >
                    <IonCard button={true} onClick={(e) => {openNewsItem(e, newsItem)}}  class={"ion-no-margin noBorderRadius cardBackground " + (index === 0 ? "newsItemCard" : "newsItemSimple")}>
                        {index === 0 &&
                            <div className="cardImage">
                                {newsItem.image ?
                                    <Img style={{verticalAlign: "middle"}} src={newsItem.image}
                                         loader={<IonSpinner name="crescent" class="ion-margin-top" style={{
                                             marginLeft: "auto",
                                             marginRight: "auto",
                                             display: "block"
                                         }}/>}/>
                                    :
                                    <div className="placeholderImage">
                                        <i className="fas fa-image"/>
                                    </div>
                                }
                            </div>
                        }
                        <IonCardHeader class="ion-no-padding">
                            {index > 0 ? (
                                <IonRow class="ion-align-items-center">
                                    <IonCol size="auto" class="ion-no-padding"  style={{ paddingRight : "10px"}}>
                                        <div className="newsItemThumbnailContainer">
                                            <Img className="newsItemThumbnail" src={newsItem.image} loader={<IonSpinner class="IonSpinner" name="crescent" />} />
                                        </div>
                                    </IonCol>
                                    <IonCol class="ion-no-padding">
                                        <IonCardTitle color="primary" class="ion-no-margin newsItemTitle">{newsItem.title}</IonCardTitle>
                                        <IonCardSubtitle class="ion-no-margin newsItemSubTitle">{newsItem.source ? newsItem.source : newsItem.group.name}</IonCardSubtitle>
                                    </IonCol>
                                </IonRow>
                            ) : (
                                <IonRow class="ion-padding">
                                    <IonCol size="12">
                                        <IonCardTitle color="primary" class="ion-no-margin" style={{ fontSize : "18px" }} >{newsItem.title}</IonCardTitle>
                                        <IonCardSubtitle class="ion-no-margin" style={{ fontSize: "16px" }}>{newsItem.source ? newsItem.source : newsItem.group.name}</IonCardSubtitle>
                                    </IonCol>
                                </IonRow>
                            )}
                        </IonCardHeader>
                    </IonCard>
                </IonCol>
            ))
        } else {
            return <IonCol size="12"><IonCard className="cardBackground noBorderRadius ion-padding ion-no-margin">{t('no news found')}</IonCard></IonCol>
        }
    };

    function openNewsItem(e: any, newsItem: INews) {
        e.preventDefault();
        if (newsItem.group) {
            history.push(`news/${newsItem.code}`);
        } else {
            Browser.open({url: newsItem.link});
        }
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getNews().then(() => {
            getNewsCategories().then(() => {
                event.detail.complete();
            });
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    async function searchNext($event: CustomEvent<void>) {
        await getNews();
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('news')}</IonTitle>
                    <IonButtons slot="end">
                        <IonPopover isOpen={showPopover} onDidDismiss={e => setShowPopover(false)}>
                            <IonList class="ion-no-padding ion-no-margin">
                                <IonListHeader>{t('filter')}</IonListHeader>
                                {/*TODO: door de nieuwsbronnen loopen wanneer beschikbaar*/}
                                <IonRadioGroup value={filter} onIonChange={(e) => {setFilter(e.detail.value); setShowPopover(false)}}>
                                    <IonItem class="ion-no-padding">
                                        <IonRadio slot="end" style={{ marginRight: 0}} value={null} />
                                        <IonLabel class="ion-padding-start">{t('general news')}</IonLabel>
                                    </IonItem>
                                    {newsCategories.map((newsCategory: INewsCategory) => {
                                        if (newsCategory.code === 'GLOBAL') {
                                            return null;
                                        }
                                        return (
                                            <IonItem class="ion-no-padding" key={newsCategory.code}>
                                                <IonRadio slot="end" style={{ marginRight: 0}} value={newsCategory.code} />
                                                <IonLabel class="ion-padding-start">{newsCategory.title}</IonLabel>
                                            </IonItem>
                                        )
                                    })}
                                </IonRadioGroup>
                            </IonList>
                        </IonPopover>
                        <IonButton onClick={() => setShowPopover(true)}><i className="fas fa-filter"/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeLg="8" sizeXl="6">
                        <IonRow className="ion-align-items-center">
                            {showNewsItems()}
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                    <IonInfiniteScrollContent loadingText={t('loading')}></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(NewsOverview);
