import {
    IonButtons,
    IonCard, IonCardContent, IonCol,
    IonContent,
    IonHeader, IonImg,
    IonMenuButton,
    IonPage, IonRefresher, IonRefresherContent, IonRow, IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RefresherEventDetail, toastController} from "@ionic/core";
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AppContext} from "../App";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import {RouteComponentProps} from "react-router-dom";
import Axios from "axios";
import {CONFIG} from "../constants";
import {ITeam} from "../models/ITeam";
import Moment from "react-moment";

interface CoachProp extends RouteComponentProps <{teamId: string, coachId: string}> {}

const Coach: React.FC<CoachProp> = ({history , match}) => {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [coach, setCoach] = useState<any>({
        birthCountry: {
            code: "",
            flag: ""
        },
        birthDate: "",
        birthPlace: "",
        fullName: "",
        heightInCm: 0,
        id: 999999,
        nationality: {
            code: "",
            flag: ""
        },
        weightInKg: 0
    });
    const [team, setTeam] = useState<ITeam>({
        id: 0,
        name: "",
        code: "",
        logo: "",
    });

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getCoach().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    const getCoach = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}competitions/${CONFIG.COMPETITION}/teams/${match.params.teamId}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                if(res.data.team.coach){
                    setCoach(res.data.team.coach);
                    setTeam(res.data.team)
                }
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    const showCoach = () => {
        return (
            <>
                <IonCard class="cardBackground ion-no-margin noBorderRadius">
                    <IonCardContent class="ion-no-padding playerCard">
                        <div className="ion-padding">
                            <IonRow>
                                <IonCol><IonText>{t("position")}</IonText></IonCol>
                                <IonCol>
                                    <IonText color="primary"><strong>{t("coach")}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            {team.name &&
                            <IonRow>
                                <IonCol><IonText>{t("team")}</IonText></IonCol>
                                <IonCol>
                                    <IonText color="primary"><strong>{team.name}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            }
                            {coach.nationality &&
                            <IonRow>
                                <IonCol><IonText>{t("nationality")}</IonText></IonCol>
                                <IonCol>
                                    <IonImg className="flagImage"  src={coach.nationality.flag} />
                                    <IonText color="primary"><strong>{t(`countries.${coach.nationality.code.toUpperCase()}`)}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            }
                            {coach.birthDate &&
                            <IonRow>
                                <IonCol><IonText>{t("birthdate")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong><Moment format="LL">{coach.birthDate}</Moment></strong></IonText></IonCol>
                            </IonRow>
                            }
                            {coach.birthPlace &&
                            <IonRow>
                                <IonCol><IonText>{t("birthplace")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{coach.birthPlace}</strong></IonText></IonCol>
                            </IonRow>
                            }
                            {coach.birthCountry.code &&
                            <IonRow>
                                <IonCol>{t("birthcountry")}</IonCol>
                                <IonCol>
                                    <IonImg className="flagImage" src={`${coach.birthCountry.flag}`}/>
                                    <IonText color="primary"><strong>{t(`countries.${coach.birthCountry.code.toUpperCase()}`)}</strong></IonText>
                                </IonCol>
                            </IonRow>
                            }
                            {coach.heightInCm && coach.heightInCm > 0 &&
                            <IonRow>
                                <IonCol><IonText>{t("height")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{coach.heightInCm} {t("cm")}</strong></IonText></IonCol>
                            </IonRow>
                            }
                            {coach.weightInKg && coach.weightInKg > 0 &&
                            <IonRow>
                                <IonCol><IonText>{t("weight")}</IonText></IonCol>
                                <IonCol><IonText color="primary"><strong>{coach.weightInKg} {t("kg")}</strong></IonText></IonCol>
                            </IonRow>
                            }
                        </div>
                    </IonCardContent>
                </IonCard>
            </>
        )
    };

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getCoach();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, match.params.teamId, match.params.coachId]);

    useEffect(() => {
        showCoach();
        // eslint-disable-next-line
    }, [coach, team]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle text-center>{coach.fullName ? coach.fullName : t('coach')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        {coach && coach.fullName !== "" ? (
                            showCoach()
                        ) : (
                            <div className="ion-padding cardLayout" style={{backgroundColor : "#fff", color : "#737373", fontSize : "14px"}}><IonText>{t("no information available")}</IonText></div>
                        )}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Coach);
