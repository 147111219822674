import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {AppContext} from "../App";
import {App} from '@capacitor/app';

const RedirectListener: React.FC = () => {
    let history = useHistory();
    const {state} = useContext(AppContext);

    useEffect(() => {
        // universal links
        App.addListener('appUrlOpen', (data) => {
            const slug = data.url.split('.eu').pop();

            if (slug) {
                if (slug.startsWith('/app')) {
                    let newSlug = slug.split('/app').pop();

                    if (newSlug) {
                        history.push(newSlug);
                    }
                } else {
                    history.push(slug);
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.redirect) {
            console.log("REDIRECT LISTENER REDIRECTING", state.redirect);
            setTimeout(() => {
                return history.push(state.redirect);
            }, 100);
        }
        // eslint-disable-next-line
    }, [state.redirect]);

    return null;
};

export default React.memo(RedirectListener);
