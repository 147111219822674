import React, {useContext, useEffect, useState} from "react";
import {
    IonPage,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonContent,
    IonCard,
    IonCardContent,
    IonCol,
    IonRow,
    IonInput,
    IonButton,
    IonItem,
    IonText,
    IonSpinner,
    IonRefresherContent,
    IonRefresher,
    useIonViewWillLeave, useIonViewWillEnter,
} from "@ionic/react";
import {useTranslation} from 'react-i18next';
import {CONFIG} from "../constants";
import Axios from "axios";
import {AppContext} from "../App";
import {IMatch} from "../models/IMatch";
import 'moment-timezone';
import moment from "moment";
import {Link, RouteComponentProps} from "react-router-dom";
import {RefresherEventDetail, toastController} from "@ionic/core";
import {Img} from "react-image";
import GameRulesModal from "../component/GameRulesModal";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import MatchTimer from "../component/MatchTimer";

const Predictions: React.FC<RouteComponentProps> = ({history}) => {
    const {t, i18n} = useTranslation();
    const [matches, setMatches] = useState<IMatch []>([]);
    const [matchState] = useState<any>('1');
    const {state} = useContext(AppContext);
    const [showGameRulesModal, setShowGameRulesModal] = useState(false);
    const [predictionsChanged, setPredictionsChanged] = useState(false);
    const [predictionsSaving, setPredictionsSaving] = useState(false);
    let matchesInterval: any = false;

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    useIonViewWillEnter(() => {
        // prevent on first run -> useEffect() when coming back this page below should pass
        if (!matchesInterval && !state.isLoading && state.token) {
            getMatches().then(() => {
                matchesInterval = setInterval(() => {
                    getMatches();
                }, 15000);
            });
        }
    }, [matchState, matchesInterval]);

    useIonViewWillLeave(() => {
        clearInterval(matchesInterval);
        matchesInterval = false;
    }, [matchState]);

    const getMatches = async () => {
        Axios.get(`${CONFIG.API_ENDPOINT}matches/list?predictions&state=${matchState}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setMatches(res.data.matches);
                return res.data.matches;
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token && !matchesInterval) {
            getMatches().then(() => {
                // eslint-disable-next-line
                matchesInterval = setInterval(() => {
                    getMatches();
                }, 15000);
            });
        }
        return () => {clearInterval(matchesInterval); matchesInterval = false};
        // eslint-disable-next-line
    }, [matchState, i18n.language, state.initLoading, state.token]);

    useEffect(() => {
        if (predictionsChanged && !predictionsSaving) {
            savePredictions();
        }
        // eslint-disable-next-line
    }, [predictionsChanged, predictionsSaving]);

    function getDateHeader(matchDateKey: any) {
        let dateString = moment(matchDateKey, "DD-MM-YYYY").locale(i18n.language).format('LL');
        if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment(), 'day')) {
            dateString = t('today');
        } else if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment().add(1, 'days'), 'day')) {
            dateString = t('tomorrow');
        } else if (moment(matchDateKey, 'DD-MM-YYYY').isSame(moment().subtract(1, 'days'), 'day')) {
            dateString = t('yesterday');
        }
        return (
            <IonCol size="12">
                <IonText color="secondary" class="matchesOverviewDateHeader"><strong>{dateString}</strong></IonText>
            </IonCol>
        )
    }

    function matchDayAway(matchStartTime: any){
        const formattedDate = moment.unix(matchStartTime);
        const today = moment();
        const difference = formattedDate.diff(today, 'hours', true);
        let matchToday = false;
        if(difference < 24) {
            matchToday = true;
        }
        return matchToday;
    }

    const showMatches = () => {
        if (matches && matches.length > 0) {
            let matchesArray = matches.reduce((groups :any, game: any) => {
                const date = moment.unix(game.startTime).format('DD-MM-YYYY');
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, []);
            let matchesOrder;
            if (matchState === '3') {
                matchesOrder = Object.keys((matchesArray)).reverse();
            } else {
                matchesOrder = Object.keys((matchesArray));
            }
            return (matchesOrder.map((matchDateKey: any) =>
                <IonRow key={matchDateKey} className="ion-margin-top">
                    {matchState !== "2" &&
                        getDateHeader(matchDateKey)
                    }
                    {matchesArray[matchDateKey].map((match: any) =>
                        <IonCol size="12" sizeLg="6" sizeXl="6" key={match.id}>
                            <IonCard class="cardBackground ion-no-margin noBorderRadius" button={true}>
                                <IonCardContent class="ion-no-padding">
                                    <Link to={"/matches/"+match.id} className="matchCardLink">
                                        <IonRow>
                                            <IonCol size="12" class="ion-margin-top ion-no-padding ion-padding-horizontal">
                                                <IonText class="ion-text-center"><h2 style={{fontSize : "16px", fontWeight : "bold"}}>{getMatchTime(match)}</h2></IonText>
                                            </IonCol>
                                            {matchState !== "2" && matchDayAway(match.startTime) &&
                                                <IonCol size="12"
                                                        class="ion-margin-top ion-no-padding ion-padding-horizontal">
                                                    <MatchTimer matchStartTime={match.startTime}/>
                                                </IonCol>
                                            }
                                            <IonCol size="12" class="ion-no-padding">
                                                <IonRow class="ion-text-center">
                                                    <IonCol class="ion-no-padding ion-padding-start" size="6" style={{ paddingRight : "10px" }}>
                                                        <div style={{ margin:"auto"}}><Img style={{maxHeight: "100px"}} src={match.teams[0].logo} loader={<IonSpinner name="crescent" />}/></div>
                                                        <div style={{ marginTop : "5px" }}><IonText style={{ fontSize: "14px"}}>{match.teams[0].name}</IonText></div>
                                                    </IonCol>
                                                    <IonCol size="6" class="ion-no-padding ion-padding-end" style={{ paddingLeft : "10px" }}>
                                                        <div style={{ margin:"auto"}}><Img style={{maxHeight: "100px"}} src={match.teams[1].logo} loader={<IonSpinner name="crescent" />}/></div>
                                                        <div style={{ marginTop : "5px" }}><IonText style={{ fontSize : "14px"}}>{match.teams[1].name}</IonText></div>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </Link>
                                    {ShowPredictionForm(match)}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                </IonRow>
            ));
        } else {
            return <IonCard className="cardBackground noBorderRadius ion-padding-horizontal ion-no-margin ion-margin-top"><IonText><p>{t('no matches found')}</p></IonText></IonCard>;
        }
    };

    async function savePredictions(showSavedToast: boolean = true) {
        let predictedMatches: any = [];
        setPredictionsSaving(true);

        matches.forEach((match: any) => {
            let teamPredictions: any = [];

            if (Object.keys(match.teams[0].predictions).length > 0) {
                teamPredictions.push({
                    "team": match.teams[0].team_id,
                    "result": match.teams[0].predictions[1000000].result
                });
            }

            if (Object.keys(match.teams[1].predictions).length > 0) {
                teamPredictions.push({
                    "team": match.teams[1].team_id,
                    "result": match.teams[1].predictions[1000000].result
                });
            }

            if (teamPredictions.length > 0) {
                predictedMatches.push({
                    "match": match.id,
                    "period": 1000000,
                    "teams": teamPredictions
                });
            }
        });

        if (predictedMatches.length > 0) {
            Axios.post(`${CONFIG.API_ENDPOINT}predictions/add`, {predictions: predictedMatches}, {
                headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
            }).then((res: any) => {
                if (res.data.success) {
                    if (showSavedToast) {
                        toastController.getTop().then((data) => {
                            if (typeof data === 'undefined') {
                                toastController.create({
                                    message: t('predictions saved'), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                                }).then((result: HTMLIonToastElement) => {
                                    return result.present();
                                });
                            }
                        });
                    }
                } else {
                    if (showSavedToast) {
                        res.data.errors.forEach((item: any) => {
                            toastController.create({
                                message: t(`errors.${item.code}`),
                                cssClass: "toastMessage",
                                buttons: [{
                                    text: t('close'),
                                    role: 'cancel'
                                }],
                                duration: 3000,
                                position: 'top',
                                translucent: false,
                            }).then((result: HTMLIonToastElement) => {
                                return result.present();
                            });
                        });
                    }
                }
            });
        }

        setPredictionsSaving(false);
        setPredictionsChanged(false);
    }

    function getMatchById(matchId: number) {
        return matches.find(match => match.id === matchId);
    }

    function getKeyByValue(object: any, value: any) {
        let matchKey = 0;
        let result = Object.keys(object).find(key => object[key] === value);
        if (typeof result !== 'undefined') {
            matchKey = parseInt(result);
        }
        return matchKey;
    }

    function changePrediction(e: any, matchId: any, matchTeamKey: number, addScore: boolean = true) {
        e.preventDefault();
        let matchesOld = [...matches];
        let match = getMatchById(matchId);

        if (!predictionsSaving) {
            if (typeof match !== 'undefined') {
                if (Object.keys(match.teams[matchTeamKey].predictions).length > 0) {
                    let matchesKey = getKeyByValue(matches, match);
                    if (typeof matchesKey !== 'undefined') {
                        if (addScore) {
                            match.teams[matchTeamKey].predictions[1000000].result++;
                        } else if (addScore === false && match.teams[matchTeamKey].predictions[1000000].result > 0) {
                            match.teams[matchTeamKey].predictions[1000000].result--;
                        }
                        matchesOld[matchesKey] = match;
                    }
                } else {
                    let matchesKey = getKeyByValue(matches, match);
                    if (typeof matchesKey !== 'undefined') {
                        if (addScore) {
                            match.teams[matchTeamKey].predictions[1000000] = {
                                result: 0
                            };
                            matchesOld[matchesKey] = match;
                        }
                    }
                }
            }
        }

        setPredictionsChanged(true);
        setMatches(matchesOld);
    }

    function getMatchTime(match: any) {
        if (state.amPmTime === 'true') {
            return moment.unix(match.startTime).locale(i18n.language).format('hh:mm A');
        }

        return moment.unix(match.startTime).locale(i18n.language).format('HH:mm');
    }

    const ShowPredictionForm = (match: any) => {
        if (match.state === 1) {
            if (!match.wasActive) {
                return (
                    <IonRow class={`ion-padding-start ion-padding-end ion-padding-bottom`} key={match.id}>
                        <IonCol size="6" class="ion-text-center ion-no-padding" style={{paddingRight: "10px"}}>
                            <IonItem class="ion-no-padding ion-text-center" style={{"--inner-padding-end": "0"}}
                                     lines="none">
                                <IonButton class="predictionButton"
                                           onClick={(e: any) => changePrediction(e, match.id, 0, false)}>-</IonButton>
                                <IonInput class="ion-no-padding predictionInput" disabled={true}
                                          name={`${match.id} ${match.teams[0].id}`}
                                          value={Object.keys(match.teams[0].predictions).length > 0 ? `${match.teams[0].predictions[1000000].result}` : ''}/>
                                <IonButton class="predictionButton"
                                           onClick={(e: any) => changePrediction(e, match.id, 0, true)}>+</IonButton>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6" class="ion-text-center ion-no-padding" style={{paddingLeft: "10px"}}>
                            <IonItem class="ion-no-padding ion-text-center" style={{"--inner-padding-end": "0"}}
                                     lines="none">
                                <IonButton class="predictionButton"
                                           onClick={(e: any) => changePrediction(e, match.id, 1, false)}>-</IonButton>
                                <IonInput name={`${match.id} ${match.teams[1].id}`}
                                          class="ion-no-padding predictionInput" disabled={true}
                                          value={Object.keys(match.teams[1].predictions).length > 0 ? `${match.teams[1].predictions[1000000].result}` : ''}/>
                                <IonButton class="predictionButton"
                                           onClick={(e: any) => changePrediction(e, match.id, 1, true)}>+</IonButton>
                            </IonItem>
                        </IonCol>
                        <IonCol size="12" class="ion-text-center ion-no-padding predictionMessage">

                            {Object.keys(match.teams[0].predictions).length > 0 && Object.keys(match.teams[1].predictions).length > 0 ?
                                <IonText style={{color: "green"}}><i style={{marginRight: "5px"}}
                                                                     className="fal fa-check"/>{t("prediction made")}
                                </IonText>
                                :
                                <IonText
                                    style={{color: "grey"}}>{t("make a prediction before the start of the match!")}</IonText>
                            }
                        </IonCol>
                    </IonRow>
                )
            } else {
                return (
                    <IonRow class={`ion-padding-start ion-padding-end ion-padding-bottom`} key={match.id}>
                        <IonCol size="12" class="ion-text-center ion-no-padding predictionMessage">
                            <IonText style={{color: "grey"}}>{t("match was active before")}</IonText>
                        </IonCol>
                    </IonRow>
                )
            }
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getMatches().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t('predictions')}</IonTitle>
                    <IonButtons slot="end" >
                        <IonButton onClick={() => setShowGameRulesModal(true)}>{t('rules')}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`, "--padding-bottom" : "0"}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeXl="10">
                        <div style={{maxWidth: "1200px", margin: "auto"}}>
                        {showMatches()}
                        </div>
                    </IonCol>
                </IonRow>
                <GameRulesModal showGameRulesModal={showGameRulesModal} onChange={setShowGameRulesModal} />
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Predictions);
