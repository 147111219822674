import React, {useContext, useEffect, useState} from 'react';
import {
    IonBackButton, IonBadge,
    IonButtons,
    IonCol,
    IonContent, IonGrid,
    IonHeader, IonImg,
    IonItem, IonLabel, IonList, IonListHeader,
    IonPage, IonRefresher, IonRefresherContent,
    IonRow, IonSpinner, IonText, IonThumbnail,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import Axios from "axios";
import {CONFIG} from "../constants";
import Moment from "react-moment";
import {Img} from 'react-image'
import {RefresherEventDetail, toastController} from "@ionic/core";
import {IPlayer} from "../models/IPlayer";
import getPrimaryGroupAsset from "../helper/getPrimaryGroupAsset";
import TimeMoment from "../component/TimeMoment";

const logoPlaceholder = {
    width: '150px',
    height: '150px'
};

interface IPlayerGroup {
    name: string,
    groupLetter: string,
    teamsItem: any
}

interface ITeamPlayer {
    player: IPlayer,
    teamsItemId: number,
    index: number,
    itemClass: string
}

function getRowClass(index: any) {
    let rowClass;
    if(isEven(index)){
        rowClass = "itemEvenRow";
    } else {
        rowClass = "";
    }
    return rowClass;
}

function isEven(value: any) {
    return value % 2 === 0;
}

const TeamPlayer: React.FC<ITeamPlayer> = (teamPlayer: ITeamPlayer) => {
    return (
        <IonItem button={true} routerDirection="forward" routerLink={`/teams/${teamPlayer.teamsItemId}/player/${teamPlayer.player.id}`} key={teamPlayer.player.id} lines="none" class={teamPlayer.itemClass}>
            <IonRow class="ion-align-items-center">
                <IonCol class="ion-padding-end" size="auto">
                    <IonThumbnail style={{"--size": "32px", "--border-radius": "15px"}}>
                        <IonImg src={teamPlayer.player.nationality.flag} />
                    </IonThumbnail>
                </IonCol>
                <IonCol>
                    <IonLabel class="removeNoWrap">{teamPlayer.player.fullName}</IonLabel>
                </IonCol>
            </IonRow>
        </IonItem>
    )
};

const PlayerGroup: React.FC<IPlayerGroup> = (playerGroup:IPlayerGroup) => {
    if (!(playerGroup.teamsItem && playerGroup.teamsItem.players && playerGroup.teamsItem.players.length > 0)) {
        return null;
    }
    return (
        <IonRow class="ion-padding-horizontal">
            <IonCol class="ion-no-padding">
                <IonList style={{background: "transparent"}} class="ion-no-margin ion-padding-bottom">
                    <IonListHeader style={{color: "var(--ion-color-secondary)", fontSize: "16px", fontWeight: "bold"}} class="ion-no-padding">{playerGroup.name}</IonListHeader>
                    <div className="cardLayout">
                        {playerGroup.teamsItem.players.filter(function (player: any) { return player.position === playerGroup.groupLetter;}).map((player: any, index: number) => {
                            return (
                                <TeamPlayer index={index} teamsItemId={playerGroup.teamsItem.id} itemClass={getRowClass(index)} player={player} key={player.id} />
                            )
                        })}
                    </div>
                </IonList>
            </IonCol>
        </IonRow>
    )
};

interface TeamsProp extends RouteComponentProps <{id: string}> {}

const TeamsItem: React.FC<TeamsProp> = ({history, match}) =>  {
    const {t, i18n} = useTranslation();
    const {state} = useContext(AppContext);
    const [teamsItem, setTeamsItem] = useState({
        code: '',
        id: 999999,
        logo: "",
        name: '',
        players: [],
        statistics: [],
        coach: {
            birthCountry: {
                code: "",
                flag: ""
            },
            birthDate: "",
            birthPlace: "",
            fullName: "",
            heightInCm: 0,
            id: 999999,
            nationality: {
                code: "",
                flag: ""
            },
            weightInKg: 0
        },
        form: ""
    });
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        if (!state.initLoading && !state.token) {
            setTimeout(() => {
                return history.push('/login');
            }, 300);
        }
        // eslint-disable-next-line
    }, [state.initLoading, state.token]);

    const getTeam = async () => {
        await Axios.get(`${CONFIG.API_ENDPOINT}competitions/${CONFIG.COMPETITION}/teams/${match.params.id}`, {
            headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
        }).then((res: any) => {
            if (res.data.success === true) {
                setTeamsItem(res.data.team);
                Axios.get(`${CONFIG.API_ENDPOINT}matches/list?team=${res.data.team.id}`, {
                    headers: {'Authorization': `bearer ${state.token}`, 'Accept-Language': i18n.language}
                }).then((res: any) => {
                    if (res.data.success === true) {
                        setMatches(res.data.matches);
                    } else {
                        res.data.errors.forEach((item: any) => {
                            toastController.create({
                                message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                            }).then((result: HTMLIonToastElement) => {
                                return result.present();
                            });
                        });
                    }
                });
            } else {
                res.data.errors.forEach((item: any) => {
                    toastController.create({
                        message: t(`errors.${item.code}`), cssClass: "toastMessage", buttons: [{text: t('close'), role: 'cancel'}], duration: 3000, position: 'top', translucent: false,
                    }).then((result: HTMLIonToastElement) => {
                        return result.present();
                    });
                });
            }
        });
    };

    useEffect(() => {
        if (!state.initLoading && state.token) {
            getTeam();
        }
        // eslint-disable-next-line
    }, [i18n.language, state.initLoading, state.token]);

    function getTeamStatistics() {
        const statistics = Object.keys(teamsItem.statistics);
        let statisticKeys: any = [];
        statistics.forEach((statistic: any) => {
            const lastIndex = statistic.lastIndexOf("_");
            const statisticName = statistic.substring(0, lastIndex);
            if(statisticKeys.indexOf(statisticName) < 0){
                statisticKeys.push(statisticName);
            }
        });
        return statisticKeys;
    }

    const showTeamGeneral = () => {
        if (teamsItem.name) {
            return (
                <IonCol size="12">
                    <div className="teamPageLogoContainer">
                        <Img style={{ margin : "auto", display : "block"}} src={teamsItem.logo} loader={<div style={logoPlaceholder} />} />
                    </div>
                </IonCol>
            )
        }
    };

    const showTeamStatistics = () => {
        if(teamsItem.statistics && Object.keys(teamsItem.statistics).length > 0){
            return(
                <IonCol class="ion-no-padding ion-margin-top">
                    <IonList class="ion-padding-bottom ion-no-margin cardLayout">
                        <IonListHeader class="ion-no-padding">
                            <IonGrid>
                                <IonRow class="ion-align-items-center ion-padding-horizontal">
                                    {(teamsItem && teamsItem.form) &&
                                        <IonCol size="12" class="previousOutcome teamPage">
                                            {teamsItem.form.split("").map((letter: string, index:number) => {
                                                return(
                                                    <IonBadge key={index + letter} class={`previousOutcomeBadge ${letter === 'L' ? "loseColor" : letter === 'D' ? "drawColor" : letter === 'W' ? "winColor" : "" }`}>{t(`teamForm.${letter}`)}</IonBadge>
                                                )
                                            })}
                                        </IonCol>
                                    }
                                    <IonCol size="6" class="ion-text-left"><IonText style={{ fontSize : "16px"}}>{t("team_home")}</IonText></IonCol>
                                    <IonCol size="6" class="ion-text-right"><IonText  style={{ fontSize: "16px"}}>{t("team_away")}</IonText></IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonListHeader>
                        {getTeamStatistics().map((statisticKey: any, index: number) => {
                            const statisticHome:any = statisticKey + "_HOME";
                            const statisticAway:any = statisticKey + "_AWAY";
                            const statisticTotal:any = statisticKey + "_TOTAL";
                            const percentageHome = teamsItem.statistics[statisticHome] / teamsItem.statistics[statisticTotal] * 100;
                            const percentageAway = teamsItem.statistics[statisticAway] / teamsItem.statistics[statisticTotal] * 100;
                            return (
                                <IonItem lines="none" key={index}>
                                    <IonGrid>
                                        <IonRow class="ion-align-items-center">
                                            <IonCol size="12" class="ion-text-center">{t(`statisticsTeam.${statisticKey}`)}</IonCol>
                                            <IonCol class="ion-text-left">
                                                <IonLabel>{ teamsItem.statistics[statisticHome]}</IonLabel>
                                            </IonCol>
                                            <IonCol size="9">
                                                {(Math.round(percentageHome) + Math.round(percentageAway)) > 100 ?
                                                    (
                                                        <div  className="statisicBar">
                                                            <div style={{ width : Math.round(percentageHome) + "%" }} className="homeBarPart"/>
                                                            <div style={{ width : Math.floor(percentageAway) + "%" }} className="awayBarPart"/>
                                                        </div>
                                                    ) : (
                                                        <div  className="statisicBar">
                                                            <div style={{ width : Math.round(percentageHome) + "%" }} className="homeBarPart"/>
                                                            <div style={{ width : Math.round(percentageAway) + "%" }} className="awayBarPart"/>
                                                        </div>
                                                    )}
                                            </IonCol>
                                            <IonCol class="ion-text-right">
                                                <IonLabel>{teamsItem.statistics[statisticAway]}</IonLabel>
                                            </IonCol>

                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            )
                        })
                        }
                    </IonList>
                </IonCol>
            )
        }
    };

    const showTeamMatches = () => {
        if (matches && matches.length > 0) {
            return(
                <IonRow class="ion-padding-horizontal ion-padding-bottom">
                    <IonCol class="ion-no-padding">
                        <IonList style={{ background : "transparent"}} >
                            <IonListHeader style={{ color : "var(--ion-color-secondary)", fontSize: "16px", fontWeight : "bold" }} class="ion-no-padding">{t('matches')}</IonListHeader>
                            <div className="cardLayout">
                                {matches.slice(0).reverse().map((match: any, index: number) => {
                                    return(
                                        <IonItem button={true} detail={false} routerDirection="forward" routerLink={`/matches/${match.id}`} key={match.id} class={getRowClass(index)} lines="none">
                                            <IonGrid>
                                                <IonRow style={{ paddingTop : "5px"}} class="ion-align-items-center">
                                                    <IonCol size="9" class="ion-no-padding">
                                                        <IonRow class="ion-align-items-center">
                                                            <IonCol style={{ paddingBottom: "0"}}>
                                                                <IonLabel><p><Moment unix format="LL">{match.startTime}</Moment></p></IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    <IonCol size="3" class="ion-text-right ion-no-padding">
                                                        <IonLabel><p><TimeMoment amPmTime={state.amPmTime} time={match.startTime} timeZone={state.me.timeZone} /></p></IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow class="ion-align-items-center">
                                                    <IonCol size="9" class="ion-no-padding">
                                                        <IonRow class="ion-align-items-center">
                                                            <IonCol size="auto">
                                                                <IonThumbnail style={{"--size": "16px"}}><Img src={match.teams[0].logo} loader={<IonSpinner name="crescent"  style={{ width: "16px", height: "16px"}} />} /></IonThumbnail>
                                                            </IonCol>
                                                            <IonCol>
                                                                {match.teams[0].name}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    <IonCol size="3">
                                                        { match.teams[0].currentResult !== null ? (
                                                            <IonText class="ion-float-right" color="primary"><strong>{match.teams[0].currentResult}</strong></IonText>
                                                        ) : (
                                                            <IonText class="ion-float-right" color="primary"><strong>-</strong></IonText>
                                                        )}
                                                    </IonCol>
                                                    <IonCol size="9" class="ion-no-padding">
                                                        <IonRow class="ion-align-items-center">
                                                            <IonCol size="auto">
                                                                <IonThumbnail style={{"--size": "16px"}}><Img src={match.teams[1].logo} loader={<IonSpinner name="crescent" style={{ width: "16px", height: "16px"}} />} /></IonThumbnail>
                                                            </IonCol>
                                                            <IonCol>
                                                                {match.teams[1].name}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    <IonCol size="3">
                                                        { match.teams[1].currentResult !== null ? (
                                                            <IonText class="ion-float-right" color="primary"><strong>{match.teams[1].currentResult}</strong></IonText>
                                                        ) : (
                                                            <IonText class="ion-float-right" color="primary"><strong>-</strong></IonText>
                                                        )}
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                    )
                                })}
                            </div>
                        </IonList>
                    </IonCol>
                </IonRow>
            )
        }
    };

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        getTeam().then(() => {
            event.detail.complete();
        });
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary" className="mainToolbar">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/teams" text="" />
                    </IonButtons>
                    <IonTitle>{ teamsItem.name }</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="mainBackground" style={{"background": `url(${getPrimaryGroupAsset(state.me.primaryGroup, "background", state.me.useGroupLayout)}) no-repeat center center`}}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} style={{"zIndex": "0"}}>
                    <IonRefresherContent refreshingSpinner="crescent" />
                </IonRefresher>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeLg="8" sizeXl="6">
                        <IonRow className="ion-padding">
                            {!(teamsItem.code && teamsItem.name && teamsItem.logo) &&
                            <IonCol size="12" class="cardLayout ion-padding" style={{backgroundColor : "#fff", color : "#737373", fontSize : "14px"}}><IonText>{t("no information available")}</IonText></IonCol>
                            }
                            {showTeamGeneral()}
                            {showTeamStatistics()}
                        </IonRow>
                        {(teamsItem.coach && teamsItem.coach.fullName !== "") &&
                        <IonRow class="ion-padding-horizontal">
                            <IonCol class="ion-no-padding">
                                <IonList style={{background: "transparent"}} class="ion-no-margin ion-padding-bottom">
                                    <IonListHeader
                                        style={{color: "var(--ion-color-secondary)", fontSize: "16px", fontWeight: "bold"}}
                                        class="ion-no-padding">{t('coach')}</IonListHeader>
                                    <div className="cardLayout">
                                        <IonItem button={true} routerDirection="forward" routerLink={`/teams/${teamsItem.id}/coach/${teamsItem.coach.id}`} lines="none">
                                            <IonRow class="ion-align-items-center">
                                                <IonCol class="ion-padding-end" size="auto">
                                                    <IonThumbnail style={{"--size": "32px", "--border-radius": "15px"}}>
                                                        <IonImg src={teamsItem.coach.nationality && teamsItem.coach.nationality.flag} />
                                                    </IonThumbnail>
                                                </IonCol>
                                                <IonCol>
                                                    <IonLabel class="removeNoWrap">{teamsItem.coach.fullName}</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonItem>
                                    </div>
                                </IonList>
                            </IonCol>
                        </IonRow>
                        }
                        <PlayerGroup name={t("goalkeeper_plural")} groupLetter={"G"} teamsItem={teamsItem}/>
                        <PlayerGroup name={t('defender_plural')} groupLetter={"D"} teamsItem={teamsItem}/>
                        <PlayerGroup name={t('midfielder_plural')} groupLetter={"M"} teamsItem={teamsItem}/>
                        <PlayerGroup name={t('attacker_plural')} groupLetter={"F"} teamsItem={teamsItem}/>
                        {showTeamMatches()}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
};

export default React.memo(TeamsItem);
