export interface IAppConfig {
    API_ENDPOINT: string,
    API_PRODUCTION_ENDPOINT: string,
    API_TEST_ENDPOINT: string,
    APP_URL: string,
    COMPETITION: number,
    APP_STORE_IOS_URL: string,
    APP_STORE_ANDROID_URL: string
}

export const CONFIG: IAppConfig = {
    API_ENDPOINT: "https://api.yourfootballapp.eu/appApi/",
    API_PRODUCTION_ENDPOINT: "https://api.yourfootballapp.eu/appApi/",
    API_TEST_ENDPOINT: "https://test.yourfootballapp.eu/appApi/",
    APP_URL: "https://yourfootballapp.eu",
    COMPETITION: 0, // for active competition
    APP_STORE_IOS_URL: "https://apps.apple.com/nl/app/your-sports-app/id1497714694",
    APP_STORE_ANDROID_URL: "https://play.google.com/store/apps/details?id=eu.yourfootball.cmdc"
};
